import React, { useEffect, useState } from 'react';
import SelectedSetsAccordion from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/selectedSetsAccordion/SelectedSetsAccordion';
import { SelectedSetItemsListPanelProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/SelectedSetItemsListPanel.consts';
import {
  Headline,
  Section,
  SelectionContainer,
  StyledSearch,
  StyledSearchView,
  ViewProductHeader,
} from 'pages/shared/setItemsSelectionForm/setItemsListPanel/SetItemsListPanel.style';
import SelectedItemsAccordion from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/selectedItemsAccordion/SelectedItemsAccordion';
import { useFormContext } from 'react-hook-form';
import {
  getTotalSelectedItems,
  removeAllDisable,
  searchDisable,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import {
  AccordionContentType,
  SetItemsSelectionFormState,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { capitalize } from 'utils/text';
import { FormMode, isViewMode } from 'utils/types';
import { isInArray } from 'utils/array';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import { SelectboxSortBy } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/SetItemsListPanel.style';

const SelectedSetItemsListPanel = ({
  setOf,
  onItemSetActionClick,
  onItemSelect,
  onExcludeItemClick,
  onExcludeSetClick,
  className,
  searchPlaceholder = 'Search',
  itemSetTitleFormatter,
  itemFormatter,
  accordions,
  removeAll,
  fetchRootSetsByIds,
  formMode,
  forcedExcludedItemsIds,
  forcedExcludedSetsIds,
  supportSetFringes,
  items,
  sets,
  isDoe,
  mode,
}: SelectedSetItemsListPanelProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [customSetsLength, setCustomSetsLength] = useState(0);
  const [setsLength, setSetsLength] = useState(0);
  const { watch } = useFormContext<SetItemsSelectionFormState>();
  const selectedItemsById = watch('selectedItemsById') ?? items;
  const selectedItemSetsById = watch('selectedItemSetsById') ?? sets;
  const excludedItemsById = watch('excludedItemsById');
  const excludedItemSetsById = watch('excludedItemSetsById');
  const [totalSelectedItems, setTotalSelectedItems] = useState(0);
  const [totalSelectedProductsLength, setTotalSelectedProductsLength] = useState(0);
  const [sortType, setSortType] = useState('AlphaAsc');
  const sortingList = [
    { id: 10000, name: 'Alphabetically (A-Z)', value: 'AlphaAsc', isDefault: true },
    { id: 20000, name: 'Alphabetically (Z-A) ', value: 'AlphaDesc', isDefault: false },
    { id: 30000, name: 'PLU (Ascending)', value: 'PluAsc', isDefault: false },
    { id: 40000, name: 'PLU (Descending)', value: 'PluDesc', isDefault: false },
  ];

  useEffect(() => {
    if (!isViewMode(formMode)) {
      const nonExcludedItems = getTotalSelectedItems(
        Object.values(selectedItemSetsById),
        Object.values(selectedItemsById),
        Object.values(excludedItemSetsById),
        Object.values(excludedItemsById),
        setOf,
        supportSetFringes,
      );
      setTotalSelectedItems(nonExcludedItems);
      setTotalSelectedProductsLength(Object.values(selectedItemsById).length);
      setCustomSetsLength(Object.values(selectedItemSetsById).filter((set) => set.custom).length);
      setSetsLength(Object.values(selectedItemSetsById).filter((set) => !set.custom).length);
    }
  }, [selectedItemsById, selectedItemSetsById, excludedItemsById, excludedItemSetsById, formMode]);

  return (
    <Section className={className} data-automation-id="selected-panel">
      {<Headline>{!isViewMode(formMode) ? `Selected ${capitalize(setOf)} (${totalSelectedItems})` : ''}</Headline>}
      <ViewProductHeader mode={mode}>
        <StyledSearchView
          name="items-search"
          data-automation-id="items-filter-input"
          placeholder={searchPlaceholder}
          onChange={(e) => setSearchValue(e.target.value.trim())}
          autoFocus={true}
          label={mode === FormMode.View || mode === 'createNew' ? 'Search' : ''}
          disabled={searchDisable(formMode, totalSelectedItems, searchValue)}
          labelIsHorizontal={mode !== undefined && (mode === FormMode.View || mode === 'createNew')}
          mode={mode}
        />

        {mode === 'view' || mode === 'createNew' ? (
          <SelectboxSortBy
            name="Sort"
            label="Sort By"
            defaultValue={{
              id: 10000,
              name: 'Alphabetically (A-Z)',
              value: 'AlphaAsc',
              isDefault: true,
            }}
            onChange={(selectedItem: any) => {
              setSortType(selectedItem.value);
            }}
            items={sortingList as any}
            labelIsHorizontal
            version="campaign-form"
            containerGap={8}
            labelMarginBottom="0px"
            selectWidth={260}
          />
        ) : mode === undefined ? null : (
          <></>
        )}
      </ViewProductHeader>

      <SelectionContainer className={className}>
        {accordions.map((accordionProps) =>
          isInArray(
            [AccordionContentType.Set, AccordionContentType.SetWithIndividualItems],
            accordionProps.contentType,
          ) ? (
            <SelectedSetsAccordion
              key={accordionProps.name}
              sort={sortType}
              accordion={accordionProps}
              isSingleAccordionPanel={accordions?.length === 1}
              setOf={setOf}
              itemFormatter={itemFormatter}
              itemSetTitleFormatter={itemSetTitleFormatter}
              onExcludeItemClick={onExcludeItemClick}
              onExcludeSetClick={onExcludeSetClick}
              onItemSelect={onItemSelect}
              onItemSetActionClick={onItemSetActionClick}
              removeAll={removeAll}
              searchValue={searchValue}
              fetchRootSetsByIds={fetchRootSetsByIds}
              forcedExcludedSetsIds={forcedExcludedSetsIds}
              forcedExcludedItemsIds={forcedExcludedItemsIds}
              supportSetFringes={supportSetFringes}
              items={items}
              sets={sets}
              isDoe={isDoe}
              disabled={removeAllDisable(
                accordionProps.name,
                formMode,
                customSetsLength,
                setsLength,
                totalSelectedItems,
                totalSelectedProductsLength,
              )}
            />
          ) : (
            <SelectedItemsAccordion
              key={accordionProps.name}
              sort={sortType}
              accordion={accordionProps}
              isSingleAccordionPanel={accordions?.length === 1}
              itemFormatter={itemFormatter}
              onExcludeItemClick={onExcludeItemClick}
              onItemSelect={onItemSelect}
              removeAll={removeAll}
              searchValue={searchValue}
              items={items}
              disabled={removeAllDisable(
                accordionProps.name,
                formMode,
                customSetsLength,
                setsLength,
                totalSelectedItems,
                totalSelectedProductsLength,
              )}
              isDoe={isDoe}
            />
          ),
        )}
      </SelectionContainer>
    </Section>
  );
};

export default SelectedSetItemsListPanel;
