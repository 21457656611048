import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isInArray } from 'utils/array';
import { DomainName, User, UserRole } from 'utils/types/users';
import type { RootState } from '../store';
import { usersGqlsDefault } from './users.gqls';
import { client } from 'app/apollo';
import { md, pki, util } from 'node-forge';

export interface UsersState {
  user: User;
}

export interface Action {
  payload: {
    user: User;
  };
}

const getDefaultUser = () => {
  // The Fetch Request that connects to resolver and grabs our env variables
  const fetchEnvVar = async () => {
    return client.query({ query: usersGqlsDefault.queries.getFirstLastEmail, fetchPolicy: 'no-cache' });
  };

  const data = fetchEnvVar();
  return data.then((res) => {
    //Checks if theres data
    if (res.data && res.data?.getEnvironments) {
      return {
        id: 1,
        firstName: res.data.getEnvironments.firstName,
        lastName: res.data.getEnvironments.lastName,
        company: 'Other',
        role: UserRole.SysAdmin,
        email: res.data.getEnvironments.adminEmail,
        userOperation: {
          id: 1,
          role: {
            id: 1,
            roleName: UserRole.SysAdmin,
          },
          domain: {
            id: 1,
            domainName: DomainName.Offers,
            attributes: [],
          },
        },
      };
    }
  });
};

const verifyUserResponse = (data: string, signature: any) => {
  const publicKey = pki.publicKeyFromPem(signature.rsaPublicKey);

  const digest = md.sha256.create();
  digest.update(data, 'utf8');

  const decodedSignature = util.decode64(signature.hash);

  return publicKey.verify(digest.digest().bytes(), decodedSignature);
};

export const initUser = createAsyncThunk('users/init', async (payload, thunkAPI) => {
  const headers = new Headers({
    'Cache-Control': 'no-store',
  });
  if (!isInArray(['development'], process.env.NODE_ENV)) {
    const res = await fetch('/user', { credentials: 'include', cache: 'no-store', headers: headers });
    if (!res.ok) {
      return thunkAPI.rejectWithValue(null);
    }
    const data = await res.json();
    const { user, signature } = data;
    if (!verifyUserResponse(JSON.stringify(user), signature)) {
      console.error('Data has been tampered with!');
      return thunkAPI.rejectWithValue('Data integrity failed');
    }
    return user;
  }
  return getDefaultUser();
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: undefined,
  } as UsersState,
  reducers: {
    logout(state) {
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initUser.pending, (state) => {
      return state;
    });
    builder.addCase(initUser.fulfilled, (state, action) => {
      state.user = action.payload as User;
      return state;
    });

    builder.addCase(initUser.rejected, () => {
      return null;
    });
  },
});

export const users = (state: RootState) => state.users;
export const { logout } = usersSlice.actions;

export default usersSlice.reducer;
