import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import { useOperationCounter } from 'app/apollo/operationCounter';
import difference from 'lodash/difference';
import Backdrop from '../backdrop/Backdrop';
import { ModalProps } from './Modal.const';
import {
  BackButton,
  CloseIcon,
  Container,
  Content, Footer, FooterContent,
  Loading,
  MainTitle,
  ModalHeader, NextButton,
  TitleWithProgress
} from './NewModal.style';
import { Loader } from '../loader';
import NewTextModalTitle from '../text/textModalTitle/NewTextModalTitle';
import ProgressLoader from 'pages/shared/progressIndicator/ProgressLoader';
import { store } from "app/store";
import { closeModal } from "app/slices/modals";
import { NewButtonText } from "components/shared/button";
import { handleKeyDownModal } from 'utils/modal';

const Modal = ({
  children,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  inProgress = false,
  onHover = false,
  message,
  showCloseIcon = false,
  nextButtonAction = undefined,
  nextButtonLabel = "Next",
  backButtonAction = undefined,
  mode,
  ...rest
}: ModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();

  const headerRef = useRef(null); 

  useEffect((): (() => void) => {
    if (headerRef.current) {
      headerRef.current.focus(); 
    }
    document.body.style.overflow = 'hidden';
    // add event listener to prevent focus from leaving the modal 
    const keyDownListener : any = (event: KeyboardEvent) => handleKeyDownModal(event, 'modal');
    document.addEventListener('keydown', keyDownListener);
    return () => {
      document.removeEventListener('keydown', keyDownListener)
      document.body.style.overflow = 'unset';
    };
  }, [mode]);

  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <Container className={className} data-automation-id="modal" {...rest}>
        {withLoader && (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
        <ModalHeader className="ModalHeader" ref={headerRef} tabIndex={0}>
          <MainTitle>
            <TitleWithProgress>
              {title && <NewTextModalTitle text={title} />}
              {inProgress && <ProgressLoader size={35} message={message} onHover={onHover} />}
            </TitleWithProgress>
            {isLocked && <LockIcon />}
            {showCloseIcon &&
              <NewButtonText aria-label='Close' onClick={() => {store.dispatch(closeModal())}} data-title="Close">
                <CloseIcon name="newClose" />
              </NewButtonText>
            }
          </MainTitle>
          {subtitle ?? null}
        </ModalHeader>
        <Content data-automation-id="modal-content">{children}</Content>
        {(backButtonAction || nextButtonAction) &&
          <Footer>
            <FooterContent>
              {backButtonAction && (
                <BackButton onClick={backButtonAction}>Go back</BackButton>
              )}
              {nextButtonAction && (
                <NextButton onClick={nextButtonAction}>{nextButtonLabel}</NextButton>
              )}
            </FooterContent>
          </Footer>
        }
      </Container>
    </>,
    document.querySelector('#modal'),
  );
};

export default Modal;
