export enum FetchPolicies {
  CacheAndNetwork = 'cache-and-network',
  NetworkOnly = 'network-only',
}

export enum ValidationMessages {
  RequiredField = 'This field is mandatory',
  AlternateRequiredField = 'This information is required',
}

export enum FormValidationMessages {
  ValidationError = 'Please review the form. Some required information is missing or contains invalid information. Please correct the errors before you can proceed.'
}
