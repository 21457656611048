import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { DoeErrorProps } from './DoeError.consts';
import { DoeErrorContainer, DoeStyledError, DoeStyledIcon } from './DoeError.style';

const DoeError = ({ className, errors, name, ref, tabIndex }: DoeErrorProps) => {
  const getNestedError = (errors: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], errors);
  };

  const hasError = getNestedError(errors, name);

  return typeof errors === 'string' ? (
    <DoeErrorContainer>
      {hasError && <DoeStyledIcon tabIndex={-1} name="doeError" />}
      <DoeStyledError className={className} data-automation-id="error" tabIndex={-1}>
        {errors}
      </DoeStyledError>
    </DoeErrorContainer>
  ) : hasError && Object.keys(errors).length > 0 ? (
    <DoeErrorContainer>
      {hasError && <DoeStyledIcon name="doeError" />}
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) =>
          message ? (
            <DoeStyledError role="alert" className={className} data-automation-id="error-message">
              <span>
                {message}
              </span>
            </DoeStyledError>
          ) : null
        }
      />
    </DoeErrorContainer>
  ) : null;
};

export default DoeError;
