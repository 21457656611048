import { FieldErrors, UseFormRegister } from 'react-hook-form';
import React from 'react';
import { appTheme } from 'styles/themes/defaultTheme';

export enum AdornmentPosition {
  Start = 'start',
  End = 'end',
}

export interface TextFieldProps {
  id?: string;
  name: string;
  type?: string;
  register?: UseFormRegister<any>;
  shouldUnregister?: boolean;
  validation?: Record<string, unknown>;
  label?: string;
  labelIsHorizontal?: boolean;
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  errors?: FieldErrors | string;
  adornmentPosition?: AdornmentPosition;
  children?: string | JSX.Element;
  theme?: appTheme;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  debounceTime?: number;
  autoFocus?: boolean;
  caretPosition?: number;
  showError?: boolean;
  selectWidth?: number;
  version?: any;
  width?: string;
  shouldUnRegister?: boolean;
}

export interface StyleTextFieldProps {
  disabled?: boolean;
  error?: boolean;
  adornmentPosition?: AdornmentPosition;
  labelIsHorizontal?: boolean;
  autoComplete?: 'off' | 'on';
  theme?: appTheme;
}
