import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import typography, { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonOutlined, NewButtonOutlined } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import { defaultTheme } from 'styles/themes/defaultTheme';
import { ThemeProps } from 'pages/settings/termsConditions/TermsConditions.consts';
import { OfferTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import NewOfferAddNewButton from 'pages/shared/addNewButton/OfferAddNewButton';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import NewPageContainer from 'pages/shared/pageContainer/NewPageContainer';
import zIndex from 'styles/zIndex';

export const DoeOfferPageContainer = styled(NewPageContainer)`
  margin: 0px 80px;
`;
// region Doe Offers
export const ButtonsContainer = styled.div`
  display: flex;
`;

export const ZonesButton = styled(ButtonOutlined)`
  width: 36px;
  height: 36px;
  gap: 0px;
  border-radius: 5.02px;
  border: 1.26px 0px 0px 0px;
  opacity: 0px;
  padding: ${typography.unit * 2}px;
  border: 1.26px solid #292929;
  :hover {
    border: 1.26px solid #292929;
  }
  :focus {
    border: 1.26px solid blue;
  }
`;
export const DoeOfferPageTitle = styled(OfferTextPageTitle)`
  width: 385px;
  height: 52px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  font-size: 56px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #292929;
`;

export const BulkEditOffersButton = styled(NewButtonOutlined)`
  padding: ${newTypography.unit * 2}px;
  background: #ffffff;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #adadad !important;
  &:hover {
    border: 2px solid #adadad !important;
  }
  color: ${newGlobalTheme.colors.text.input};
  padding: 8px 16px 8px 16px;
  border-radius: 24px;
  opacity: 0px;
  gap: 4px;
`;

export const DoeOfferAddNewButton = styled(NewOfferAddNewButton)`
  width: 36px;
  height: 36px;
  gap: 0px;
  border-radius: 5.02px;
  border: 1.26px 0px 0px 0px;
  opacity: 0px;
  padding: ${typography.unit * 2}px;
  border: 1.26px solid #c08b00;
  :hover {
    border: 1.26px solid #c08b00;
  }
`;

export const ZonesIcon = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 14px;
`;

export const DoeOffersGrid = styled.div`
  overflow: auto;
`;

export const DoeOffersGridContainer = styled(InfiniteScroll)`
  display: flex;
  margin-left: ${typography.unit * 7}px;
  margin-bottom: ${typography.unit * 5}px;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: ${typography.unit * 9}px;
  row-gap: ${typography.unit * 12}px;
  padding-bottom: ${typography.unit * 5}px;
  word-break: break-all;
  margin-top: 10px;
`;
// endregion
export const NewOffersGridContainer = styled(InfiniteScroll)`
  display: flex;
  padding-left: ${newTypography.unit * 18.25}px;
  margin-right: auto;
  padding-bottom: ${newTypography.unit * 5}px;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: ${newTypography.unit * 9.5}px;
  row-gap: 38px;
  padding-bottom: ${newTypography.unit * 5}px;
  word-break: break-all;
  padding-top: 10px;
`;
// region Offer shared
export const NoImageText = styled.div<StyledWithThemeProps>`
  display: flex;
  align-self: center;
  color: ${newGlobalTheme.colors.toggle.disabled};
  text-align: center;
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: ${newTypography.unit * 2}px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -50px;
`;
export const TitleActionButtonsContainer = styled.div`
  gap: ${newTypography.unit * 4}px;
`;

export const DoeOfferTitleActionHeader = styled.div`
  gap: ${newTypography.unit * 4}px;
`;
export const DoeOfferFilterHeader = styled.div`
  gap: ${newTypography.unit * 4}px;
`;
export const DoeOfferNewStyledHeader = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 16.25}px ${typography.unit * 6}px 0 ${typography.unit * 6}px;
  width: 94%;
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
`;
export const StyledIcon = styled(Icon)`
  width: 16px;
  fill: ${newGlobalTheme.colors.global.textSubheading};
  margin: ${typography.unit * 4}px 0;
  align-self: center;
`;
export const StyledEditIcon = styled(StyledIcon)`
  margin: 0 ${typography.unit * 3}px 0 0;
`;
// endregion
