import React from 'react';
import { StyledWithThemeProps } from 'utils/types';
import { appTheme } from 'styles/themes/defaultTheme';

export enum MessageType {
  Natural = 'natural',
  Info = 'info',
  Warning = 'warning',
  NewWarning = 'newWarning',
  Success = 'success',
  Error = 'error',
  Critical = 'critical',
  Important = 'important',
}

export const IconNameByType: Record<MessageType, string> = {
  [MessageType.Natural]: 'info',
  [MessageType.Info]: 'info',
  [MessageType.Warning]: 'warning',
  [MessageType.NewWarning]: 'newWarning',
  [MessageType.Success]: 'success',
  [MessageType.Error]: 'error',
  [MessageType.Critical]: 'critical',
  [MessageType.Important]: 'important',
};

export interface NotificationsProps {
  type: MessageType;
  children: string | number | React.ReactNode | JSX.Element | JSX.Element[];
  className?: string;
  theme?: appTheme;
  staticImport?: boolean;
}

export interface StyledNotificationWithTheme extends StyledWithThemeProps {
  type?: MessageType;
}
