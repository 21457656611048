import {
  NotificationActionType,
  NotificationEntityType,
  UserNotification,
  UserNotificationMessage,
} from 'utils/types/notification';
import { capitalize } from 'utils/text';
import { store } from 'app/store';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { OfferFilters } from 'pages/offers/offerManagement/Offers.const';
import { formatDate } from 'utils/date';
import { DateFormats, TimeFormats } from 'utils/types';
import { bulkDoeCampaignUpdate } from 'utils/api/doeCampaigns';
import { bulkDoeOfferUpdate } from 'utils/api/offers';

export const getEnableManagementByZone = (): Boolean => {
  const { enableManagementByZone } = store.getState()?.config?.config;
  return enableManagementByZone;
};
export const getEnableDoe = (): boolean => {
  const { enableDigitalOffersEngine } = store.getState()?.config?.config;
  return enableDigitalOffersEngine;
};

export const getCampaignPage = () => {
  if (getEnableManagementByZone() === false) {
    return 'campaigns';
  }
  return 'campaign-management';
};

export const getDoeCampaignPage = () => {
  if (getEnableDoe()) {
    return 'doe-campaigns';
  }
  return getCampaignPage();
};
export const notificationsMap = new Map<number, UserNotification>();

export const setNotificationsMap = (notifications: UserNotification[]) => {
  notifications.forEach(
    (notification) => !notification.isArchive && notificationsMap.set(notification.id, notification),
  );
};
const viewCampaignLink = ['View Campaigns'];
const viewDoeCampaignLink = ['View DOE Campaigns'];
const embz = getEnableManagementByZone() ? 'campaign-management' : 'campaigns';
const doeembz =  'doe-campaigns' ;
const viewOfferLink  = ['View Offers'];
const viewDoeOfferLink  = ['View DOE Offers'];
const embz2 = getEnableManagementByZone() ? 'offer-management' : 'offers';
const doeembz2 =  'doe-offers';

const prepareBulkCampaignCreationNotificationTemplate = (
  userNotification: UserNotification,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
): UserNotificationMessage | undefined => {
  if (userNotification?.entitiesData?.length === 0) {
    return;
  }
  let failureFlag = false;
  let initiatedAt: any;
  const campaignExternalIds = userNotification?.entitiesData
    ?.map((obj: any) => {
      initiatedAt = obj?.initiatedAt;
      if (!obj.isSuccess) {
        failureFlag = true;
        return null;
      }
      return obj?.externalId;
    })
    .filter((id) => id !== null);
  const url = `./${embz}?${CampaignFilterType.Id}=${campaignExternalIds?.join(',')}`;
  if (failureFlag && campaignExternalIds.length === 0) {
    return {
      ...userNotification,
      message: `
          Bulk Campaign creation by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName}
          & ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is not successful due to an error.`,
    };
  } else if (failureFlag && campaignExternalIds.length > 0) {
    return {
      ...userNotification,
      message: `
          Bulk Campaign creation by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName}
          & ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is partially successful due to an error.`,
      linksString: viewCampaignLink,
      links: [url],
    };
  } else {
    return {
      ...userNotification,
      message: `
          Bulk campaign creation by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName}
          & ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is successful.`,
      linksString: viewCampaignLink,
      links: [url],
    };
  }
};

const prepareImageUpdateNotificationTemplate = (
  userNotification: UserNotification,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
): UserNotificationMessage | undefined => {
  if (userNotification?.entitiesData?.length === 0) {
    return;
  }

  let failureFlag = false;
  let initiatedAt: any;
  const offerIds = userNotification?.entitiesData?.flatMap((obj: any) => obj.offerIds || []);
  const campaignIds = userNotification?.entitiesData?.flatMap((obj: any) => obj.campaignIds || []);
  
  // Check for failureFlag based on the entitiesData
  userNotification.entitiesData.forEach((obj: any) => {
    if (!obj.isSuccess) {
      failureFlag = true;
    }
    initiatedAt = obj?.initiatedAt;
  });

  

  const source = userNotification?.entitiesData[0]?.source;
  const offerSource = source === 'DOE' ? doeembz2 : embz2;
  const campaignSource = source === 'DOE' ? doeembz : embz;

  
  const offerUrl = offerIds.length ? `./${offerSource}?${OfferFilters.Id}=${offerIds.join(',')}` : null;
  const campaignUrl = campaignIds.length ? `./${campaignSource}?${CampaignFilterType.Id}=${campaignIds.join(',')}` : null;

  // Case when all updates are successful (isSuccess = true for all)
  if (!failureFlag) {
    return {
      ...userNotification,
      message: `
          Image ${userNotification?.entitiesData[0]?.imageId} update by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName},
          ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is successful.`,
    };
  } 
  // Case when some updates failed (offerIds or campaignIds exist in the failed items)
  else if ((offerIds?.length > 0 || campaignIds?.length > 0)) {
    
    const response : UserNotificationMessage  = {
      ...userNotification,
      message: `
        Image ${userNotification?.entitiesData[0]?.imageId} updated by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName},
        ${formatDate(
          initiatedAt,
          { dateFormat, timeFormat, startTimezone, endTimezone },
          startTimezone,
        )} is not successful for a few offers and campaigns.`,
      linksString: [],
      links: [],
      secondLinkString: [],
      secondLink: [],
    };

    if (offerUrl) {
      response.linksString = viewOfferLink;
      response.links.push(offerUrl);
    }
  
    if (campaignUrl) {
      response.secondLinkString = viewCampaignLink;
      response.secondLink.push(campaignUrl);
    }
  
    return response;

  } 
  // Case when all updates failed (no offerIds or campaignIds exist in the failed items)
  else {
    return {
      ...userNotification,
      message: `
          Image ${userNotification?.entitiesData[0]?.imageId} update by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName},
          ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is unsuccessful.`,
    };
  }
};

const prepareTermUpdateNotificationTemplate = (
  userNotification: UserNotification,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
  ): UserNotificationMessage | undefined => {
  if (userNotification?.entitiesData?.length === 0) {
    return;
  }

  let failureFlag = false;
  let initiatedAt: any;

  const offerIds = userNotification?.entitiesData?.flatMap((obj: any) => obj.offerIds || []);
  const doeOfferIds = userNotification?.entitiesData?.flatMap((obj: any) => obj.doeOfferIds || []);
  const campaignIds = userNotification?.entitiesData?.flatMap((obj: any) => obj.campaignIds || []);
  const doeCampaignIds = userNotification?.entitiesData?.flatMap((obj: any) => obj.doeCampaignIds || []);

  // Check for failureFlag based on the entitiesData
  userNotification.entitiesData.forEach((obj: any) => {
    if (!obj.isSuccess) {
      failureFlag = true;
    }
    initiatedAt = obj?.initiatedAt;
  });

  const offerUrl = offerIds.length ? `./${embz2}?${OfferFilters.Id}=${offerIds.join(',')}` : null;
  const doeOfferUrl = doeOfferIds.length ? `./${doeembz2}?${OfferFilters.Id}=${doeOfferIds.join(',')}` : null;
  const campaignUrl = campaignIds.length ? `./${embz}?${CampaignFilterType.Id}=${campaignIds.join(',')}` : null;
  const doeCampaignUrl = doeCampaignIds.length ? `./${doeembz}?${CampaignFilterType.Id}=${doeCampaignIds.join(',')}` : null;

  // Case when all updates are successful (isSuccess = true for all)
  if (!failureFlag) {
    return {
      ...userNotification,
      message: `
          Terms and Conditions ${userNotification?.entitiesData[0]?.termId} update by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName},
          ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is successful.`,
    };
  } 
  // Case when some updates failed (offerIds or campaignIds exist in the failed items)
  else if ((offerIds?.length > 0 || doeOfferIds?.length > 0 || campaignIds?.length > 0 || doeCampaignIds?.length > 0)) {
    
    const response : UserNotificationMessage  = {
      ...userNotification,
      message: `
        Terms and Conditions ${userNotification?.entitiesData[0]?.termId} updated by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName},
        ${formatDate(
          initiatedAt,
          { dateFormat, timeFormat, startTimezone, endTimezone },
          startTimezone,
        )} is not successful for a few offers and campaigns.`,
      linksString: [],
      links: [],
      secondLinkString: [],
      secondLink: [],
      thirdLinkString: [],
      thirdLink: [],
      fourthLinkString: [],
      fourthLink: [],
    };

    if (offerUrl) {
      response.linksString = viewOfferLink;
      response.links.push(offerUrl);
    }

    if (doeOfferUrl) {
      response.secondLinkString = viewDoeOfferLink;
      response.secondLink.push(doeOfferUrl);
    }

    if (campaignUrl) {
      response.thirdLinkString = viewCampaignLink;
      response.thirdLink.push(campaignUrl);
    }

    if (doeCampaignUrl) {
      response.fourthLinkString = viewDoeCampaignLink;
      response.fourthLink.push(doeCampaignUrl);
    }

    return response;

  } 
  // Case when all updates failed (no offerIds or campaignIds exist in the failed items)
  else {
    return {
      ...userNotification,
      message: `
          Terms and Conditions ${userNotification?.entitiesData[0]?.termId} update by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName},
          ${formatDate(
            initiatedAt,
            { dateFormat, timeFormat, startTimezone, endTimezone },
            startTimezone,
          )} is unsuccessful.`,
    };
  }
  };

const prepareBulkCampaignNotificationTemplate = (
  userNotification: UserNotification,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
): UserNotificationMessage | undefined => {
  if (userNotification?.entitiesData?.length === 0) {
    return;
  }
  const campaignExternalIds = userNotification?.entitiesData?.map((obj: any) => obj.externalId);
  const url = `./${embz}?${CampaignFilterType.Id}=${campaignExternalIds?.join(',')}`;
  return {
    ...userNotification,
    message: `
    Bulk Campaign ${
      userNotification.action === NotificationActionType.BulkSubmissionForApproval ? 'Submission for ' : ''
    }Approval by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName}
      & ${formatDate(
        userNotification?.entitiesData?.[0].initiatedAt,
        { dateFormat, timeFormat, startTimezone, endTimezone },
        startTimezone,
      )} is completed.`,
    linksString: viewCampaignLink,
    links: [url],
  };
};

const prepareNoLocationProductNotificationTemplate = (
  userNotification: UserNotification,
): UserNotificationMessage | undefined => {
  if (userNotification?.entitiesData?.length === 0) {
    return;
  }
  const idStrings = userNotification.entitiesData.map((id) => `${id}`);
  return {
    ...userNotification,
    message: `
          The following ${capitalize(userNotification.entityType)}s have no active ${
      userNotification.action === NotificationActionType.NoLocations
        ? NotificationEntityType.Location
        : NotificationEntityType.Product
    }s defined and have been automatically ${
      userNotification.entityType === NotificationEntityType.Campaign ? 'revoked' : 'rejected'
    }.`,
    linksString: idStrings,
    links: idStrings.map(
      (entityId) =>
        `./${
          userNotification.entityType === NotificationEntityType.Campaign ? embz : 'offers'
        }?searchQuery=${entityId}`,
    ),
  };
};

const prepareBulkStopRevokeNotificationTemplate = (
  userNotification: UserNotification,
  activeFilter: string,
  type: string,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
): UserNotificationMessage | undefined => {
  const doeCampaignPage = getDoeCampaignPage();
  let initiatedAt: any;
  const campaignExternalIds = userNotification?.entitiesData
    ?.map((obj: any) => {
      initiatedAt = obj?.initiatedAt;
      return obj?.externalId;
    })
    .filter((id) => id !== null);
  return {
    ...userNotification,
    message: `Campaign ${type} by ${userNotification?.user?.firstName} ${userNotification?.user?.lastName} at ${formatDate(
      initiatedAt,
      { dateFormat, timeFormat, startTimezone, endTimezone },
      startTimezone,
    )} is completed.`,
    linksString: viewCampaignLink,
    links: [`./${doeCampaignPage}?${activeFilter}&${CampaignFilterType.Id}=${campaignExternalIds?.join(',')}`],
  };
};

const prepareBulkEditCampaignNotificationTemplate = (
  userNotification: UserNotification,
  message: string,
): UserNotificationMessage | undefined => {
  const result: UserNotificationMessage = {
    ...userNotification,
    message,
  };

  // if additionalInfo is present, it means that the bulk edit failed, and we may need to add a callToAction
  if (userNotification.entitiesData[0].additionalInfo) {
    const additionalInfo = userNotification.entitiesData[0].additionalInfo;
    if (additionalInfo.retryCount >= 3) {
      result.message += ` You've reached the maximum number of retries.`
      result.additionalMessage = `We've reported this issue to the Support Team.`;
    } else {
      result.callToAction = {
        label: 'Click here to retry',
        action: async () => bulkDoeCampaignUpdate(additionalInfo),
      };
    }
  }

  return result;
};

const prepareBulkEditOfferNotificationTemplate = (
    userNotification: UserNotification,
    message: string,
): UserNotificationMessage | undefined => {
  const result: UserNotificationMessage = {
    ...userNotification,
    message,
  };

  // if additionalInfo is present, it means that the bulk edit failed, and we may need to add a callToAction
  if (userNotification.entitiesData[0].additionalInfo) {
    let additionalInfo = userNotification.entitiesData[0].additionalInfo;
    if (additionalInfo.pointOfDistribution) {
      // When sending additionalInfo through the gateway, we need to capitalize the first letter to match the enum
      const mappedPointsOfDistribution = additionalInfo.pointOfDistribution.map(
          (item: string ) => item.length > 0 ? item.charAt(0).toUpperCase() + item.slice(1) : item
      );
      additionalInfo = { ...additionalInfo, pointOfDistribution: mappedPointsOfDistribution };
    }
    if (additionalInfo.retryCount >= 3) {
      result.message += ` You've reached the maximum number of retries.`
      result.additionalMessage = `We've reported this issue to the Support Team.`;
    } else {
      result.callToAction = {
        label: 'Click here to retry',
        action: async () => bulkDoeOfferUpdate(additionalInfo),
      };
    }
  }
  return result;
};

export const handleDifferenceBetweenSubscriptionAndState = (
  fromSubscription: UserNotification[],
): UserNotification[] => {
  fromSubscription.forEach((notification) => {
    notificationsMap.set(notification.id, notification);
    if (notification.isArchive) {
      notificationsMap.delete(notification.id);
    }
  });
  return Array.from(notificationsMap.values());
};

export const getNotificationTemplate = (
  userNotification: UserNotification,
  dateFormat: DateFormats,
  timeFormat: TimeFormats,
  startTimezone: string,
  endTimezone: string,
): UserNotificationMessage | undefined => {
  switch (userNotification.action) {
    case NotificationActionType.LocationUpdate:
    case NotificationActionType.ProductUpdate:
      return {
        ...userNotification,
        message: `${capitalize(userNotification.entityType)} update has occurred.`,
        linksString: ['Go to report'],
        links: [`./${userNotification.entityType}-changes`],
      };
    case NotificationActionType.GameLocationUpdateFailed:
      return {...userNotification, message: `Location update failed for ${userNotification.game?.name}.`};
    case NotificationActionType.GameLocationUpdateSuccess:
      return {
        ...userNotification,
        message: `Location update occurred for ${userNotification.game?.name}.`,
        linksString: ['Go to report'],
        links: [`./${userNotification.entityType}-changes`],
      };
    case NotificationActionType.BulkRevoke:
      return prepareBulkStopRevokeNotificationTemplate(
        userNotification,
        `status=revoked`,
        'bulk revoke',
        dateFormat,
        timeFormat,
        startTimezone,
        endTimezone,
      );
    case NotificationActionType.BulkStopAssociation:
      return prepareBulkStopRevokeNotificationTemplate(
        userNotification,
        `status=active`,
        'bulk stop association',
        dateFormat,
        timeFormat,
        startTimezone,
        endTimezone,
      );
    case NotificationActionType.NoLocations:
    case NotificationActionType.NoProducts:
      return prepareNoLocationProductNotificationTemplate(userNotification);
    case NotificationActionType.BulkCampaignCreation:
      return prepareBulkCampaignCreationNotificationTemplate(
        userNotification,
        dateFormat,
        timeFormat,
        startTimezone,
        endTimezone,
      );
    case NotificationActionType.BulkSubmissionForApproval:
    case NotificationActionType.BulkCampaignApproval:
      return prepareBulkCampaignNotificationTemplate(
        userNotification,
        dateFormat,
        timeFormat,
        startTimezone,
        endTimezone,
      );
    case NotificationActionType.ImageEdit:
      return prepareImageUpdateNotificationTemplate(
        userNotification,
        dateFormat,
        timeFormat,
        startTimezone,
        endTimezone,
      );  
    case NotificationActionType.TermEdit:
      return prepareTermUpdateNotificationTemplate(
        userNotification,
        dateFormat,
        timeFormat,
        startTimezone,
        endTimezone,
      );  
    case NotificationActionType.NewUser:
    case NotificationActionType.UserUpdate:
    case NotificationActionType.CampaignErrors:
    case NotificationActionType.NoRedemptions:
    case NotificationActionType.CampaignApprovalRequired:
    case NotificationActionType.BulkOfferEditSuccess:
      return prepareBulkEditOfferNotificationTemplate(userNotification, 'Your offer bulk edit is complete.');
    case NotificationActionType.BulkOfferEditFailed:
      return prepareBulkEditOfferNotificationTemplate(userNotification, 'Your offer bulk edit failed.');
    case NotificationActionType.BulkOfferEditPartialSuccess:
      return prepareBulkEditOfferNotificationTemplate(userNotification,
          `${userNotification.entitiesData[0]?.totalCount - userNotification.entitiesData[0]?.successCount} of ${userNotification.entitiesData[0]?.totalCount} offers failed processing during your bulk edit.`);
    case NotificationActionType.BulkCampaignEditSuccess:
      return prepareBulkEditCampaignNotificationTemplate(userNotification, 'Your campaign bulk edit is complete.');
    case NotificationActionType.BulkCampaignEditPartialSuccess:
      return prepareBulkEditCampaignNotificationTemplate(userNotification,
          `${userNotification.entitiesData[0]?.totalCount - userNotification.entitiesData[0]?.successCount} of ${userNotification.entitiesData[0]?.totalCount} campaigns failed processing during your bulk edit.`);
    case NotificationActionType.BulkCampaignEditFailure:
      return prepareBulkEditCampaignNotificationTemplate(userNotification, 'Your campaign bulk edit failed.');
    default:
      break;
  }
}