import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import { StyledErrorProps } from './Error.consts';
import { Icon } from '../icon';

export const DoeErrorContainer = styled.div`
  display: flex;
  gap: ${newTypography.unit}px;
`;

export const DoeStyledError = styled.div<StyledErrorProps & { className?: string }>`
  display: flex;
  align-items: center;
  color: ${newGlobalTheme.colors.text.error};
  font-family: ${newTypography.primaryFont};
  font-size: ${newTypography.smallFontSize}px;
  font-style: normal;
  font-weight: 400;
  line-height: ${({ className }) => (['SetItemSelectionForm'].includes(className) ? 22.83 : 12.64)}px;
  text-align: left;
  letter-spacing: -0.03em;
`;

export const DoeStyledIcon = styled(Icon)<{ tabIndex?: number }>`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.text.secondary};
`;
