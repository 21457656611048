import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import { useOperationCounter } from 'app/apollo/operationCounter';
import difference from 'lodash/difference';
import Backdrop from '../backdrop/Backdrop';
import { DoeOfferModalProps } from './Modal.const';
import {
  DoeOfferMainTitle,
  DoeOfferContent,
  DoeOfferModalHeader,
  DoeOfferContainer,
  DoeOfferTextModalTitle,
  CloseIcon,
  CloseButtonText,
} from './DoeOfferModal.style';
import { Loader } from '../loader';
import NewTextModalTitle from '../text/textModalTitle/NewTextModalTitle';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { StyledButtonDropdown } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';
import { DraftOfferActionsList, OfferActions, OfferActionsList } from 'utils/types/offers';
import { FormMode } from 'utils/types';
import { Loading } from './NewModal.style';

import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';

const DoeOfferModal = ({
  children,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  contentWidth,
  // onActionClicked,
  modalMode,
  // status,
  showMoreActions,
  ...rest
}: DoeOfferModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();

  useEffect((): (() => void) => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const headerRef = useRef(null);

  useEffect(() => {
    // Focus on the header when the modal opens
    if (headerRef.current) {
      headerRef.current.focus();
    }
  }, [modalMode]);

  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <DoeOfferContainer className={className} data-automation-id="modal" {...rest}>
        {withLoader && (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
        <>
          <DoeOfferModalHeader className="ModalHeader">
            <DoeOfferMainTitle ref={headerRef} tabIndex={0}>
              {title && <DoeOfferTextModalTitle>{title}</DoeOfferTextModalTitle>}
              {isLocked && <LockIcon />}
            </DoeOfferMainTitle>
            {subtitle ?? null}
            <CloseButtonText aria-label='Close' onClick={() => {store.dispatch(closeModal())}} data-title="Close">
              <CloseIcon name="close" />
            </CloseButtonText>
          </DoeOfferModalHeader>
          <DoeOfferContent contentWidth={contentWidth} data-automation-id="modal-content">
            {children}
          </DoeOfferContent>
        </>
      </DoeOfferContainer>
    </>,
    document.querySelector('#modal'),
  );
};

export default DoeOfferModal;
