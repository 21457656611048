import React, { useEffect, useState } from 'react';
import { StyledLoader } from 'pages/shared/shared.style';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { tabName } from 'components/header/Header.consts';
import { FormMode } from 'utils/types';
import {
  Actions,
  CampaignPriorityHeader,
  CampaignPriorityTextTitle,
  CancelButton,
  EditButton,
  EditTextWrapper,
  PriorityColumn,
  PriorityFooter,
  PriorityFooterWrapper,
  PriorityRow,
  StyledDnDItems,
} from './DealPriorityConfiguration.style';
import DragAndDropList from 'components/shared/dragAndDrop/DragAndDrop';
import {
  closestCorners,
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import PriorityTitle from './components/priorityTitle/PriorityTitle';
import { users } from 'app/slices/users';
import { useSelector } from 'react-redux';
import { UserRole } from 'utils/types/users';
import { isInArray } from 'utils/array';
import { useQuery } from '@apollo/client';
import dealPriorityConfiguration from './DealPriorityConfiguration.gqls';
import { FetchPolicies } from 'utils/types/common';
import { CampaignType, CampaignTypeLabel } from 'utils/types/campaigns';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { updateDealPriority } from 'utils/api/dealPriority';
import { DealPriority } from './components/DealPriorityConfiguration.const';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { Loader } from 'components/shared/loader';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import { Icon } from 'components/shared/icon';
import { getUserRole } from '../../../utils/users';

const DealPriorityConfiguration = () => {
  const { user } = useSelector(users);
  const isUserEligibleForEdit = isInArray([UserRole.SysAdmin, UserRole.Admin], getUserRole(user));
  const { data } = useQuery(dealPriorityConfiguration.queries.getAllDealPriority, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [priorityChanged, setPriorityChanged] = useState(true);
  const priority = [
    { id: 1, name: 'Priority 1', value: 10000 },
    { id: 2, name: 'Priority 2', value: 20000 },
    { id: 3, name: 'Priority 3', value: 30000 },
    { id: 4, name: 'Priority 4', value: 40000 },
    { id: 5, name: 'Priority 5', value: 50000 },
    { id: 6, name: 'Priority 6', value: 60000 },
  ];
  let initalPriority: React.SetStateAction<any[]> = [];
  const dealPriorityArray = data?.getDealPriority || [];
  const [items, setItems] = useState(initalPriority);
  const [mode, setMode] = useState(FormMode.View);
  const isDisabled = mode === FormMode.View;

  useEffect(() => {
    const originalCampaignTypeOrder = (initalPriority as []).map(
      (originalpriority: DealPriority) => originalpriority.campaignType,
    );
    const updatedCampaignTypeOrder = items.map((updatedPriority: DealPriority) => updatedPriority.campaignType);
    if (JSON.stringify(originalCampaignTypeOrder) === JSON.stringify(updatedCampaignTypeOrder)) {
      setPriorityChanged(false);
    } else {
      setPriorityChanged(true);
    }
  }, [items]);

  if (data && data.getDealPriority) {
    initalPriority = dealPriorityArray
      .slice()
      .sort((a: { priority: number }, b: { priority: number }) => a.priority - b.priority)
      .map((item: any) => ({
        ...item,
        name: CampaignTypeLabel[item.campaignType as CampaignType],
      }));
  }
  const getTaskPos = (id: number) => items.findIndex((task: { id: number }) => task.id === id);
  function handleCancel() {
    setMode(FormMode.View);
    setItems(initalPriority);
  }
  function handleOnEdit() {
    setMode(FormMode.Edit);
  }

  async function handleSubmit(itemsToSubmit: DealPriority[]) {
    const transformedItems: DealPriority[] = itemsToSubmit.map(({ name, priorityId, __typename, ...rest }) => rest);
    try {
      setIsSubmitting(true);
      await updateDealPriority(transformedItems);
      setIsSubmitting(false);
      setMode(FormMode.View);
    } catch (e) {
      showToast(MessageType.Error, `Default Campaign priority not updated`);
      setIsSubmitting(false);
    }
  }
  useEffect(() => {
    if (data && data.getDealPriority) {
      setItems(initalPriority);
    }
  }, [data]);

  const itemCount = items.length;

  const getNameOfItem = (active: any) => {
    let nameOfMovedItem: string;
    dealPriorityArray.map((dealPriorityItem: any) => {
      if (dealPriorityItem.id === active.id) {
        nameOfMovedItem = dealPriorityItem.campaignType;
      }
    });
    return nameOfMovedItem;
  };

  const announcements = {
    onDragStart({ active }: DragStartEvent) {
      return `Picked up sortable item ${getNameOfItem(active)}.`;
    },
    onDragOver({ active, over }: DragOverEvent) {
      return `Picked up sortable item ${getNameOfItem(active)}. `;
    },
    onDragEnd({ active, over }: DragEndEvent) {
      let voiceover;
      setItems((allitems: any) => {
        const originalPos = getTaskPos(active.id as number);
        const newPos = getTaskPos(over.id as number);
        const newItems = arrayMove(allitems, originalPos, newPos);
        return newItems.map((item: any, index: any) => {
          return {
            ...item,
            priorityId: priority[index].id,
            priority: priority[index].value,
          };
        });
      });
      if (over) {
        const movedToIndex = over.data.current.sortable.index + 1;
        voiceover = `Sortable item ${getNameOfItem(active)} was dropped at priority ${movedToIndex} of ${itemCount}`;
      }
      return voiceover;
    },
    onDragCancel({ active }: DragCancelEvent) {
      return `Dragging was cancelled. Sortable item ${getNameOfItem(active)} was dropped.`;
    },
  };
  return (
    <>
      {isSubmitting && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <PageContainer>
        <CampaignPriorityHeader data-automation-id="header">
          <CampaignPriorityTextTitle text={tabName.DealsPriorityConfiguration} />
          <Actions>
            {mode === FormMode.View && isUserEligibleForEdit && (
              <EditButton onClick={handleOnEdit} aria-label="Edit">
                <Icon width={25} height={25} name="newEdit" />
                <EditTextWrapper>Edit</EditTextWrapper>
              </EditButton>
            )}
            {mode === FormMode.Edit && (
              <>
                <EditButton isSave disabled={!priorityChanged} onClick={() => handleSubmit(items)} aria-label="Save">
                  Save
                </EditButton>{' '}
                <CancelButton onClick={handleCancel} aria-label="Cancel">
                  Cancel
                </CancelButton>
              </>
            )}
          </Actions>
        </CampaignPriorityHeader>
        {!items.length ? (
          <StyledLoader size={LoaderSize.Medium} />
        ) : (
          <StyledDnDItems data-automation-id={`drag-and-drop-box`}>
            <PriorityColumn>
              <PriorityRow>
                <PriorityTitle priority={priority} />
                <DndContext accessibility={{ announcements }} collisionDetection={closestCorners}>
                  <DragAndDropList items={items} disabled={isDisabled} color={'grey'} border={'darkGrey'} />
                </DndContext>
              </PriorityRow>
              <PriorityFooterWrapper>
                <PriorityFooter />
              </PriorityFooterWrapper>
            </PriorityColumn>
          </StyledDnDItems>
        )}
      </PageContainer>
    </>
  );
};

export default DealPriorityConfiguration;
