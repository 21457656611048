import React from 'react';
import {
  StyledMessage,
  DoeBannerContainer,
  DoeBannerMessageContainer,
  DoeBannerErrorTypeLine,
} from './DoeBanner.style';
import { NotificationsProps } from '../notifications';

const Banner = ({ type, children, className }: NotificationsProps) => {
  return (
    <DoeBannerContainer role="banner">
      <DoeBannerErrorTypeLine type={type} />
      <DoeBannerMessageContainer>
        <StyledMessage type={type} className={className}>
          {children}
        </StyledMessage>
      </DoeBannerMessageContainer>
    </DoeBannerContainer>
  );
};

export default Banner;
