import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ApprovalStatus, FormMode } from 'utils/types';
import { Language, LanguageRecord } from 'utils/languages';

import {
  BaseOffer,
  DiscountCondition,
  OfferActions,
  OfferTemplates,
  PointOfDistributionRecord,
} from 'utils/types/offers';
import { Term } from 'utils/types/terms';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import { convertEnumToObjectArray } from 'utils/mapping';
import ReactTooltip from 'react-tooltip';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { store } from 'app/store';
import { closeModal, Modals, openModal, setData } from 'app/slices/modals';
import { createNewOffer, createOfferTag, setOfferApprovalStatus } from 'utils/api/offers';
import { NoImageText } from 'pages/offers/offerManagement/Offers.style';
import { cloneDeep, forOwn, get } from 'lodash';
import { TermConditionProps } from 'pages/settings/termsConditions/TermsConditions.consts';
import { hasValue } from 'utils/text';
import { isInArray } from 'utils/array';
import { OfferFormStyledSectionTitle } from 'pages/shared/shared.style';
import { getOfferStatus, viewImageClicked } from 'utils/offer';
import { hideTooltip } from 'utils/tooltip';
import { EntityApproveButton } from 'pages/shared/entityApproveButton/EntityApproveButton';
import { EntityType } from 'pages/shared/entityApproveButton/EntityApproveButton.consts';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import {
  checkForEmptyData,
  convertToGenericSet,
} from 'pages/offers/offerManagement/components/offerForm/components/templates/shared/ProductsActionCondition.utils';
import useFeatureFlag from 'hooks/use-feature-flag';
import { Feature } from 'utils/types/features';
import { termsConditionsSelection } from 'app/genericSlices/termsConditions';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import {
  AdditionalInfoRow,
  BaseTextButton,
  CancelButton,
  ContentContainer,
  ContentTabWrapper,
  GroupIdTextField,
  ImageColumnWrapper,
  ImageContainer,
  ImageRowContainer,
  ImageTextWrapper,
  OfferFormAdditionalDetails,
  OfferFormContainer,
  OfferFormContentContainer,
  OfferFormFooter,
  OfferFormImage,
  OfferFormImageContainer,
  OfferFormSection,
  OfferTemplateSection,
  SaveButtons,
  SelectImageSecondaryButton,
  SelectionSection,
  StyledGoldButton,
  StyledImageButtonText,
  StyledInfoTooltip,
  StyledSelectImageButton,
  StyledTabStrip,
  StyledTermsAndConditionsTextArea,
  StyledViewImage,
  StyledWhiteButton,
  TagsSelectbox,
  TemplateSelectbox,
  TermsConditionsSelectbox,
  TermsConditionsWrapper,
  TermsTextArea,
} from './OfferForm.style';
import { OfferFormProps } from './OfferForm.const';
import { renderTemplate } from './components/templates';
import { useOfferFromRedux } from './components/hooks/useOfferFromRedux';
import { OfferStatusLabel } from './components/offerStatusLabel/OfferStatusLabel';
import {
  archiveOffer,
  onOfferArchive,
  onOfferEdit,
  onOfferReject,
  onOfferViewImpact,
} from '../offerActions/OfferActions';
import { PriceOffValidationError } from './components/priceOffValidationError/PriceOffValidationError';
import { ValidationMessages } from 'utils/types/common';
import { inProcessErrorMessage } from 'pages/shared/shared.consts';
import { OfferSource } from '../../Offers.const';
import { OfferTemplatesConfig, convertStringToIntArray } from 'pages/configurations/config.util';
import OfferCheckboxGroup from 'components/shared/checkboxGroup/OfferCheckboxGroup';
import OfferToolTip from 'components/shared/tooltip/OfferToolTip';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import { ButtonContained } from 'components/shared/button';
import OfferModal from 'components/shared/modal/OfferModal';
import { convertUtcDateToTimezoneDate, getMarketDatetime } from 'utils/date';
import { GMAPreviewButton } from 'pages/shared/gmaPreviewButton/GmaPreviewButton';

const OfferForm = ({
  offer,
  defaultLanguage,
  mode,
  onClose,
  viewOnly = false,
  offerFormTags,
  offerSelectedLanguage,
  offerSource,
  tempValuesChanged,
  fromCampaignScreen = false,
}: OfferFormProps) => {
  const {
    offer: persistentOffer,
    mode: persistentMode,
    selectedLanguage: persistentLanguage,
    offerSource: persistentOfferSource,
  }: { offer: BaseOffer; mode: string; selectedLanguage: string; offerSource: string } = useOfferFromRedux(
    cloneDeep(offer),
  );

  const templateValuesString = 'versions[0].templateValues';

  let loadTags: any;
  let tags: any[];
  if (!offerFormTags) {
    offerFormTags = useTagsQuery([TagsValidEntities.Offer]);
    loadTags = offerFormTags.load;
    tags = offerFormTags.tags;
  } else {
    loadTags = offerFormTags.load;
    tags = offerFormTags.tags;
  }

  const compareOffers = () => {
    if (offer) {
      if (offer !== persistentOffer) {
        return false;
      }
    }
    return true;
  };
  const [voucherDetails, setVoucherDetails] = useState({
    name: '',
    startDate: '',
    endDate: '',
  });

  const [tempTranslationChange, setTempTranslationChange] = useState<boolean>(tempValuesChanged);

  const { config, languages } = useSelector(marketConfig);
  const draftCampaignOfferEnhancement = useFeatureFlag(Feature.DraftCampaignOfferEnhancement);
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: compareOffers() ? (persistentOffer as any) : (offer as any),
  });
  const [selectedTemplate, setSelectedTemplate] = useState(formMethods.getValues('templateType'));

  const [selectedLanguage, setSelectedLanguage] = useState(
    persistentLanguage ?? defaultLanguage ?? config.primaryLanguage,
  );
  const [tempTranslationsMap, setTempTranslationsMap] = useState<{ [key: string]: string }>({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalMode, setModalMode] = useState(mode ?? persistentMode);
  const [modalOfferSource] = useState(offerSource ?? persistentOfferSource);
  const isInitialMount = useRef(true);
  const terms = useSelector((state: any) => state.terms);

  const { modals } = useSelector((state: any) => state);

  let termsConditions: any;

  if (modalOfferSource === OfferSource.DOE) {
    const selectedTemplateTermsFromConfig = convertStringToIntArray(
      get(config, OfferTemplatesConfig[selectedTemplate].name),
    );
    const selectedTemplateTermsFromConfigSet = new Set(selectedTemplateTermsFromConfig);
    termsConditions =
      selectedTemplate && terms.termsConditionsSelection.termsConditions && selectedTemplateTermsFromConfig
        ? terms.termsConditionsSelection.termsConditions.filter((t: Term) => {
            return selectedTemplateTermsFromConfigSet.has(Number(t.id));
          })
        : [];
  } else {
    termsConditions =
      selectedTemplate && terms.termsConditionsSelection.termsConditions
        ? terms.termsConditionsSelection.termsConditions
            .filter((t: Term) => t.template.includes(selectedTemplate))
            .map((t: Term) => ({ ...t, id: Number(t.id) }))
        : [];
  }

  const requiredValidation = useMemo(() => ({ required: true }), []);
  const offerStatus = getOfferStatus(persistentOffer);
  const shouldDisplayInfoIcon =
    (persistentOffer?.versions && persistentOffer?.versions[0].term?.originalTermId) ||
    (isInArray([FormMode.New, FormMode.Edit, FormMode.Duplicate], modalMode) &&
      formMethods.formState.dirtyFields.term_content);
  let disabledGroupIdInput =
    modalMode === FormMode.View ||
    (persistentOffer.segmentId && ![ApprovalStatus.Draft, ApprovalStatus.PendingApproval].includes(offerStatus));

  const setTranslations = (tc: any) => {
    if (!tc) {
      return;
    }
    const content = tc.translationsMap;
    if (content) {
      formMethods.setValue(
        `term_content`,
        Object.keys(content).reduce((m, key) => ({ ...m, [key]: content[key].content }), {}),
        { shouldValidate: true },
      );
    }
    formMethods.setValue(`term_name`, tc.name);
    formMethods.setValue(`versions.0.term`, tc.originalTermId ?? tc.id);
  };

  let hasExpireVoucherGroupError = false;
  if (
    voucherDetails &&
    getMarketDatetime(config.endTimezone).getTime() >
      convertUtcDateToTimezoneDate(voucherDetails.endDate, config.endTimezone).getTime()
  ) {
    hasExpireVoucherGroupError = true;
  }

  let hasProductsOrVoucherGroup = false;
  const checkProducts = (productsObject: any): boolean => {
    let res = false;
    forOwn(productsObject, (value, key) => {
      if (value?.length > 0) {
        res = true;
      }
    });
    return res;
  };
  // checking offer has product set or not
  if (modalMode === FormMode.New || modalMode === FormMode.Duplicate) {
    hasProductsOrVoucherGroup = true;
    disabledGroupIdInput = false;
  } else if (modalMode === FormMode.Edit || offerStatus === 'draft') {
    const templateValues = get(formMethods.getValues(), templateValuesString);
    switch (selectedTemplate) {
      case '1':
      case '11':
        hasProductsOrVoucherGroup =
          checkProducts(templateValues.buyProducts) && checkProducts(templateValues.getProducts);
        break;
      case '2':
        hasProductsOrVoucherGroup = checkProducts(templateValues.products);
        break;
      case '3':
        hasProductsOrVoucherGroup = checkProducts(templateValues.buyProducts);
        break;
      case '4':
        hasProductsOrVoucherGroup = checkProducts(templateValues.substitutes) && templateValues.product !== undefined;
        break;
      case '5':
        if (templateValues.discountCondition === DiscountCondition.WithPurchaseOf) {
          hasProductsOrVoucherGroup = checkProducts(templateValues.conditionProducts);
        } else {
          hasProductsOrVoucherGroup = true;
        }
        break;
      case '7':
        hasProductsOrVoucherGroup =
          checkProducts(templateValues.buyProductsA) &&
          checkProducts(templateValues.buyProductsB) &&
          checkProducts(templateValues.getProducts);
        break;
      case '8':
        hasProductsOrVoucherGroup =
          checkProducts(templateValues.buyProducts) &&
          checkProducts(templateValues.getProductsA) &&
          checkProducts(templateValues.getProductsB);
        break;
      case '10':
        hasProductsOrVoucherGroup = checkProducts(templateValues.products);
        break;
      case '13':
        let count = 0;
        templateValues?.offerTemplates?.forEach((offerTemplate: any) => {
          const res = checkProducts(offerTemplate.buyProducts);
          if (res) {
            count++;
          }
        });
        hasProductsOrVoucherGroup = count === templateValues?.offerTemplates?.length;
        break;
      case '14':
        hasProductsOrVoucherGroup = templateValues.nonFoodDiscountRewards !== undefined ? true : false;
        break;
    }
  }

  if (
    (modalMode === FormMode.Duplicate || modalMode === FormMode.Edit || modalMode === FormMode.View) &&
    (selectedTemplate === '5' || selectedTemplate === '10' || selectedTemplate === '2')
  ) {
    const templateValues = get(formMethods.getValues(), templateValuesString);
    if (checkForEmptyData(templateValues?.includeNonFoodProductCodes)) {
      formMethods.setValue('versions[0].templateValues.includeNonFoodProductCodes', undefined);
    }
  }

  const versionTermPath = 'versions.0.term';
  const [term] = formMethods.watch([versionTermPath]);

  const handleNewTerm = (termId: number) => {
    const {
      term_content: termsTranslations,
      term_name: name,
      templateType: template,
      versions,
    } = formMethods.getValues();

    if (termId) {
      const tc = termsConditions.find((t: TermConditionProps) => Number(t.id) === Number(termId));
      const termContent = formMethods.getValues('term_content');

      let dirtyTc = formMethods.formState.dirtyFields.term_content;
      if (tc) {
        Object.keys(tc.translationsMap).forEach((l) => {
          if (tc.translationsMap[l].content !== termContent[l]) {
            dirtyTc = true;
          }
        });
      } else if (
        !terms?.termsConditionsSelection?.termsConditions
          ?.find((t: TermConditionProps) => Number(t.id) === Number(termId))
          ?.template.includes(template)
      ) {
        const persistentTc: any = persistentOffer?.versions?.[0]?.term;

        if (persistentTc && persistentTc.translationsMap) {
          Object.keys(persistentTc.translationsMap).forEach((l) => {
            if (persistentTc.translationsMap[l].content !== termContent[l]) {
              dirtyTc = true;
            }
          });
        }
        if (!dirtyTc) {
          return { id: persistentTc.id };
        }
      }
      if (dirtyTc) {
        return {
          name,
          template: [template],
          originalTermId: Number(versions[0].term),
          translations: Object.entries(termsTranslations).map((value) => ({
            language: value[0],
            content: value[1],
          })),
        };
      }

      return { id: Number(termId) };
    }
  };

  const onSubmit = (isDraft = true) => {
    return async (formData: any) => {
      formData.versions[0].term = handleNewTerm(formData.versions[0].term);

      try {
        if (modalMode === FormMode.New || modalMode === FormMode.Duplicate) {
          setIsSubmitting(true);
          await createNewOffer(formData, isDraft);
          setIsSubmitting(false);
          showToast(MessageType.Success, `Offer added successfully`);
          store.dispatch(closeModal());
        } else if (isInArray([FormMode.Edit, FormMode.View], modalMode)) {
          await onOfferEdit(persistentOffer, formData, isDraft, offerSource);
        }
      } catch (e) {
        showToast(MessageType.Error, `Failed to ${modalMode === FormMode.Edit ? 'update' : 'create'} offer`);
        setIsSubmitting(false);
      }
    };
  };

  const getOfferImage = (lang: Language) =>
    persistentOffer?.versions && persistentOffer?.versions[0]?.translationsMap[lang]?.image;

  const getModalTitle = () => {
    switch (modalMode) {
      case FormMode.New:
        return 'Add Offer';
      case FormMode.Edit:
        return persistentOffer?.offerSource === OfferSource.VCE
          ? `Edit Offer ID ${persistentOffer?.id}`
          : `Edit DOE Offer ID ${persistentOffer?.id}`;
      case FormMode.View:
        return `View Offer ID ${persistentOffer?.id}`;
      case FormMode.Duplicate:
        return `Duplicate Offer ID ${persistentOffer?.id}`;
      default:
        return '';
    }
  };

  const [isRejectButtonClicked, setIsRejectButtonClicked] = useState(false);

  const handleRejectClick = async (offer1: BaseOffer, rejectionComment: string) => {
    if (!isRejectButtonClicked) {
      setIsRejectButtonClicked(true);
      await onOfferReject(offer1, rejectionComment);
      hideTooltip('#reject-tooltip');
      ReactTooltip.hide();
    }
  };

  const onTagCreation = async (tagName: string) => {
    try {
      const tag = await createOfferTag(tagName);
      return tag?.data?.createTag;
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to add tag${
          e.message.includes('duplication item')
            ? ` - ${tagName} already exists in another entity. Please update tag entity or contact Admin`
            : ''
        }
        ${e.message.includes('char limit') ? ` - Exceeds 50 Character limit` : ''}`,
      );
      return null;
    }
  };

  const handleDuplicateChange = () => {
    setTranslations(offer.versions[0].term);
    formMethods.setValue(
      `tags`,
      offer.tags.map((t) => t.id),
    );
  };

  const onActionClicked = async (actionName: OfferActions) => {
    switch (actionName) {
      case OfferActions.Archive:
        await onOfferArchive(offer);
        break;
      case OfferActions.Unarchive: {
        await archiveOffer(offer, false);
        break;
      }
      case OfferActions.Duplicate:
        setModalMode(FormMode.Duplicate);
        handleDuplicateChange();
        break;
      case OfferActions.ViewImpact:
        await onOfferViewImpact(offer);
        break;
      case OfferActions.Reject:
        await onOfferReject(offer, '');
        break;
      case OfferActions.Edit:
        setModalMode(FormMode.Edit);
        break;
      case OfferActions.Preview:
        // TODO: Need to implemeneted
        break;
      default:
    }
  };

  const inputValidation = (value: string, language: string, message: string) => {
    if (
      (language === config.primaryLanguage ||
        (language === config.secondaryLanguage && config.isSecondaryLanguageEnforced)) &&
      !hasValue(value)
    ) {
      return message;
    }
  };

  const shouldShowField = (fieldName: string) => {
    if (modalMode === FormMode.View) {
      const fieldVal = formMethods.getValues(fieldName);
      if (fieldVal && Array.isArray(fieldVal)) {
        return fieldVal?.length > 0;
      }
      return fieldVal;
    }
    return true;
  };

  const onApproveClick = async () => {
    try {
      await setOfferApprovalStatus(
        persistentOffer.versions[0].approvals[0]?.id,
        persistentOffer.id,
        persistentOffer.versions[0].id,
        ApprovalStatus.Approved,
        1,
      );
      showToast(MessageType.Success, `Offer approved successfully`);
      store.dispatch(closeModal());
    } catch (e) {
      if (e.message === inProcessErrorMessage) {
        showToast(
          MessageType.Error,
          `Offer cannot be modified at this time as associated campaign approval is in progress`,
        );
      } else if (e.message === `Image failed to upload in S3 Bucket`) {
        showToast(MessageType.Error, `Offer update failed`);
      } else {
        showToast(MessageType.Error, e.message);
      }
      store.dispatch(closeModal());
    }
  };

  const getTermName = () => {
    const termId = term?.id ?? term;
    const selectedTerm = termsConditions.find((t: any) => Number(t.id) === Number(termId));
    return selectedTerm?.name;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [formMethods.formState.isDirty, modalMode === FormMode.Duplicate]);

  useEffect(() => {
    if (selectedTemplate && !isInitialMount.current) {
      if (termsConditions?.length === 1) {
        setTranslations(termsConditions[0]);
      } else if (!termsConditions.some((item: { id: any }) => Number(item.id) === Number(term))) {
        // Reset terms
        formMethods.reset(
          {
            ...formMethods.getValues(),
            versions: [{ ...formMethods.getValues('versions.0'), term: undefined }],
            term_content: languages.reduce((langs: any, l: any) => ({ ...langs, [l]: '' }), {}),
            term_name: undefined,
          },
          { keepDirty: true, keepTouched: true },
        );
      }
    } else if (isInitialMount.current) {
      isInitialMount.current = false;

      if (offer?.id && offer?.versions[0].term) {
        setTranslations(offer?.versions[0].term);
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    formMethods.reset(formMethods.getValues());
    store.dispatch(setData({ data: { mode: modalMode } }));
  }, [modalMode]);

  useEffect(() => {
    formMethods.setValue(`versions.0.templateValues`, formMethods.getValues(`versions.0.templateValues`), {
      shouldValidate: true,
    });
    loadTags();
    if (termsConditions?.length === 0) {
      store.dispatch(termsConditionsSelection.loadTerms());
    }
  }, []);

  const isValidField = (fieldName: string) => {
    const fieldState = formMethods.getFieldState(fieldName);
    const fieldValue = formMethods.watch(fieldName);
    return fieldValue && !fieldState.error;
  };

  const isValidForPartialDraft = () =>
    Object.keys(formMethods.formState.errors)?.length === 0 &&
    isValidField(`versions.0.translationsMap.${config.primaryLanguage}.title`) &&
    isValidField(`templateType`);
  useEffect(() => {
    if (offerSelectedLanguage) {
      setSelectedLanguage(offerSelectedLanguage);
    }
  }, [offerSelectedLanguage]);

  const resetTermCondition = (selectedTemplate: string) => {
    const termsConditions =
      selectedTemplate && terms?.termsConditionsSelection?.termsConditions
        ? terms.termsConditionsSelection.termsConditions.filter((t: Term) => t.template.includes(selectedTemplate))
        : [];
    if (selectedTemplate && !isInitialMount.current) {
      if (
        termsConditions.length !== 1 &&
        !termsConditions.some((item: { id: any }) => Number(item.id) === Number(term))
      ) {
        formMethods.unregister(versionTermPath);
        formMethods.setValue(versionTermPath, undefined);
      }
    }
  };
  const isTermSelected = term ? true : false;

  const { enableDigitalDownload } = config;
  const filteredOfferTemplates = Object.values(OfferTemplates).filter(
    (template) => enableDigitalDownload || template.id !== '14',
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (event.key === 'Enter') {
      !onClose ? store.dispatch(closeModal()) : onClose();
    }
  };

  // Handle change of title, posTitle, subtitle, and description
  // Updates tempTranslationMap so edited values are maintained when opening image view modal
  // Also updates form value with setValue function & sets dirty value.
  const handleCustomChange = (item: any, value: string, language: string) => {
    setTempTranslationChange(true);
    switch (value) {
      case 'title':
        setTempTranslationsMap((prevState) => ({
          ...prevState,
          title: item.target.value,
        }));
        formMethods.setValue(`versions.0.translationsMap.${language}.title`, item.target.value, { shouldDirty: true });
        return;
      case 'posTitle':
        setTempTranslationsMap((prevState) => ({
          ...prevState,
          posTitle: item.target.value,
        }));
        formMethods.setValue(`versions.0.translationsMap.${language}.posTitle`, item.target.value, {
          shouldDirty: true,
        });
        return;
      case 'subtitle':
        setTempTranslationsMap((prevState) => ({
          ...prevState,
          subtitle: item.target.value,
        }));
        formMethods.setValue(`versions.0.translationsMap.${language}.subtitle`, item.target.value, {
          shouldDirty: true,
        });
        return;
      case 'description':
        setTempTranslationsMap((prevState) => ({
          ...prevState,
          description: item.target.value,
        }));
        formMethods.setValue(`versions.0.translationsMap.${language}.description`, item.target.value, {
          shouldDirty: true,
        });
        return;
    }
  };

  const [isFormDirty, setIsFormDirty] = useState(false);

  const checkDirtyFormValues = () => {
    if (isFormDirty === true) {
      return false;
    }
    if (tempTranslationChange) {
      return !tempTranslationChange;
    } else {
      return true;
    }
  };

  useEffect(() => {
    let foundDirtyValue = false;

    if (modalMode === FormMode.Edit || modalMode === FormMode.Duplicate) {
      if (Object.keys(formMethods.formState.dirtyFields).length > 0) {
        foundDirtyValue = true;
      }
      if (!foundDirtyValue) {
        setIsFormDirty(false);
        formMethods.reset(undefined, { keepValues: true, keepDirty: false });
      } else if (foundDirtyValue) {
        setIsFormDirty(true);
      }
    }
  }, [formMethods.formState.isDirty]);

  useEffect(() => {
    if (modals.data.isFormDirty) {
      setIsFormDirty(true);
    }
  }, [modals.data.isFormDirty]);

  const getValidationObj = (language: string, value: number, message: string) => {
    const reqValidations: any = {
      required: false,
      maxLength: { value: value, message: message },
    };
    if (selectedTemplate && selectedTemplate === '14') {
      if (
        (config.isSecondaryLanguageEnforced && language === config.secondaryLanguage) ||
        language === config.primaryLanguage ||
        formMethods.getValues(`versions.0.translationsMap.${language}.image`)
      ) {
        reqValidations.required = ValidationMessages.RequiredField;
        reqValidations.validate = (value: string) => inputValidation(value, language, ValidationMessages.RequiredField);
      }
    }
    return reqValidations;
  };
  // Value used for button enable/disable -  to check if substitution value is cleared or invalid
  const isSubstitutionProductInvalid = modals.data.isSubstitutionProductInvalid;

  // On modal load, for substitution type check if the product has been passed through
  // If it has, update state value to be false
  // If it has not, update state value to be true so button is disabled and error message shows
  const checkSubstitutionProductValue = () => {
    const templateValues = get(formMethods.getValues(), templateValuesString);
    if (persistentOffer.templateType === '4' && (modalMode === FormMode.View || modalMode === FormMode.Edit)) {
      const productTemplate = templateValues.product;
      if (productTemplate === undefined) {
        store.dispatch(
          setData({
            data: {
              isSubstitutionProductInvalid: true,
            },
          }),
        );
      }
    } else {
      store.dispatch(
        setData({
          data: {
            isSubstitutionProductInvalid: false,
          },
        }),
      );
    }
  };

  // To run function to update isSubstitutionProductInvalidon modal load
  useEffect(() => {
    checkSubstitutionProductValue();
  }, []);

  let hoverMessage;
  if (offer?.inProgress && offer?.inProgress === 'imageUpdateInProgress') {
    hoverMessage = 'Image Update in Progress';
  } else if (offer?.inProgress && offer?.inProgress === 'termUpdateInProgress') {
    hoverMessage = 'Term Update in progress';
  } else if (offer?.inProgress) {
    hoverMessage = 'Campaign Approval is in progress';
  }

  const onGoBack = () => {
    const editedValues = formMethods.getValues();
    store.dispatch(
      openModal({
        modal: Modals.OfferModal,
        props: {
          viewOnly,
          mode: modalMode,
          offer: { ...offer, ...editedValues },
          offerSource: persistentOffer?.offerSource,
          tempValuesChanged: tempTranslationChange || isFormDirty,
          fromCampaignScreen: fromCampaignScreen,
          onClose,
        },
      }),
    );
  };

  return (
    <OfferModal
      title={getModalTitle()}
      subtitle={
        modalMode !== FormMode.New && modalMode !== FormMode.Duplicate && <OfferStatusLabel offer={persistentOffer} />
      }
      isLocked={offer?.isLocked}
      {...(offer?.inProgress && { inProgress: true, message: hoverMessage })}
      onHover
      ignoreOperations={['VoucherGroups']}
      onActionClicked={onActionClicked}
      modalMode={modalMode}
      status={offer?.versions[0]?.status}
      showMoreActions={!(offer?.isLocked && !viewOnly) && !offer?.isArchive}
      fromCampaignScreen={fromCampaignScreen}
      offerSource={offerSource}
    >
      <FormProvider {...formMethods}>
        <OfferFormContainer>
          <OfferFormSection>
            <div>
              <OfferTemplateSection>
                <SelectionSection>
                  <OfferFormStyledSectionTitle>Offer Template</OfferFormStyledSectionTitle>
                  <TemplateSelectbox
                    name="templateType"
                    control={formMethods.control}
                    items={Object.values(filteredOfferTemplates)}
                    placeholder="Select"
                    label="Offer Template"
                    validation={requiredValidation}
                    disabled={modalMode === FormMode.View || modalMode === FormMode.Edit}
                    onChange={(selected: any) => {
                      setSelectedTemplate(selected.id);
                      formMethods.unregister('versions.0.templateValues');
                      formMethods.setValue('versions.0.templateValues', {});
                      resetTermCondition(selected.id);
                    }}
                    labelIsHorizontal={modalMode === FormMode.View ? false : true}
                    version="offer-form"
                  />
                </SelectionSection>
                {renderTemplate(selectedTemplate, {
                  disabled: modalMode === FormMode.View,
                  mode: modalMode,
                  offerSource: persistentOffer?.offerSource,
                  // New callback
                  // Can now use the editedValues/tempTranslationMap to maintain the edited state of the form when opening/closing product view form
                  // On cancel we can now use the tempTranslationChange boolean value so we open up the offer form with the correct isDirty state
                  onProductPreviewClick: useCallback(
                    async (buyGetProducts: any) => {
                      store.dispatch(
                        openModal({
                          modal: Modals.ProductViewModal,
                          props: {
                            mode: mode,
                            buyGetProducts: buyGetProducts,
                            offerID: persistentOffer.id,
                            offerSource: offerSource,
                            fromCampaignScreen: fromCampaignScreen,
                            onCancel: () => {
                              const editedValues = formMethods.getValues();
                              editedValues.versions[0].translationsMap.language = {
                                ...formMethods.getValues().versions[0].translationsMap.language,
                                ...tempTranslationsMap,
                              };
                              store.dispatch(
                                openModal({
                                  modal: Modals.OfferModal,
                                  props: {
                                    viewOnly,
                                    mode: modalMode,
                                    offer: { ...offer, ...editedValues },
                                    offerSource: persistentOffer?.offerSource,
                                    tempValuesChanged: tempTranslationChange || isFormDirty,
                                    fromCampaignScreen: fromCampaignScreen,
                                    onClose,
                                  },
                                }),
                              );
                            },
                          },
                        }),
                      );
                    },
                    [tempTranslationChange, isFormDirty],
                  ),
                  onProductSelection: useCallback(
                    async (
                      valueKey: string,
                      onSave: (data: any) => void,
                      isOptional = false,
                      setType = '',
                      familyGroup = '',
                    ) => {
                      const offerProducts = formMethods.getValues(`versions.0.templateValues.${valueKey}`);
                      store.dispatch(
                        openModal({
                          modal: Modals.ProductSetModal,
                          data: { offer: formMethods.getValues(), mode: modalMode },
                          props: {
                            productSet: convertToGenericSet(offerProducts),
                            onSave: (data: SetItemsSelectionFormState) => {
                              onSave({
                                products: Object.values(data.selectedItemsById),
                                productSets: Object.values(data.selectedItemSetsById),
                                excludedProductsIds: Object.keys(data.excludedItemsById).map(Number),
                              });

                              store.dispatch(
                                openModal({
                                  modal: Modals.OfferModal,
                                  props: {
                                    viewOnly,
                                    mode: modalMode,
                                    offerSource: persistentOffer?.offerSource,
                                    tempValuesChanged: tempTranslationChange || true,
                                    fromCampaignScreen: fromCampaignScreen,
                                  },
                                }),
                              );
                            },
                            onCancel: () => {
                              store.dispatch(
                                openModal({
                                  modal: Modals.OfferModal,
                                  props: {
                                    viewOnly,
                                    mode: modalMode,
                                    offerSource: persistentOffer?.offerSource,
                                    tempValuesChanged: tempTranslationChange || isFormDirty,
                                    fromCampaignScreen: fromCampaignScreen,
                                  },
                                }),
                              );
                            },

                            mode: FormMode.Select,
                            setType,
                            familyGroup,
                            isOptional,
                          },
                        }),
                      );
                    },
                    [modalMode, tempTranslationChange, isFormDirty],
                  ),
                  modalMode: modalMode,
                  setVoucherDetails: setVoucherDetails,
                  offerID: persistentOffer.id,
                })}
              </OfferTemplateSection>
              <PriceOffValidationError />
            </div>
          </OfferFormSection>
          <OfferFormSection>
            <ContentTabWrapper>
              <OfferFormStyledSectionTitle>Content</OfferFormStyledSectionTitle>
              <StyledTabStrip
                tabs={useMemo(
                  () =>
                    languages.map((language: Language) => ({
                      id: language,
                      title: LanguageRecord[language],
                    })),
                  [],
                )}
                selectedTabId={selectedLanguage}
                onClick={useCallback((tabId: Language) => setSelectedLanguage(tabId), [])}
                isOfferTab
              />
            </ContentTabWrapper>
            <>
              {languages.map((language: Language) => {
                formMethods.register(`versions.0.translationsMap.${language}.language`, { value: language });
                return (
                  <OfferFormContentContainer selected={language === selectedLanguage} key={language}>
                    <ImageRowContainer imageSelected={modalMode !== FormMode.View && getOfferImage(language)?.file}>
                      <ImageColumnWrapper>
                        <OfferFormImageContainer>
                          <OfferFormImage imageSelected={getOfferImage(language)?.file}>
                            {getOfferImage(language)?.file ? (
                              <ImageContainer>
                                <img
                                  src={getOfferImage(language)?.file}
                                  alt={getOfferImage(language)?.name ?? ''}
                                  crossOrigin="anonymous"
                                ></img>
                                {modalMode !== FormMode.New ? (
                                  <StyledImageButtonText
                                    data-automation-id="offer-image-view"
                                    onClick={async () => {
                                      const editedFormValues = tempTranslationChange || formMethods.formState.isDirty;
                                      store.dispatch(
                                        openModal({
                                          modal:
                                            persistentOffer?.offerSource === OfferSource.VCE
                                              ? Modals.ImageModal
                                              : Modals.DoeImagesModal,
                                          props: {
                                            mode: FormMode.View,
                                            image:
                                              persistentOffer?.offerSource === OfferSource.VCE
                                                ? getOfferImage(language)
                                                : await viewImageClicked(
                                                    persistentOffer?.versions[0]?.translationsMap[language]?.image?.id,
                                                  ),
                                            viewOnly: true,
                                            fromOfferScreen: true,
                                            onClose: () => {
                                              const editedValues = formMethods.getValues();
                                              editedValues.versions[0].translationsMap.language = {
                                                ...formMethods.getValues().versions[0].translationsMap.language,
                                                ...tempTranslationsMap,
                                              };
                                              store.dispatch(
                                                openModal({
                                                  modal: Modals.OfferModal,
                                                  props: {
                                                    offer: { ...offer, ...editedValues },
                                                    defaultLanguage,
                                                    mode,
                                                    onClose,
                                                    viewOnly,
                                                    fromCampaignScreen: fromCampaignScreen,
                                                    offerFormTags,
                                                    fromOfferScreen: true,
                                                    offerSelectedLanguage: language,
                                                    offerSource: persistentOffer?.offerSource,
                                                    tempValuesChanged: editedFormValues,
                                                  },
                                                }),
                                              );
                                            },
                                          },
                                        }),
                                      );
                                    }}
                                    data-title="View"
                                  >
                                    <StyledViewImage width={18} height={16} name="blueEye" />
                                  </StyledImageButtonText>
                                ) : (
                                  ''
                                )}
                              </ImageContainer>
                            ) : (
                              <ImageTextWrapper>
                                <NoImageText>No Image Defined</NoImageText>
                                <input
                                  type="hidden"
                                  {...formMethods.register(`versions.0.translationsMap.${language}.image`, {
                                    required:
                                      (config.isSecondaryLanguageEnforced && language === config.secondaryLanguage) ||
                                      language === config.primaryLanguage ||
                                      formMethods.getValues(`versions.0.translationsMap.${language}.title`),
                                  })}
                                />
                                {modalMode !== FormMode.View && (
                                  <StyledSelectImageButton
                                    data-automation-id="select-image-button"
                                    onClick={() =>
                                      store.dispatch(
                                        openModal({
                                          modal: Modals.ImageSelectionModal,
                                          props: {
                                            data: {
                                              selectedLanguage,
                                              offer: formMethods.getValues(),
                                              mode: modalMode,
                                              tempValuesChanged: tempTranslationChange || isFormDirty,
                                            },
                                          },
                                          data: {
                                            selectedLanguage,
                                            offer: formMethods.getValues(),
                                            mode: modalMode,
                                            tempValuesChanged: tempTranslationChange || isFormDirty,
                                          },
                                        }),
                                      )
                                    }
                                  >
                                    Select Image*
                                  </StyledSelectImageButton>
                                )}
                              </ImageTextWrapper>
                            )}
                          </OfferFormImage>
                        </OfferFormImageContainer>
                        {modalMode !== FormMode.View && getOfferImage(language)?.file && (
                          <SelectImageSecondaryButton
                            data-automation-id="select-image-button"
                            onClick={() =>
                              store.dispatch(
                                openModal({
                                  modal: Modals.ImageSelectionModal,
                                  props: {
                                    data: {
                                      selectedLanguage,
                                      offer: formMethods.getValues(),
                                      mode: modalMode,
                                      tempValuesChanged: tempTranslationChange,
                                    },
                                  },
                                  data: {
                                    selectedLanguage,
                                    offer: formMethods.getValues(),
                                    mode: modalMode,
                                    tempValuesChanged: tempTranslationChange,
                                  },
                                }),
                              )
                            }
                          >
                            Select Image*
                          </SelectImageSecondaryButton>
                        )}
                      </ImageColumnWrapper>
                      <ContentContainer>
                        <OfferFormTextField
                          register={formMethods.register}
                          errors={formMethods.formState.errors}
                          name={`versions.0.translationsMap.${language}.title`}
                          value={formMethods.getValues(`versions.0.translationsMap.${language}.title`)}
                          label="Title"
                          placeholder="Enter"
                          onChange={(item: any) => {
                            handleCustomChange(item, 'title', language);
                            setTempTranslationsMap((prevState) => ({
                              ...prevState,
                              title: item.target.value,
                            }));
                            formMethods.setValue(`versions.0.translationsMap.${language}.title`, item.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          disabled={modalMode === FormMode.View}
                          validation={{
                            required:
                              (config.isSecondaryLanguageEnforced && language === config.secondaryLanguage) ||
                              language === config.primaryLanguage ||
                              formMethods.getValues(`versions.0.translationsMap.${language}.image`)
                                ? ValidationMessages.RequiredField
                                : false,
                            maxLength: { value: 200, message: 'Up to 200 characters' },
                            validate: (value: string) =>
                              inputValidation(value, language, ValidationMessages.RequiredField),
                          }}
                          labelIsHorizontal
                        />
                        {
                          // TODO: OMS-522 - Hide "POS Title"
                          <OfferFormTextField
                            register={formMethods.register}
                            errors={formMethods.formState.errors}
                            name={`versions.0.translationsMap.${language}.posTitle`}
                            label="POS Title"
                            placeholder="Enter"
                            onChange={(item: any) => {
                              handleCustomChange(item, 'posTitle', language);
                              setTempTranslationsMap((prevState) => ({ ...prevState, posTitle: item.target.value }));
                              formMethods.setValue(
                                `versions.0.translationsMap.${language}.posTitle`,
                                item.target.value,
                                { shouldValidate: true },
                              );
                            }}
                            labelIsHorizontal
                            disabled={modalMode === FormMode.View}
                            validation={{
                              maxLength: { value: 100, message: 'Up to 100 characters' },
                            }}
                          />
                        }
                        <OfferFormTextField
                          register={formMethods.register}
                          errors={formMethods.formState.errors}
                          name={`versions.0.translationsMap.${language}.subtitle`}
                          label="Subtitle"
                          placeholder="Enter"
                          labelIsHorizontal
                          onChange={(item: any) => {
                            handleCustomChange(item, 'subtitle', language);
                            setTempTranslationsMap((prevState) => ({
                              ...prevState,
                              subtitle: item.target.value,
                            }));
                            formMethods.setValue(`versions.0.translationsMap.${language}.subtitle`, item.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          disabled={modalMode === FormMode.View}
                          validation={{
                            ...getValidationObj(language, 200, 'Up to 200 characters'),
                          }}
                        />
                      </ContentContainer>
                    </ImageRowContainer>
                    <OfferFormTextArea
                      register={formMethods.register}
                      errors={formMethods.formState.errors}
                      name={`versions.0.translationsMap.${language}.description`}
                      label="Description"
                      scrollOnDisabled={true}
                      labelIsHorizontal
                      onChange={(item: any) => {
                        handleCustomChange(item, 'description', language);
                        setTempTranslationsMap((prevState) => ({
                          ...prevState,
                          description: item.target.value,
                        }));
                        formMethods.setValue(`versions.0.translationsMap.${language}.description`, item.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      disabled={modalMode === FormMode.View}
                      placeholder="Enter"
                      validation={{
                        ...getValidationObj(language, 10000, 'Up to 10,000 characters'),
                      }}
                      rows={1}
                    />
                    {!offer?.versions[0].term && modalMode === FormMode.View ? (
                      <OfferFormTextArea
                        name="versions.0.term"
                        label="Terms and Conditions"
                        placeholder="No T&C Defined"
                        validation={requiredValidation}
                        disabled
                        labelIsHorizontal
                        value={getTermName()}
                      />
                    ) : (
                      <TermsConditionsWrapper>
                        {selectedLanguage === language && (
                          <TermsConditionsSelectbox
                            key={`${JSON.stringify(termsConditions)}_${selectedTemplate}`}
                            label="Terms and Conditions"
                            validation={requiredValidation}
                            labelIsHorizontal
                            items={termsConditions}
                            placeholder={selectedTemplate ? 'Select' : 'Offer template must be selected to proceed'}
                            name="versions.0.term"
                            control={formMethods.control}
                            disabled={
                              !termsConditions ||
                              modalMode === FormMode.View ||
                              selectedTemplate === undefined ||
                              termsConditions?.length === 0
                            }
                            onChange={(item: any) => setTranslations(item)}
                            errors={
                              termsConditions && termsConditions?.length === 0 && selectedTemplate
                                ? modalOfferSource === OfferSource.VCE
                                  ? 'Default T&C need to be defined from Settings → Terms & Conditions'
                                  : modalOfferSource === OfferSource.DOE
                                  ? 'Default T&C need to be defined from Configurations'
                                  : formMethods.formState.errors
                                : formMethods.formState.errors
                            }
                            defaultValue={term}
                            initialSelectedItems={term ? [typeof term === 'object' ? term.id : term] : []}
                            version="offer-form"
                            containerGap={8}
                          />
                        )}
                        <StyledTermsAndConditionsTextArea>
                          {shouldDisplayInfoIcon && (
                            <StyledInfoTooltip content="Changes made to the T&C from within the offer will not be reflected in the T&C repository. In addition, no future template changes will be applied to this T&C." />
                          )}
                          <TermsTextArea
                            register={formMethods.register}
                            errors={formMethods.formState.errors}
                            name={`term_content.${language}`}
                            scrollOnDisabled={true}
                            rows={3}
                            disabled={
                              !termsConditions ||
                              !termsConditions?.length ||
                              modalMode === FormMode.View ||
                              (persistentOffer?.offerSource === OfferSource.DOE && modalMode === FormMode.Edit) ||
                              formMethods.watch(versionTermPath) === undefined
                            }
                            validation={{
                              maxLength: { value: 100e3, message: 'Up to 100k characters' },
                              validate: (value: string) =>
                                inputValidation(value, language, ValidationMessages.RequiredField),
                            }}
                          />
                        </StyledTermsAndConditionsTextArea>
                      </TermsConditionsWrapper>
                    )}
                  </OfferFormContentContainer>
                );
              })}
            </>
            <OfferFormAdditionalDetails>
              <AdditionalInfoRow>
                <TagsSelectbox
                  key={`${Boolean(tags.length)}`}
                  multiple
                  label="Tags"
                  placeholder="Select"
                  control={formMethods.control}
                  errors={formMethods.formState.errors}
                  name="tags"
                  items={tags}
                  disabled={modalMode === FormMode.View}
                  reset
                  onCreateOption={(tagName: string) => onTagCreation(tagName)}
                  withSearch
                  labelIsHorizontal
                  withAmount
                  limit={10}
                  maxItems={1}
                  selectWidth={257}
                  selectHeight={36}
                  version="offer-form"
                  containerGap={8}
                />
                <FeatureGuard features={[{ feature: Feature.SegmentId }]}>
                  <GroupIdTextField
                    register={formMethods.register}
                    errors={formMethods.formState.errors}
                    name="segmentId"
                    label="Group ID"
                    placeholder="Enter"
                    labelIsHorizontal
                    disabled={disabledGroupIdInput}
                  />
                </FeatureGuard>
              </AdditionalInfoRow>
              {selectedTemplate !== '14' && (
                <OfferCheckboxGroup
                  label="Point of Distribution"
                  control={formMethods.control}
                  name="versions.0.pointOfDistribution"
                  disabled={modalMode === FormMode.View}
                  labelIsHorizontal
                  checkboxes={convertEnumToObjectArray(PointOfDistributionRecord, 'id', 'label') as any[]}
                  defaultValue={[PointOfDistributionRecord.delivery, PointOfDistributionRecord.pickup]}
                  errors={formMethods.formState.errors}
                  validation={{
                    required: 'Must select at least 1 Point of Distribution',
                  }}
                />
              )}
            </OfferFormAdditionalDetails>
          </OfferFormSection>
        </OfferFormContainer>
      </FormProvider>
      <OfferFormFooter>
        <CancelButton
          noStopPropagation
          data-for="cancel-tooltip"
          onKeyDown={handleKeyDown}
          {...((formMethods.formState.isDirty || tempTranslationChange || modalMode === FormMode.Duplicate) &&
          modalMode !== FormMode.View
            ? { 'data-tip': true, onClick: () => null }
            : { onClick: () => (!onClose ? store.dispatch(closeModal()) : onClose()) })}
        >
          Close
        </CancelButton>
        <SaveButtons>
          {modalMode === FormMode.View && ![ApprovalStatus.Archived, ApprovalStatus.Rejected].includes(offerStatus) && (
            <GMAPreviewButton entity={persistentOffer.versions[0]} entityStatus={offerStatus}>
              <BaseTextButton
                onClick={() =>
                  store.dispatch(
                    openModal({
                      modal: Modals.GmaViewModal,
                      props: {
                        offer,
                        onClose,
                        onGoBack,
                      },
                    }),
                  )
                }
              >
                Preview
              </BaseTextButton>
            </GMAPreviewButton>
          )}
          {modalMode !== FormMode.View ? (
            <>
              {modalMode === FormMode.Edit && persistentOffer?.offerSource === OfferSource.DOE ? (
                <ButtonContained
                  onClick={() => {
                    offer = { ...offer, ...formMethods.getValues() };
                    store.dispatch(
                      openModal({
                        modal: Modals.GmaViewModal,
                        props: {
                          offer,
                          onClose,
                          onSubmit: formMethods.handleSubmit(onSubmit(false)),
                          onGoBack,
                          isDisabled:
                            !formMethods.formState.isValid ||
                            isSubmitting ||
                            !hasProductsOrVoucherGroup ||
                            termsConditions?.length === 0 ||
                            checkDirtyFormValues() ||
                            isSubstitutionProductInvalid,
                        },
                      }),
                    );
                  }}
                  disabled={
                    !formMethods.formState.isValid ||
                    isSubmitting ||
                    !hasProductsOrVoucherGroup ||
                    termsConditions?.length === 0 ||
                    checkDirtyFormValues() ||
                    isSubstitutionProductInvalid
                  }
                >
                  Continue
                </ButtonContained>
              ) : (
                <>
                  <StyledWhiteButton
                    noStopPropagation
                    onClick={
                      draftCampaignOfferEnhancement
                        ? () => onSubmit()(formMethods.getValues())
                        : formMethods.handleSubmit(onSubmit())
                    }
                    disabled={
                      (draftCampaignOfferEnhancement ? !isValidForPartialDraft() : !formMethods.formState.isValid) ||
                      isSubmitting ||
                      checkDirtyFormValues()
                    }
                  >
                    Save as Draft
                  </StyledWhiteButton>
                  <StyledGoldButton
                    noStopPropagation
                    onClick={() => {
                      offer = { ...offer, ...formMethods.getValues() };
                      store.dispatch(
                        openModal({
                          modal: Modals.GmaViewModal,
                          props: {
                            offer,
                            onGoBack,
                            onClose,
                            onSubmit: formMethods.handleSubmit(onSubmit(false)),
                            isDisabled:
                              !formMethods.formState.isValid ||
                              isSubmitting ||
                              !hasProductsOrVoucherGroup ||
                              hasExpireVoucherGroupError ||
                              checkDirtyFormValues() ||
                              isSubstitutionProductInvalid,
                          },
                        }),
                      );
                    }}
                    disabled={
                      !formMethods.formState.isValid ||
                      isSubmitting ||
                      !hasProductsOrVoucherGroup ||
                      hasExpireVoucherGroupError ||
                      checkDirtyFormValues() ||
                      isSubstitutionProductInvalid
                    }
                  >
                    Save and Continue
                  </StyledGoldButton>
                </>
              )}
            </>
          ) : (
            <>
              {!offer?.isLocked && !viewOnly && (
                <>
                  <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                    {offer?.isArchive ? (
                      <StyledGoldButton
                        noStopPropagation
                        onClick={async () => {
                          await archiveOffer(offer, false);
                        }}
                      >
                        Unarchive
                      </StyledGoldButton>
                    ) : (
                      <>
                        {persistentOffer?.offerSource === OfferSource.DOE ? (
                          <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
                            <ButtonContained onClick={() => setModalMode(FormMode.Edit)}>Edit</ButtonContained>
                          </RoleGuard>
                        ) : (
                          offerStatus === ApprovalStatus.Draft && (
                            <StyledWhiteButton noStopPropagation onClick={() => setModalMode(FormMode.Edit)}>
                              Edit
                            </StyledWhiteButton>
                          )
                        )}
                        {persistentOffer?.offerSource === OfferSource.VCE &&
                          (offerStatus === ApprovalStatus.Draft || offerStatus === ApprovalStatus.Rejected) && (
                            <StyledGoldButton
                              onClick={() =>
                                store.dispatch(
                                  openModal({
                                    modal: Modals.GmaViewModal,
                                    props: {
                                      offer,
                                      onClose,
                                      onSubmit: formMethods.handleSubmit(onSubmit(false)),
                                      onGoBack,
                                      isDisabled:
                                        !formMethods.formState.isValid ||
                                        isSubmitting ||
                                        !hasProductsOrVoucherGroup ||
                                        !isTermSelected ||
                                        hasExpireVoucherGroupError ||
                                        isSubstitutionProductInvalid,
                                    },
                                  }),
                                )
                              }
                              noStopPropagation
                              disabled={
                                !formMethods.formState.isValid ||
                                isSubmitting ||
                                !hasProductsOrVoucherGroup ||
                                !isTermSelected ||
                                hasExpireVoucherGroupError ||
                                isSubstitutionProductInvalid
                              }
                            >
                              Continue
                            </StyledGoldButton>
                          )}
                      </>
                    )}
                    {persistentOffer?.offerSource === OfferSource.VCE &&
                      (offerStatus === ApprovalStatus.PendingApproval || offerStatus === ApprovalStatus.Approved) && (
                        <EntityApproveButton entity={persistentOffer.versions[0]} entityType={EntityType.Offer}>
                          <StyledWhiteButton noStopPropagation onClick={() => null} data-tip data-for="reject-tooltip">
                            Reject
                          </StyledWhiteButton>
                        </EntityApproveButton>
                      )}
                    {persistentOffer?.offerSource === OfferSource.VCE &&
                      offerStatus === ApprovalStatus.PendingApproval && (
                        <EntityApproveButton entity={persistentOffer.versions[0]} entityType={EntityType.Offer}>
                          <StyledGoldButton
                            noStopPropagation
                            onClick={() =>
                              store.dispatch(
                                openModal({
                                  modal: Modals.GmaViewModal,
                                  props: {
                                    offer,
                                    onClose,
                                    onSubmit: onApproveClick,
                                    handleRejectClick,
                                    onGoBack,
                                    isDisabled: hasExpireVoucherGroupError || isSubstitutionProductInvalid,
                                  },
                                }),
                              )
                            }
                          >
                            Continue to Approve
                          </StyledGoldButton>
                        </EntityApproveButton>
                      )}
                  </RoleGuard>
                </>
              )}
            </>
          )}
        </SaveButtons>
        <OfferToolTip
          id="cancel-tooltip"
          content="Are you sure you want to cancel?"
          onDisapproveClick={() => {
            hideTooltip('#cancel-tooltip');
          }}
          onApproveClick={() => store.dispatch(closeModal())}
        />
        <OfferToolTip
          id="reject-tooltip"
          content="Are you sure you want to reject?"
          onDisapproveClick={() => {
            hideTooltip('#reject-tooltip');
            ReactTooltip.hide();
            setIsRejectButtonClicked(false);
          }}
          eventOff={null}
          approveMsg="Reject"
          isWithResponse
          disapproveMsg="Cancel"
          responsePlaceholder="Enter reject reason"
          onApproveWithResponseClick={(rejectionComment) => {
            handleRejectClick(offer, rejectionComment);
          }}
        />
      </OfferFormFooter>
    </OfferModal>
  );
};

export default OfferForm;