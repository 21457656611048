import styled from '@emotion/styled';
import typography from 'styles/typography';
import NewRadioItem from './radioItem/NewRadioItem';
import DoeError from '../error/DoeError';

export const RadioGroupContainer = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) => (direction === 'row' ? 'center' : 'flex-start')};
`;

export const StyledRadioItem = styled(NewRadioItem)<{ direction: 'row' | 'column' }>`
  margin-right: ${({ direction }) => direction === 'row' && `${typography.unit * 5}px`};
  margin-bottom: ${({ direction }) => direction === 'column' && `${typography.unit}px`};
    
    > span {
        color: ${({ disabled, theme }) => disabled ? theme.colors.text.disabled : theme.colors.text.primary};
    }
`;

export const StyledRadioGroupError = styled(DoeError)`
  top: ${typography.unit * 7}px;
  margin-left: ${typography.unit / 2}px;
  white-space: nowrap;
`;
