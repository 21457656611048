import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledLabelProps, StyledToggleSwitchProps, ToggleSwitchContainerProps } from './ToggleSwitch.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

const sizes = {
  small: {
    ball: {
      width: '9px',
      height: '9px',
      top: '2px',
      left: '2px',
      transition: 'translateX(11px)',
    },
    slide: {
      width: '24px',
      height: '13px',
    },
  },
  medium: {
    ball: {
      width: '11px',
      height: '11px',
      top: '2px',
      left: '2px',
      transition: 'translateX(13px)',
    },
    slide: {
      width: '28px',
      height: '15px',
    },
  },
  large: {
    ball: {
      width: '14px',
      height: '14px',
      top: '3px',
      left: '3px',
      transition: 'translateX(14px)',
    },
    slide: {
      width: '34px',
      height: '20px',
    },
  },
  xlarge: {
    ball: {
      width: '13px',
      height: '13px',
      top: '2px',
      left: '2px',
      transition: 'translateX(13px)',
    },
    slide: {
      width: '30px',
      height: '17px',
    },
  },
  
};

export const ToggleSwitchContainer = styled.div<ToggleSwitchContainerProps>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  line-height: 21px;
`;

export const Ball = styled.span<StyledToggleSwitchProps>`
  position: absolute;
  background-color: ${({ checked, disabled, theme }) =>
    checked ? newGlobalTheme.colors.ball.offBg : disabled ? newGlobalTheme.colors.ball.offBg : newGlobalTheme.colors.ball.offBg};
  width: ${({ size }) => sizes[size].ball.width};
  height: ${({ size }) => sizes[size].ball.height};
  top: ${({ size }) => sizes[size].ball.top};
  left: ${({ size }) => sizes[size].ball.left};
  border-radius: 50%;
  transition: all ${typography.transition}s;
  transform: ${({ checked, size }) => (checked ? sizes[size].ball.transition : 'none')};
`;

const getDisabledStyle = (disabled: boolean) => {
  return disabled
  ? newGlobalTheme.colors.global.disabledGold
  : newGlobalTheme.colors.global.brandMain
}

export const Slide = styled.div<StyledToggleSwitchProps>`
  width: ${({ size }) => sizes[size].slide.width};
  height: ${({ size }) => sizes[size].slide.height};
  border-radius: 10px;
  position: relative;

  background-color: ${({ checked, disabled }) =>
    checked
      ? getDisabledStyle(disabled)
      : newGlobalTheme.colors.global.inputDisabledSlide};
  transition: all ${typography.transition}s;
  cursor: pointer;
`;

export const StyledLabel = styled.label<StyledLabelProps>`
font-size: 12px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
  margin-right: ${({ labelPosition }) => (labelPosition === 'before' ? `${typography.unit * 2}px` : '0')};
  margin-left: ${({ labelPosition }) => (labelPosition === 'after' ? '10px' : '0')};
  color: ${({ disabled }) => disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary};
  cursor: pointer;
`;
