import React from 'react';
import { TimePicker as MuiTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { ThemeProvider } from '@mui/material/styles';
import { TimePickerProps, materialTheme } from './TimePicker.consts';
import { StyledTimeContainer, StyledLabel, StyledError } from './TimePicker.style';

export const TimePicker = ({
  validation,
  errors,
  name,
  withAmPm = true,
  label,
  labelIsHorizontal,
  disabled,
  defaultValue,
  className,
  control,
  changeHandler,
}: TimePickerProps) => {
  return (
    <StyledTimeContainer
      className={className}
      labelIsHorizontal={labelIsHorizontal}
      disabled={disabled}
      data-automation-id="time-picker"
      withAmPm={withAmPm}
    >
      {label && (
        <StyledLabel labelIsHorizontal={labelIsHorizontal} disabled={disabled}>{`${label}${
          validation?.required ? '*' : ''
        }`}</StyledLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={defaultValue}
        render={({ field }: any) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={materialTheme}>
              <MuiTimePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                    tabIndex: -1,
                    onBlur: field.onBlur,
                  },
                  field: {
                    inputProps: {
                      'aria-label': `time-picker-${name}`,
                    },
                  },
                }}
                format={withAmPm ? `hh:mm a` : `HH:mm`}
                value={field.value}
                onChange={(time) => {
                  field.onChange(time);
                  // eslint-disable-next-line no-unused-expressions
                  changeHandler && changeHandler(time);
                }}
                disabled={disabled}
                ampm={withAmPm}
              />
            </ThemeProvider>
          </LocalizationProvider>
        )}
      />
      <StyledError name={name} errors={errors} />
    </StyledTimeContainer>
  );
};
