import { convertGenericItemArrayToRecord } from 'utils/mapping';

export enum AuditFilters {
  Type = 'type',
  Action = 'action',
  SearchQuery = 'searchQuery',
}

export enum AuditType {
  Campaign = 'Campaign',
  Offer = 'Offer',
  Image = 'Image',
  Term = 'Term',
  Tag = 'Tag',
  CustomProductSet = 'CustomProductSet',
  CustomLocationSet = 'CustomLocationSet',
  User = 'User',
  Period = 'Period',
  Audit = 'Audit',
  LocationChanges = 'LocationChanges',
  ProductChanges = 'ProductChanges',
  DOEOffer = 'DOE Offer',
  DOECampaign = 'DOE Campaign',
  DOEImage = 'DOE Image',
}

export enum AuditAction {
  Added = 'added',
  Updated = 'updated',
  PendingApproval = 'pendingApproval',
  Archive = 'archive',
  Unarchive = 'unarchive',
  Approve = 'approve',
  Deploy = 'deploy',
  Reject = 'reject',
  Revoke = 'revoke',
  AssociationStopped = 'stopAssociation',
  Removed = 'removed',
  Unlocked = 'Unlocked',
  AutoDelete = 'autoDelete',
  AutoArchive = 'autoArchive',
  ReDeploy = 'reDeploy',
  BulkStop = 'bulkStop',
  BulkRevoke = 'bulkRevoke',
  BulkEditOffers = 'bulkEditOffers',
  BulkEditCampaigns = 'bulkEditCampaigns',
}

const pendingApprovalString = 'Pending Approval';

export const auditActions: { id: AuditAction; name: string }[] = [
  { id: AuditAction.Added, name: 'Add New' },
  { id: AuditAction.Updated, name: 'Updated' },
  { id: AuditAction.PendingApproval, name: pendingApprovalString },
  { id: AuditAction.Archive, name: 'Archive' },
  { id: AuditAction.Unarchive, name: 'Unarchive' },
  { id: AuditAction.Approve, name: 'Approve' },
  { id: AuditAction.Deploy, name: 'Deployed' },
  { id: AuditAction.Reject, name: 'Reject' },
  { id: AuditAction.Revoke, name: 'Revoke' },
  { id: AuditAction.AssociationStopped, name: 'Stop Association' },
  { id: AuditAction.Removed, name: 'Removed' },
  { id: AuditAction.Unlocked, name: 'Unlocked' },
  { id: AuditAction.AutoDelete, name: 'Auto Delete' },
  { id: AuditAction.AutoArchive, name: 'Auto Archive' },
  { id: AuditAction.ReDeploy, name: 'Re-Deploy' },
  { id: AuditAction.BulkStop, name: 'Bulk Stop' },
  { id: AuditAction.BulkRevoke, name: 'Bulk Revoke' },
  { id: AuditAction.BulkEditOffers, name: 'Bulk Edit Offers' },
  { id: AuditAction.BulkEditCampaigns, name: 'Bulk Edit Campaigns' },
];

export const auditActionsUpdates = [{ id: pendingApprovalString, name: pendingApprovalString }];

export const auditTypes: { id: AuditType; name: string }[] = [
  { id: AuditType.Campaign, name: 'Campaign' },
  { id: AuditType.Offer, name: 'Offer' },
  { id: AuditType.Image, name: 'Image' },
  { id: AuditType.DOEImage, name: 'DOE Image' },
  { id: AuditType.Term, name: 'T&C' },
  { id: AuditType.Tag, name: 'Tag' },
  { id: AuditType.CustomProductSet, name: 'Custom Product Set' },
  { id: AuditType.CustomLocationSet, name: 'Custom Location Set' },
  { id: AuditType.User, name: 'User' },
  { id: AuditType.Period, name: 'Schedule Period' },
  { id: AuditType.Audit, name: 'Audit' },
  { id: AuditType.LocationChanges, name: 'Location Changes' },
  { id: AuditType.ProductChanges, name: 'Product Changes' },
  { id: AuditType.DOECampaign, name: 'DOE Campaign' },
  { id: AuditType.DOEOffer, name: 'DOE Offer' },
];

export const auditTypesByAction: Record<AuditAction, AuditType[]> = {
  [AuditAction.Added]: [
    AuditType.Image,
    AuditType.DOEImage,
    AuditType.Offer,
    AuditType.Campaign,
    AuditType.Term,
    AuditType.Tag,
    AuditType.CustomLocationSet,
    AuditType.CustomProductSet,
    AuditType.User,
    AuditType.Period,
  ],
  [AuditAction.Updated]: [
    AuditType.Image,
    AuditType.DOEImage,
    AuditType.Offer,
    AuditType.Campaign,
    AuditType.Term,
    AuditType.Tag,
    AuditType.CustomLocationSet,
    AuditType.CustomProductSet,
    AuditType.User,
    AuditType.Period,
    AuditType.DOECampaign,
    AuditType.DOEOffer,
  ],
  [AuditAction.PendingApproval]: [AuditType.Offer, AuditType.Campaign],
  [AuditAction.Archive]: [
    AuditType.Image,
    AuditType.Offer,
    AuditType.Campaign,
    AuditType.Term,
    AuditType.DOECampaign,
    AuditType.DOEImage,
  ],
  [AuditAction.Unarchive]: [
    AuditType.Image,
    AuditType.Offer,
    AuditType.Campaign,
    AuditType.Term,
    AuditType.DOECampaign,
    AuditType.DOEImage,
  ],
  [AuditAction.Approve]: [AuditType.Offer, AuditType.Campaign],
  [AuditAction.Deploy]: [AuditType.Campaign],
  [AuditAction.Reject]: [AuditType.Campaign, AuditType.Offer],
  [AuditAction.Revoke]: [AuditType.Campaign],
  [AuditAction.AssociationStopped]: [AuditType.Campaign],
  [AuditAction.Removed]: [AuditType.Tag, AuditType.CustomLocationSet, AuditType.CustomProductSet, AuditType.User],
  [AuditAction.Unlocked]: [AuditType.User],
  [AuditAction.AutoDelete]: [AuditType.LocationChanges, AuditType.ProductChanges, AuditType.Audit],
  [AuditAction.AutoArchive]: [AuditType.Offer, AuditType.Campaign, AuditType.DOEOffer],
  [AuditAction.ReDeploy]: [AuditType.Campaign],
  [AuditAction.BulkStop]: [AuditType.Campaign],
  [AuditAction.BulkRevoke]: [AuditType.Campaign],
  [AuditAction.BulkEditOffers]: [AuditType.DOEOffer],
  [AuditAction.BulkEditCampaigns]: [AuditType.DOECampaign],
};

export const auditActionsByType: Record<AuditType, AuditAction[]> = {
  [AuditType.Campaign]: [
    AuditAction.Added,
    AuditAction.Updated,
    AuditAction.PendingApproval,
    AuditAction.Approve,
    AuditAction.Revoke,
    AuditAction.AssociationStopped,
    AuditAction.Archive,
    AuditAction.Unarchive,
    AuditAction.Deploy,
    AuditAction.AutoArchive,
    AuditAction.Reject,
    AuditAction.ReDeploy,
    AuditAction.BulkStop,
    AuditAction.BulkRevoke
  ],
  [AuditType.Offer]: [
    AuditAction.Added,
    AuditAction.Updated,
    AuditAction.PendingApproval,
    AuditAction.Approve,
    AuditAction.Archive,
    AuditAction.Unarchive,
    AuditAction.AutoArchive,
    AuditAction.Reject,
  ],
  [AuditType.Image]: [AuditAction.Added, AuditAction.Updated, AuditAction.Archive, AuditAction.Unarchive],
  [AuditType.DOEImage]: [AuditAction.Added, AuditAction.Updated, AuditAction.Archive, AuditAction.Unarchive],
  [AuditType.Term]: [AuditAction.Added, AuditAction.Updated, AuditAction.Archive, AuditAction.Unarchive],
  [AuditType.DOECampaign]: [AuditAction.Updated, AuditAction.Archive, AuditAction.Unarchive, AuditAction.BulkEditCampaigns],
  [AuditType.Tag]: [AuditAction.Added, AuditAction.Updated, AuditAction.Removed],
  [AuditType.CustomProductSet]: [AuditAction.Added, AuditAction.Updated, AuditAction.Removed],
  [AuditType.CustomLocationSet]: [AuditAction.Added, AuditAction.Updated, AuditAction.Removed],
  [AuditType.User]: [AuditAction.Added, AuditAction.Updated, AuditAction.Removed, AuditAction.Unlocked],
  [AuditType.Period]: [AuditAction.Added, AuditAction.Updated],
  [AuditType.Audit]: [AuditAction.AutoDelete],
  [AuditType.LocationChanges]: [AuditAction.AutoDelete],
  [AuditType.ProductChanges]: [AuditAction.AutoDelete],
  [AuditType.DOEOffer]: [AuditAction.AutoArchive, AuditAction.Updated, AuditAction.BulkEditOffers],
};

export const auditActionRecord = convertGenericItemArrayToRecord(auditActions);

export const auditActionRecordUpdates = convertGenericItemArrayToRecord(auditActionsUpdates);

export const auditTypesRecord = convertGenericItemArrayToRecord(auditTypes);

export const auditTypesArray = auditTypes.map((type) => type.id);
