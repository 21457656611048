import React, { useEffect, useState } from 'react';
import { store } from 'app/store';
import { Modals, openModal, setData } from 'app/slices/modals';
import { BaseOffer } from 'utils/types/offers';
import { useFormContext, useWatch } from 'react-hook-form';
import { offersSelection } from 'app/genericSlices/offers';
import { OfferFilters, OfferSource } from 'pages/offers/offerManagement/Offers.const';
import Error from 'components/shared/error/Error';
import {
  SectionContainer,
  SelectOfferWrapper,
  StyledButtonText,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSection/OfferSection.style';
import { OfferSectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSection/OfferSection.consts';
import { FormMode } from 'utils/types';
import { CampaignType } from 'utils/types/campaigns';
import ViewOfferItem from '../offerVersionItem/ViewOfferItem';

const OfferSection = ({ mode, isDisabled, className, offerSource = OfferSource.VCE, isExternalVoucher = false }: OfferSectionProps) => {
  const { getValues, control } = useFormContext();
  const campaign = getValues();
  const [isLocalCampaign, zoneSelection, isGeneratedName, isNational] = useWatch({
    control,
    name: ['isLocalCampaign', 'localSchedule.zone', 'isGeneratedName', 'isNational'],
  });
  const [shouldShowZoneError, setShouldShowZoneError] = useState(false);

  useEffect(() => {
    store.dispatch(
      offersSelection.actions.setFilter({
        filter: [OfferFilters.Zone],
        value: zoneSelection ? [zoneSelection] : null,
      }),
    );
  }, [zoneSelection]);

  useEffect(() => {
    if (!isLocalCampaign) {
      setShouldShowZoneError(false);
    }
  }, [isLocalCampaign]);

  useEffect(() => {
    if (zoneSelection || isNational) {
      setShouldShowZoneError(false);
    }
  }, [zoneSelection, isNational]);

  const onSelectOfferClick = () => {
    if (zoneSelection) {
      store.dispatch(
        offersSelection.actions.setFilter({
          filter: [OfferFilters.Zone],
          value: zoneSelection ? [zoneSelection] : null,
        }),
      );
    }
    if (isLocalCampaign && !zoneSelection && !isNational) {
      setShouldShowZoneError(true);
    } else {
      setShouldShowZoneError(false);
      store.dispatch(
        openModal({
          modal: Modals.OfferSelectionModal,
          props: {
            selectedOffer: campaign?.offerVersion,
            externalVoucherCampaign: isExternalVoucher,
            onSave: (offerVersion: BaseOffer | any) => {
              // update modal state isFormDirty
              store.dispatch(
                setData({
                  data: {
                    isFormDirty: true,
                  },
                }),
              );
              store.dispatch(
                openModal({
                  modal: Modals.CampaignModal,
                  props: {
                    mode,
                    campaign: {
                      ...getValues(),
                      offerVersion,
                      isLocalCampaign,
                      isGeneratedName,
                      ...(offerVersion?.templateValues?.templateType === '14' ? { type: CampaignType.LoyaltyExternalVoucher } : {}),
                    },
                  },
                  data: {
                    clickSelectOffer: false,
                  },
                }),
              );
            },
            onCancel: () =>
              store.dispatch(
                openModal({
                  modal: Modals.CampaignModal,
                  props: { mode, campaign: { ...getValues(), isLocalCampaign, isGeneratedName } },
                  data: {
                    clickSelectOffer: false,
                  },
                }),
              ),
          },
          data: {
            campaign: { ...getValues(), isLocalCampaign, isGeneratedName },
            mode,
            clickSelectOffer: true,
          },
        }),
      );
    }
  };

  return (
    <SectionContainer className={className}>
      {(!isDisabled || (mode === FormMode.Edit && offerSource === OfferSource.DOE)) && (
        <SelectOfferWrapper
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSelectOfferClick(); // Trigger click when Enter is pressed
            }
          }}
        >
          <StyledButtonText disabled={mode === FormMode.Edit && offerSource === OfferSource.DOE} onClick={onSelectOfferClick}>
            
            Select Offer
          
          </StyledButtonText>
          {shouldShowZoneError && <Error errors="Zone selection is required" tabIndex={-1} />}
        </SelectOfferWrapper>
      )}

      <ViewOfferItem offer={campaign?.offerVersion} campaign={getValues()} />

        
    </SectionContainer>
  );
};

export default OfferSection;
