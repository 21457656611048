import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import typography from 'styles/typography';
import { Icon } from '../../icon';
import { StyledNotificationWithTheme } from '../notifications';

export const StyledDoeMessageContainer = styled.div<StyledWithThemeProps>`
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: ${typography.fontSize}px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.primary};
  gap: 16px;
  align-items: center;
`;

export const StyledDoeIcon = styled(Icon)<StyledNotificationWithTheme>`
  width: 24px;
  height: 24px;
  fill: ${({ type, theme }) => theme.colors.notifications.primary[type]};
`;

export const StyledContent = styled.p`
  flex: 1;
`;
