import React from 'react';
import { StyledContent, StyledDoeIcon, StyledDoeMessageContainer } from './DoeMessage.style';
import { IconNameByType, NotificationsProps } from '../notifications';

const DoeMessage = ({ type, children, className }: NotificationsProps) => {
  return (
    <StyledDoeMessageContainer className={className} role="message" data-automation-id="message">
      <StyledDoeIcon type={type} name={IconNameByType[type]} />
      <StyledContent>{children}</StyledContent>
    </StyledDoeMessageContainer>
  );
};

export default DoeMessage;
