import { GenericItem, GenericSet } from '../types';

export interface Location extends GenericItem {
  code: number;
}

export interface LocationSet extends GenericSet<Location> {
  custom: boolean;
  totalSets: number;
  isReadOnly?: boolean;
}

export enum LocationSetType {
  Custom = 'custom',
  Hierarchy = 'hierarchy',
}

export interface RestaurantGroup {
  id: number;
  type: LocationSetType;
}

export interface RestaurantEligibility {
  restaurants: number[];
  restaurantGroups: RestaurantGroup[];
  excludeRestaurants: number[];
  excludeRestaurantGroups: RestaurantGroup[];
  isNationwide?: boolean;
}
