import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import { CheckboxContainer } from 'components/shared/checkbox/Checkbox.style';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import NewModal from 'components/shared/modal/NewModal';
import UserModal from 'components/shared/modal/UserModal';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { StyledWithThemeProps } from 'utils/types';
import { StyledFormSection } from 'pages/shared/shared.style';
import DoeTextField from 'components/shared/textField/DoeTextField';
import { NewButtonContained } from 'components/shared/button';
import Checkbox from 'components/shared/checkbox/Checkbox';

export const UserManagementModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  flex: 1;
  padding: ${typography.unit * 8}px;
  overflow-y: auto;
  overflow-x: hidden;

  header {
    display: flex;
    grid-area: top;
    align-items: center;
  }
`;

export const UserManagementModalColumn = styled.div`
  display: flex;
  gap: ${typography.unit * 9}px;
  flex-direction: column;
  flex-grow: 1;
`;

export const UserManagementModalHorizontalColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${typography.unit * 7}px;
  justify-content: space-between;

  > * {
    flex-grow: 1;
  }
`;

export const UserManagementModalNames = styled.div`
  display: flex;
  gap: ${typography.unit * 5}px;
`;

export const UserManagementModalCompany = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    display: block;
    font-size: 13px;
    margin-bottom: ${typography.unit * 2}px;
  }
`;

export const UserManagementModalSection = styled(StyledFormSection)`
  gap: 8px;
  margin-bottom: 0px;
`;

export const UserManagementModalSectionTitle = styled.div<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  align-self: stretch;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.15px;
`;

export const UserManagementModalNotificationSectionTitle = styled(UserManagementModalSectionTitle)`
  margin-bottom: 8px;
`;

export const UserManagementModalTextField = styled(DoeTextField)`
  width: 100%;
  gap: 8px;
`;

export const UserManagementModalDoeBannerSection = styled(UserManagementModalSection)`
  width: 100%;
`;

export const NotificationRegisteration = styled.div`
  ${CheckboxContainer} {
    margin-bottom: ${typography.unit * 4}px;
  }

  span {
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: ${typography.unit * 4}px;
  }
`;

export const UserManagementModalFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: ${typography.unit * 5}px;
  grid-area: footer;
  align-items: center;
  padding: 24px;
  border-top: 1px solid #d6d6d6;
`;

export const StyledModal = styled(UserModal)`
  max-width: 750px;

  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const RoleSelectbox = styled(Selectbox)`
  height: fit-content;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCheckbox = styled(Checkbox)`
  & label {
    font-weight: 400;
    color: ${newGlobalTheme.colors.text.primary};
  }
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  margin-bottom: 16px;
`;

export const StyledGoldButton = styled(NewButtonContained)`
  height: 52px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  border: 1px solid #c08b00;
  background: var(--Brand-Colors-McDonalds-Gold, #ffbc0d);
  color: ${newGlobalTheme.colors.text.primary};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Speedee;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  letter-spacing: -0.187px;
  &:disabled {
    border: none;
    background: ${newGlobalTheme.colors.global.disabledGold};
    color: ${newGlobalTheme.colors.text.disabledSave};
  }
  :hover {
    border: 1px solid var(--Accent-Gold, #c08b00);
    background: var(--McDonalds-Gold, #ffbc0d);
  }
`;
