import styled from '@emotion/styled';
import zIndex from 'styles/zIndex';
import typography from 'styles/typography';
import { css, keyframes } from '@emotion/react';
import { ModalProps } from './Modal.const';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewButtonText } from '../button';
import { Icon } from '../icon';

export const Container = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${typography.transition}s;
  z-index: ${zIndex.modal};
  background: ${({ theme }) => theme.colors.global.background};
  border-radius: ${typography.borderRadius}px;
  box-shadow: 0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22);
  width: 720px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  max-height: 98vh;
`;

const loader = keyframes`
  0% {visibility: visible; background: unset; width:100%; height: 100%; opacity: 0}
  100% {visibility: visible; background: rgba(255, 255, 255, 1); width:100%; height: 100%; opacity: 0.5}
`;

export const Loading = styled.div<{ isLoading: boolean }>`
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.modalLoading};
  animation: ${({ isLoading }) =>
    isLoading
      ? css`
          ${loader} 0.3s 0s 1 ease-in-out forwards
        `
      : 'unset'};
`;

// margin-bottom: ${typography.unit * 5}px;
// height: 28px;
export const ModalHeader = styled.div`
  font-family: Speedee;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  font-weight: 700;
  line-height: 22.834px; /* 81.55% */
  letter-spacing: -0.84px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  height: auto;
  min-width: 720px;
  max-width: 1080px;
  border-bottom: 1px solid var(--Secondary-Colors-Grey, #adadad);
`;

export const MainTitle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 28px;
  align-items: center;
`;

export const TitleWithProgress = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CloseButtonText = styled(NewButtonText)`

`;

export const CloseIcon = styled(Icon)`
  width: 22px;
  fill: ${({ theme }) => newGlobalTheme.colors.text.primary};
  cursor: pointer;
`;