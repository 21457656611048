import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormMode } from 'utils/types';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { store } from 'app/store';
import { closeModal, Modals, openModal, setData } from 'app/slices/modals';
import { useOfferBanksQuery } from 'hooks/offer-banks';
import {
  ContentTabWrapper,
  DoeImageFormContainer,
  DoeOfferColumnContainer,
  DoeOfferFormContainer,
  DoeOfferFormFooter,
  DoeOfferFormSection,
  DoeOfferFormSectionWrapper,
  DoeOfferFormSubsection,
  DoeOfferFormTextField,
  DoeOfferInfoWrapper,
  DoeOfferSectionSubtitle,
  DoeOfferSectionTitle,
  DoeOfferSectionTitleContainer,
  DoeOfferSelectbox,
  DoeOfferSelectboxPaginate,
  StyledBanner,
  StyledBannerContent,
  StyledBoldText,
  StyledGoBackButton,
  StyledGoldButton,
  StyledInputFiles,
  StyledLabel,
  StyledLine,
  StyledList,
  StyledListItem,
  StyledReplaceImage,
  StyledSupportingText,
  StyledTitle,
  StyledTitleWrapper,
  TagsSelectbox,
} from './DoeOfferForm.style';
import { ReactComponent as RefreshIcon } from 'assets/svgs/refresh.svg';
import {
  BulkEditImageOptions,
  BulkEditOptions,
  DoeOfferFormBulkEditImageMode,
  DoeOfferFormBulkEditMode,
  DoeOfferFormData,
  DoeOfferFormProps,
  PointsOfDistribution,
  PointsOfDistributionRecord,
} from './DoeOfferForm.const';
import DoeOfferModal from 'components/shared/modal/DoeOfferModal';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';
import { client } from 'app/apollo';
import { ImageProps } from '../../../../settings/images/Images.consts';
import { bulkDoeOfferUpdate } from 'utils/api/offers';
import { useBaseOfferRecsQuery } from 'hooks/base-offer-recs';
import doeOfferFormGqls from './DoeOfferForm.gqls';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { useTermsAndConditionsQuery } from 'hooks/use-terms-and-conditions-query';
import { StyledIcon } from 'components/header/components/helpMenu/HelpMenu.style';

enum DoeOfferFormSteps {
  'SelectOfferBankAndGroup' = 1,
  'BulkEditImageOptions' = 2,
  'EditOfferContent' = 3,
  'Confirmation' = 4,
}

interface DoeOfferFormReduxData {
  image?: ImageProps;
  offer: DoeOfferFormData;
  step: DoeOfferFormSteps;
}

const DoeOfferForm = ({ mode, onClose, offerSource, defaultLanguage }: DoeOfferFormProps) => {
  const { config } = useSelector(marketConfig); // TODO: Can be utilized when validation is done

  const reduxData: Readonly<DoeOfferFormReduxData> = useSelector(
    (state: any) => state.modals.data,
  ) as DoeOfferFormReduxData;
  const propsData = useSelector((state: any) => state.modals.props);

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      offerBankId: reduxData?.offer?.offerBankId ?? propsData?.offerBankId ?? null,
      offerGroupId: reduxData?.offer?.offerGroupId ?? propsData?.offerGroupId ?? null,
      bulkEditOptions: reduxData?.offer?.bulkEditOptions ?? BulkEditOptions[0].value,
      bulkEditImageOptions: reduxData?.offer?.bulkEditImageOptions ?? null,
    } as any,
  });
  const [step, setStep] = useState(reduxData?.step ?? DoeOfferFormSteps.SelectOfferBankAndGroup);
  const { loadOptions: loadOfferBankOptions } = useOfferBanksQuery(true);
  const { loadOptions: loadOfferGroupOptions } = useBaseOfferRecsQuery(true, [formMethods.getValues('offerBankId')]);
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Offer]);
  const { load: loadTermsAndConditions, termsAndConditions } = useTermsAndConditionsQuery(undefined, {
    AND: [{ isArchive: { is: false } }],
  });

  const [offerBankOpen, setOfferBankOpen] = useState(false);
  const [offerGroupKey, setOfferGroupKey] = useState(false);
  const [offerGroupOpen, setOfferGroupOpen] = useState(false);

  const [isNextEnabled, setIsNextEnabled] = useState(true);
  const [isReplaceEnabled, setIsReplaceEnabled] = useState(false);
  const [modalMode, setModalMode] = useState(mode);
  const [modalOfferSource] = useState(offerSource);
  const [bulkEditOfferInfo, setBulkEditOfferInfo] = useState(null);

  const requiredValidation = useMemo(() => ({ required: true }), []);

  const hasMixedValuesForField = (field: string) => {
    return !bulkEditOfferInfo[field] && !bulkEditOfferInfo.emptyFields.has(field);
  };

  const getInputPlaceholderText = (field: string) => {
    return hasMixedValuesForField(field) ? 'This field contains mixed values' : 'Enter';
  };

  const getInputSupportingText = (field: string) => {
    if (hasMixedValuesForField(field)) {
      return 'Fields containing mixed values must be edited individually.';
    }

    return `All values for this field are currently ${bulkEditOfferInfo[field] ? 'the same' : 'empty'}.`;
  };

  // TODO: Explore a more refined way to handle a state of "no changes" for given offers
  const isNotChanging =
    formMethods.getValues('bulkEditOptions') === DoeOfferFormBulkEditMode.ImagesOnly &&
    formMethods.getValues('bulkEditImageOptions') === DoeOfferFormBulkEditImageMode.KeepImages;

  const handleSubmitBulkEdit = async () => {
    const { bulkEditOptions, bulkEditImageOptions, pointsOfDistribution, ...formData } = formMethods.getValues();

    const pointOfDistribution = pointsOfDistribution ? PointsOfDistributionRecord[pointsOfDistribution] : undefined;

    const isReplacingImage =
      bulkEditOptions !== DoeOfferFormBulkEditMode.TextOnly &&
      bulkEditImageOptions === DoeOfferFormBulkEditImageMode.ReplaceImages &&
      reduxData.image?.id != null;

    const bulkOfferUpdateDto = {
      imageId: isReplacingImage ? reduxData.image.id : undefined,
      pointOfDistribution,
      ...formData,
    };

    try {
      await bulkDoeOfferUpdate(bulkOfferUpdateDto);
      showToast(MessageType.Success, 'Your request to bulk edit offers was completed.');
      store.dispatch(closeModal());
    } catch (error) {
      showToast(MessageType.Error, 'An error occurred while processing your request.');
    }
  };

  const fetchBulkEditOfferInfo = async (offerBankId: string, offerGroupId: string) => {
    const bulkEditOfferInfo = await client.query({
      query: doeOfferFormGqls.queries.getBulkEditOfferInfo,
      variables: {
        data: {
          offerBankId,
          offerGroupId,
        },
      },
    });
    if (bulkEditOfferInfo.error) {
      throw new Error('Failed to fetch base offer rec information.');
    }

    const { pointOfDistribution, emptyFields, ...info } = bulkEditOfferInfo.data.getBulkEditOfferInfo;
    const emptyFieldsSet = new Set(emptyFields);

    if (info.offerCount === 0) {
      showToast(MessageType.Error, 'No offers found.');
      throw new Error('No offers found.');
    }
    if (info.campaignCount === 0) {
      showToast(MessageType.Error, 'No campaigns found.');
      throw new Error('No campaigns found.');
    }

    const pointOfDistributionResult = pointOfDistribution
      ? pointOfDistribution.length > 1
        ? 'deliveryAndPickup'
        : pointOfDistribution[0]
      : null;

    return {
      offerPointOfDistribution: pointOfDistributionResult ?? '',
      emptyFields: emptyFieldsSet,
      ...info,
    };
  };

  const handleNext = async () => {
    const bulkEditOptions = formMethods.getValues('bulkEditOptions');

    switch (step) {
      case DoeOfferFormSteps.SelectOfferBankAndGroup:
        const values = formMethods.getValues();
        await formMethods.trigger(['offerBankId', 'offerGroupId', 'bulkEditOptions']);
        if (!!values.offerBankId && !!values.offerGroupId && !!values.bulkEditOptions) {
          setIsNextEnabled(false);
          try {
            await Promise.all([loadTags(), loadTermsAndConditions()]);
            setBulkEditOfferInfo(await fetchBulkEditOfferInfo(values.offerBankId, values.offerGroupId));
            setStep(
              bulkEditOptions === DoeOfferFormBulkEditMode.TextOnly
                ? DoeOfferFormSteps.EditOfferContent
                : DoeOfferFormSteps.BulkEditImageOptions,
            );
          } catch (e) {
            showToast(MessageType.Error, e.message);
            setIsNextEnabled(true);
          }
        }
        break;
      case DoeOfferFormSteps.BulkEditImageOptions:
        setStep(
          bulkEditOptions === DoeOfferFormBulkEditMode.ImagesOnly
            ? DoeOfferFormSteps.Confirmation
            : DoeOfferFormSteps.EditOfferContent,
        );
        break;
      case DoeOfferFormSteps.EditOfferContent:
        setStep(DoeOfferFormSteps.Confirmation);
        break;
      case DoeOfferFormSteps.Confirmation:
        if (isNotChanging) {
          store.dispatch(closeModal());
          return;
        }

        await handleSubmitBulkEdit();
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    const bulkEditOptions = formMethods.getValues('bulkEditOptions');

    switch (step) {
      case DoeOfferFormSteps.Confirmation:
        setStep(
          bulkEditOptions === DoeOfferFormBulkEditMode.ImagesOnly
            ? DoeOfferFormSteps.BulkEditImageOptions
            : DoeOfferFormSteps.EditOfferContent,
        );
        break;
      case DoeOfferFormSteps.EditOfferContent:
        setStep(
          bulkEditOptions === DoeOfferFormBulkEditMode.TextOnly
            ? DoeOfferFormSteps.SelectOfferBankAndGroup
            : DoeOfferFormSteps.BulkEditImageOptions,
        );
        break;
      case DoeOfferFormSteps.BulkEditImageOptions:
        setStep(DoeOfferFormSteps.SelectOfferBankAndGroup);
        break;
      default:
        break;
    }
  };

  const handleFormValuesChange = (values: any) => {
    switch (step) {
      case DoeOfferFormSteps.SelectOfferBankAndGroup:
        setIsNextEnabled(!!values.offerBankId && !!values.offerGroupId && !!values.bulkEditOptions);
        break;
      case DoeOfferFormSteps.BulkEditImageOptions:
        setIsNextEnabled(!!values.bulkEditImageOptions);
        setIsReplaceEnabled(values.bulkEditImageOptions === DoeOfferFormBulkEditImageMode.ReplaceImages);
        break;
      case DoeOfferFormSteps.EditOfferContent:
        const posTitleEval = hasMixedValuesForField('posTitle') || !!values.posTitle;
        const subtitleEval = hasMixedValuesForField('subtitle') || !!values.subtitle;
        const descriptionEval = hasMixedValuesForField('description') || !!values.description;
        const termIdEval = hasMixedValuesForField('termId') || !!values.termId;
        setIsNextEnabled(posTitleEval && subtitleEval && descriptionEval && termIdEval);
        break;
      default:
        break;
    }
  };

  const getModalTitle = () => {
    switch (modalMode) {
      case FormMode.BulkEdit:
        return 'Bulk Edit - Offers';
      default:
        return '';
    }
  };

  const getNextStatus = () => {
    switch (step) {
      case DoeOfferFormSteps.BulkEditImageOptions:
        return formMethods.getValues('bulkEditOptions') === 'imagesOnly' ? 'Submit' : 'Next';
      case DoeOfferFormSteps.EditOfferContent:
        return 'Submit';
      case DoeOfferFormSteps.Confirmation:
        return isNotChanging ? 'Okay' : 'Confirm';
      default:
        return 'Next';
    }
  };

  useEffect(() => {
    const subscription = formMethods.watch((values) => {
      handleFormValuesChange(values);
    });
    return () => subscription.unsubscribe();
  }, [formMethods, step]);

  useEffect(() => {
    handleFormValuesChange(formMethods.getValues());
  }, [step]);

  useEffect(() => {
    setOfferGroupKey((prev) => !prev);
  }, [offerGroupOpen]);

  useEffect(() => {
    formMethods.reset(formMethods.getValues());
    ReactTooltip.rebuild();
  }, [formMethods.formState.isDirty, modalMode === FormMode.Duplicate]);

  useEffect(() => {
    formMethods.reset(formMethods.getValues());
    store.dispatch(setData({ data: { mode: modalMode } }));
  }, [modalMode]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (event.key === 'Enter') {
      !onClose ? store.dispatch(closeModal()) : onClose();
    }
  };

  const getInitialOfferBank = () =>
    formMethods.getValues('offerBankId') ? [formMethods.getValues('offerBankId')] : [];
  const getInitialOfferGroup = () =>
    formMethods.getValues('offerGroupId') ? [formMethods.getValues('offerGroupId')] : [];

  const renderStep = () => {
    switch (step) {
      case DoeOfferFormSteps.SelectOfferBankAndGroup:
        return (
          <>
            <DoeOfferFormSection>
              <DoeOfferSectionTitle>
                Select the Offer bank and Offer group you want to edit in bulk:
              </DoeOfferSectionTitle>
              <DoeOfferSelectboxPaginate
                control={formMethods.control}
                placeholder=""
                name="offerBankId"
                label="Offer Bank"
                onChange={(selectedItem) => {
                  formMethods.setValue('offerBankId', selectedItem?.id || null);
                  formMethods.setValue('offerGroupId', null);
                  formMethods.trigger('offerBankId');
                }}
                initialSelectedItems={getInitialOfferBank()}
                defaultValue={formMethods.getValues('offerBankId')}
                reset
                isOpen={offerBankOpen}
                setIsOpen={setOfferBankOpen}
                loadOptions={loadOfferBankOptions}
                validation={{ required: 'Required' }}
                errors={formMethods.formState.errors}
              />
              <DoeOfferSelectboxPaginate
                key={`doeOfferForm_${offerGroupKey}`}
                control={formMethods.control}
                placeholder=""
                name="offerGroupId"
                label="Offer Group"
                onChange={(selectedItem) => {
                  const selectedOfferGroup = selectedItem ? Number(selectedItem?.id) : null;
                  formMethods.setValue('offerGroupId', selectedOfferGroup);
                  formMethods.trigger('offerGroupId');
                }}
                initialSelectedItems={getInitialOfferGroup()}
                disabled={!formMethods.getValues('offerBankId')}
                reset
                isOpen={offerGroupOpen}
                setIsOpen={setOfferGroupOpen}
                loadOptions={loadOfferGroupOptions}
                validation={{ required: 'Required' }}
                defaultValue={formMethods.getValues('offerBankId')}
                errors={formMethods.formState.errors}
              />
            </DoeOfferFormSection>
            <DoeOfferFormSection>
              <DoeOfferSectionTitle>Select the type of content you want to edit in bulk</DoeOfferSectionTitle>
              <RadioGroup
                control={formMethods.control}
                name="bulkEditOptions"
                radioList={BulkEditOptions}
                defaultValue={null}
                disabled={false} // TODO: Should be updated according to the requirement
              />
            </DoeOfferFormSection>
          </>
        );
      case DoeOfferFormSteps.BulkEditImageOptions:
        return (
          <DoeOfferFormSection>
            <StyledBanner type={bulkEditOfferInfo.imageId ? MessageType.Success : MessageType.Important}>
              <StyledBannerContent>
                {bulkEditOfferInfo.imageId
                  ? 'All images in the selected offer bank are identical'
                  : 'This Offer Group includes multiple images.'}
              </StyledBannerContent>
            </StyledBanner>
            <DoeOfferFormSubsection>
              <DoeOfferSectionTitle>Options for editing bulk Image</DoeOfferSectionTitle>
              <RadioGroup
                control={formMethods.control}
                name="bulkEditImageOptions"
                radioList={BulkEditImageOptions}
                defaultValue={null}
                disabled={false} // TODO: Should be updated according to the requirement
              />
            </DoeOfferFormSubsection>
            {isReplaceEnabled && (
              <DoeOfferFormSubsection>
                <DoeOfferSectionTitleContainer>
                  <DoeOfferSectionTitle>Bulk replace image</DoeOfferSectionTitle>
                  <DoeOfferSectionSubtitle>Pick an image from your existing VCE library</DoeOfferSectionSubtitle>
                </DoeOfferSectionTitleContainer>
                <StyledReplaceImage>
                  {reduxData?.image && (
                    <img src={reduxData?.image?.file} alt={reduxData?.image?.name ?? ''} crossOrigin="anonymous"></img>
                  )}
                  <StyledInputFiles
                    onClick={() =>
                      store.dispatch(
                        openModal({
                          modal: Modals.ImageSelectionModal,
                          props: {
                            data: {
                              selectedLanguage: defaultLanguage ?? config.primaryLanguage,
                              offer: formMethods.getValues(),
                              mode: modalMode,
                            },
                          },
                          data: {
                            selectedLanguage: defaultLanguage ?? config.primaryLanguage,
                            offer: formMethods.getValues(),
                            mode: modalMode,
                            offerSource: modalOfferSource,
                            step,
                          },
                        }),
                      )
                    }
                  >
                    <StyledIcon name="refresh" />
                    Replace
                  </StyledInputFiles>
                </StyledReplaceImage>
              </DoeOfferFormSubsection>
            )}
          </DoeOfferFormSection>
        );
      case DoeOfferFormSteps.EditOfferContent:
        return (
          <DoeOfferColumnContainer>
            <DoeImageFormContainer>
              <StyledTitleWrapper>
                <StyledTitle>Selection summary</StyledTitle>
              </StyledTitleWrapper>
              <StyledLine />
              {formMethods.getValues('bulkEditOptions') !== DoeOfferFormBulkEditMode.TextOnly &&
                isReplaceEnabled &&
                reduxData?.image && (
                  <StyledReplaceImage>
                    <img src={reduxData.image.file} alt={reduxData.image.name ?? ''} crossOrigin="anonymous"></img>
                  </StyledReplaceImage>
                )}
              <StyledLabel>Offer Bank</StyledLabel>
              <StyledTitle>{formMethods.getValues('offerBankId')}</StyledTitle>
              <StyledLabel>Offer Group</StyledLabel>
              <StyledTitle>{formMethods.getValues('offerGroupId')}</StyledTitle>
            </DoeImageFormContainer>
            <DoeOfferFormSectionWrapper>
              <DoeOfferFormSection>
                <DoeOfferSectionTitle>Options for bulk editing</DoeOfferSectionTitle>
                <DoeOfferInfoWrapper>
                  <DoeOfferFormTextField
                    register={formMethods.register}
                    shouldUnregister={false}
                    errors={formMethods.formState.errors}
                    name="posTitle"
                    label="POS Title"
                    placeholder={getInputPlaceholderText('posTitle')}
                    value={bulkEditOfferInfo.posTitle ?? ''}
                    onChange={() => {}}
                    labelIsHorizontal
                    disabled={hasMixedValuesForField('posTitle')}
                    validation={{
                      maxLength: { value: 100, message: 'Up to 100 characters' },
                    }}
                  />
                  <StyledSupportingText>{getInputSupportingText('posTitle')}</StyledSupportingText>
                </DoeOfferInfoWrapper>
                <DoeOfferInfoWrapper>
                  <DoeOfferFormTextField
                    register={formMethods.register}
                    shouldUnregister={false}
                    errors={formMethods.formState.errors}
                    name="subtitle"
                    label="Subtitle"
                    placeholder={getInputPlaceholderText('subtitle')}
                    value={bulkEditOfferInfo.subtitle ?? ''}
                    onChange={() => {}}
                    labelIsHorizontal
                    disabled={hasMixedValuesForField('subtitle')}
                    validation={{
                      maxLength: { value: 100, message: 'Up to 100 characters' },
                    }}
                  />
                  <StyledSupportingText>{getInputSupportingText('subtitle')}</StyledSupportingText>
                </DoeOfferInfoWrapper>
                <DoeOfferInfoWrapper>
                  <DoeOfferFormTextField
                    register={formMethods.register}
                    shouldUnregister={false}
                    errors={formMethods.formState.errors}
                    name="description"
                    label="Description"
                    placeholder={getInputPlaceholderText('description')}
                    value={bulkEditOfferInfo.description ?? ''}
                    onChange={() => {}}
                    labelIsHorizontal
                    disabled={hasMixedValuesForField('description')}
                    validation={{
                      maxLength: { value: 500, message: 'Up to 500 characters' },
                    }}
                  />
                  <StyledSupportingText>{getInputSupportingText('description')}</StyledSupportingText>
                </DoeOfferInfoWrapper>
                <DoeOfferInfoWrapper>
                  <DoeOfferSelectbox
                    name="termId"
                    control={formMethods.control}
                    items={termsAndConditions}
                    placeholder="Select"
                    label="Terms and Conditions"
                    initialSelectedItems={[bulkEditOfferInfo.termId ?? '']}
                    validation={requiredValidation}
                    disabled={hasMixedValuesForField('termId')}
                    labelIsHorizontal={true}
                    version="doe-offer-form"
                  />
                  <StyledSupportingText>{getInputSupportingText('termId')}</StyledSupportingText>
                </DoeOfferInfoWrapper>
                <DoeOfferInfoWrapper>
                  <TagsSelectbox
                    multiple
                    label="Tags"
                    initialSelectedItems={bulkEditOfferInfo.tagIds ?? []}
                    placeholder="Select"
                    control={formMethods.control}
                    errors={formMethods.formState.errors}
                    name="tags"
                    items={tags}
                    disabled={modalMode === FormMode.View || hasMixedValuesForField('tagIds')}
                    reset
                    withSearch
                    labelIsHorizontal
                    withAmount
                    limit={10}
                    maxItems={1}
                    selectHeight={36}
                    version="doe-offer-form"
                    containerGap={8}
                  />
                  <StyledSupportingText>{getInputSupportingText('tagIds')}</StyledSupportingText>
                </DoeOfferInfoWrapper>
              </DoeOfferFormSection>
              <DoeOfferInfoWrapper>
                <DoeOfferSectionTitle>Points of distribution</DoeOfferSectionTitle>
                <StyledSupportingText>{getInputSupportingText('pointOfDistribution')}</StyledSupportingText>
                <RadioGroup
                  control={formMethods.control}
                  name="pointsOfDistribution"
                  radioList={PointsOfDistribution}
                  defaultValue={bulkEditOfferInfo.offerPointOfDistribution}
                  disabled={hasMixedValuesForField('pointOfDistribution')}
                  direction="column"
                />
              </DoeOfferInfoWrapper>
            </DoeOfferFormSectionWrapper>
          </DoeOfferColumnContainer>
        );
      case DoeOfferFormSteps.Confirmation:
        return isNotChanging || (bulkEditOfferInfo.offerCount === 0 && bulkEditOfferInfo.campaignCount === 0) ? (
          <StyledBoldText>No offers will be impacted by this change.</StyledBoldText>
        ) : (
          <DoeOfferFormSection>
            <StyledBanner type={MessageType.Warning}>
              <StyledBannerContent>You are about to confirm a bulk action!</StyledBannerContent>
            </StyledBanner>
            <ContentTabWrapper>
              <StyledLabel>Your changes will impact:</StyledLabel>
              <StyledList>
                <StyledListItem>{bulkEditOfferInfo.offerCount} offers</StyledListItem>
                <StyledListItem>{bulkEditOfferInfo.campaignCount} campaigns</StyledListItem>
              </StyledList>
            </ContentTabWrapper>
          </DoeOfferFormSection>
        );
      default:
        return null;
    }
  };

  return (
    <DoeOfferModal
      className={step === DoeOfferFormSteps.Confirmation ? 'autoHeight' : undefined}
      title={getModalTitle()}
      modalMode={modalMode}
    >
      <FormProvider {...formMethods}>
        <DoeOfferFormContainer>{renderStep()}</DoeOfferFormContainer>
      </FormProvider>
      <DoeOfferFormFooter>
        {step !== DoeOfferFormSteps.SelectOfferBankAndGroup && (
          <StyledGoBackButton onClick={handleBack} disabled={false}>
            Go back
          </StyledGoBackButton>
        )}
        <StyledGoldButton onClick={async () => await handleNext()} disabled={!isNextEnabled}>
          {getNextStatus()}
        </StyledGoldButton>
      </DoeOfferFormFooter>
    </DoeOfferModal>
  );
};

export default DoeOfferForm;
