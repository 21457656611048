import { FormMode } from 'utils/types';
import { AuthType, User } from 'utils/types/users';

export interface UserManagementModalProps {
  user?: User;
  mode?: FormMode;
}

export const Companies = [
  {
    value: 'MCD',
    label: 'McDonald\'s',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const AuthTypes = [
  {
    label: 'McD Single Sign On (SSO)',
    value: AuthType.GAS,
  },
  {
    label: 'Password based authentication',
    value: AuthType.VCE,
  },
];
