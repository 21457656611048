import React from 'react';
import { ApprovalStatus, FormMode, GenericItem, GenericSet, isViewMode, SetOfEntity } from 'utils/types';
import LocationSetTitle from 'pages/settings/locationSets/components/locationSetModal/locationSetTitle/LocationSetTitle';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import {
  getAvailableLocationsCountBySearchValue,
  getCampaignsImpactsByLocationSetId,
  getLocationSets,
} from 'utils/api/locationSets';
import { createLocationSet, deleteLocationSet, updateLocationSet } from 'utils/api/locations';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import {
  AccordionContentType,
  SetItemsSelectionFormState,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { Location, LocationSet } from 'utils/types/locations';
import SetItemsSelectionForm from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm';
import { SelectedAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/SelectedSetItemsListPanel.consts';
import { AvailableAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/availableSetItemsListPanel/AvailableSetItemsListPanel.consts';
import { searchLocation, searchLocationSet } from 'utils/location';
import { SelectedDisplayMode } from 'pages/shared/setItemsSelectionForm/setListItem/SetListItem.consts';
import { handleCampaignImpactChange } from 'utils/campaign';
import { Impact } from 'components/impact/impactItem/ImpactItem.consts';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { getAllItemsRecursively } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { useQuery } from '@apollo/client';
import { LocationSetModalProps } from './LocationSetModal.consts';
import { StyledLocationSetModal } from './LocationSetModal.style';
import locationSetsGqls from '../../LocationSets.gqls';
import { FetchPolicies } from 'utils/types/common';
import { UsageEntityType } from 'app/genericSlices/usage';
import { campaignUsage as campaignUsageSlice } from 'app/genericSlices/usage';

const LocationSetModal = ({
  mode = FormMode.New,
  locationSet,
  onSave,
  onCancel,
  onEditFormMode = FormMode.Edit,
  restaurantEligibility,
  supportSetFringes = true,
  isZoneSelection = false,
}: LocationSetModalProps) => {
  const { New, Select, Edit, View, Duplicate, SelectionView, QuickView, BulkEdit } = FormMode;
  const titleByMode: Record<FormMode, (set: GenericSet) => string> = {
    [New]: () => 'Add Location Set',
    [Select]: () => 'Location Selection',
    [Edit]: (set) => `Edit ${set?.name || ''} Set`,
    [BulkEdit]: () => 'Bulk Edit Location Set',
    [View]: (set) =>
      `View ${set?.name || ''} (${getAllItemsRecursively([set], SetOfEntity.Locations, true).length} Locations)`,
    [SelectionView]: () => `View Locations`,
    [Duplicate]: (set) => `Duplicate ${set?.name} Set`,
    [QuickView]: () => '',
  };

  const { data: excludedLocations } = useQuery(locationSetsGqls.queries.getAllLocations, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !restaurantEligibility?.excludeRestaurants,
    variables: {
      data: {
        filters: { [LocationSetsFilters.LocationsCodes]: restaurantEligibility?.excludeRestaurants },
        limit: restaurantEligibility?.excludeRestaurants?.length,
      },
    },
  });

  const { data: excludedLocationSets } = useQuery(locationSetsGqls.queries.getAllLocationSets, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !restaurantEligibility?.excludeRestaurantGroups,
    variables: {
      data: {
        filters: {
          [LocationSetsFilters.CustomSets]: false,
          [LocationSetsFilters.LocationSetsExternalIds]: restaurantEligibility?.excludeRestaurantGroups?.map(
            (group) => group.id,
          ),
        },
        limit: restaurantEligibility?.excludeRestaurantGroups?.length,
      },
    },
  });

  const campaignImpactsBySetId = (limit?: number) => {
    return getCampaignsImpactsByLocationSetId(locationSet.externalId, limit);
  };

  const onEdit = () => {
    store.dispatch(
      openModal({
        modal: Modals.LocationSetModal,
        props: { mode: onEditFormMode, locationSet, onCancel, onSave },
      }),
    );
  };

  const onDuplicate = () => {
    store.dispatch(
      openModal({
        modal: Modals.LocationSetModal,
        props: { mode: Duplicate, locationSet: { ...locationSet, id: null, externalId: null }, onCancel, onSave },
      }),
    );
  };

  const openLocationSetImpactModal = (
    title: string,
    headerComponentType: HeaderComponentType,
    campaignImpacts: Impact[],
    onSubmit?: (set: LocationSet) => void,
    submitButtonString?: string,
  ) => {
    store.dispatch(
      openModal({
        modal: Modals.ImpactModal,
        props: {
          title,
          headerComponentType,
          campaignImpacts,
          entityType: 'Set',
          entityId: locationSet.id,
          onCancel: () => store.dispatch(closeModal()),
          onSubmit,
          submitButtonString,
        },
      }),
    );
  };

  const openLocationSetUsageModal = (
    title: string,
    headerComponentType: HeaderComponentType,
    haveCampaign: boolean,
    onSubmit?: (set: LocationSet) => void,
    submitButtonString?: string,
  ) => {
    store.dispatch(
      openModal({
        modal: Modals.UsageModal,
        props: {
          title,
          headerComponentType,
          haveCampaign,
          entityType: UsageEntityType.LocationSet,
          entityId: locationSet.externalId,
          onCancel: () => {
            store.dispatch(campaignUsageSlice.actions.resetFilters());
            store.dispatch(closeModal());
          },
          onSubmit,
          submitButtonString,
        },
      }),
    );
  };

  const reloadLocationSets = () => {
    store.dispatch(closeModal());
  };

  const onDelete = async () => {
    try {
      await deleteLocationSet(Number(locationSet.id));
      reloadLocationSets();
      showToast(MessageType.Success, `Custom Location Set deleted successfully`);
    } catch (e) {
      showToast(MessageType.Error, `Failed to delete location set`);
    }
  };

  const onLocationSetViewImpact = async (
    isDelete: boolean,
    campaignsImpacts: Impact[],
    canSave = false,
    onSubmit?: (set: LocationSet) => void,
  ) => {
    if (isDelete && canSave) {
      // Location Set can be deleted when all campaign is archived
      openLocationSetImpactModal(
        'Delete Notification',
        HeaderComponentType.LocationSetDeleteSubtitleType,
        campaignsImpacts,
        () => onDelete(),
        'Yes, Delete',
      );
    } else if (isDelete && !canSave) {
      openLocationSetImpactModal(
        'Delete Notification',
        HeaderComponentType.LocationSetDeleteNotificationType,
        campaignsImpacts,
      );
    } else if (!isDelete && canSave) {
      openLocationSetUsageModal(
        'Save Notification',
        HeaderComponentType.LocationSetSaveNotificationType,
        true,
        onSubmit,
        'Yes, Save',
      );
    } else if (!isDelete && campaignsImpacts.length) {
      openLocationSetUsageModal('Location Set Usage', HeaderComponentType.LocationSetUsageType, true);
    } else {
      showToast(MessageType.Info, `The custom location set is not being used in campaigns`);
    }
  };

  const onViewUsage = async () => {
    await onLocationSetViewImpact(false, await campaignImpactsBySetId(25), false);
  };

  const theCampaignsHasAtLeastOneLocationSet = (campaigns: CampaignProps[]) =>
    !campaigns.some((campaign: CampaignProps) => campaign.restaurantEligibility?.restaurantGroups?.length < 2);

  const atLeastOneCampaignIsNotArchive = (campaigns: CampaignProps[]) =>
    !campaigns.some((campaign: CampaignProps) => campaign.status !== ApprovalStatus.Archived);

  const canDelete = (campaigns: CampaignProps[]) => {
    return atLeastOneCampaignIsNotArchive(campaigns) || theCampaignsHasAtLeastOneLocationSet(campaigns);
  };

  const handleDelete = async () => {
    const campaignImpacts = await campaignImpactsBySetId();
    if (campaignImpacts.length) {
      const campaignImpactProps = handleCampaignImpactChange(campaignImpacts);
      await onLocationSetViewImpact(true, campaignImpactProps, canDelete(campaignImpacts));
    } else {
      await onDelete();
    }
  };

  const handleCreate = async (formState: SetItemsSelectionFormState) => {
    const locationSetDto = {
      name: formState.name,
      custom: true,
      sets: Object.keys(formState.selectedItemSetsById).map(Number),
      locations: Object.keys(formState.selectedItemsById).map(Number),
    };
    await createLocationSet(locationSetDto);
    showToast(MessageType.Success, `Custom Location Set added successfully`);
    reloadLocationSets();
  };

  const handleUpdate = async (formState: SetItemsSelectionFormState) => {
    const locationSetDto = {
      id: Number(formState.id),
      name: formState.name,
      custom: true,
      sets: Object.keys(formState.selectedItemSetsById).map(Number),
      locations: Object.keys(formState.selectedItemsById).map(Number),
    };
    const campaignImpacts = await campaignImpactsBySetId(25);
    if (campaignImpacts.length) {
      const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
      await onLocationSetViewImpact(false, campaignsImpactProps, true, async () => {
        await updateLocationSet(locationSetDto);
        showToast(MessageType.Success, `Custom Location Set updated successfully`);
        reloadLocationSets();
      });
    } else {
      await updateLocationSet(locationSetDto);
      showToast(MessageType.Success, `Custom Location Set updated successfully`);
      reloadLocationSets();
    }
  };

  const handleSave = async (formState: SetItemsSelectionFormState) => {
    const { id, name } = formState;

    try {
      if (id) {
        await handleUpdate(formState);
      } else {
        await handleCreate(formState);
      }
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to ${id ? 'update' : 'create'} location set`.concat(
          e.message.includes('duplication item') && name ? ` - ${name} already exists` : '',
        ),
      );
    }
  };

  const availableLocationSetsSectionProps: AvailableAccordionProps[] = [
    ...(isZoneSelection
      ? []
      : [
          {
            name: 'custom-sets',
            headline: 'Sets',
            gqlQuery: locationSetsGqls.queries.getAllLocationSets,
            gqlName: 'getLocationSets',
            getFilters: (searchValue: string) => ({
              [LocationSetsFilters.SearchQuery]: searchValue,
              [LocationSetsFilters.CustomSets]: true,
              [LocationSetsFilters.OnlyRootSets]: true,
              [LocationSetsFilters.SavedOnExternal]: mode === FormMode.Select,
              [LocationSetsFilters.ByRestaurantEligibility]: restaurantEligibility,
            }),
            selectedItemsDisplayMode: SelectedDisplayMode.Disable,
            selectedSetsDisplayMode: SelectedDisplayMode.Hide,
            selectedNestedSetsDisplayMode: SelectedDisplayMode.Hide,
          },
        ]),
    {
      name: 'non-custom-sets',
      headline: 'Locations',
      gqlQuery: locationSetsGqls.queries.getAllLocationSets,
      gqlName: 'getLocationSets',
      getFilters: (searchValue: string) => ({
        [LocationSetsFilters.SearchQuery]: searchValue,
        [LocationSetsFilters.OnlyRootSets]: true,
        [LocationSetsFilters.CustomSets]: false,
        [LocationSetsFilters.SavedOnExternal]: true,
        [LocationSetsFilters.ByRestaurantEligibility]: restaurantEligibility,
      }),
      selectedItemsDisplayMode: SelectedDisplayMode.Hide,
      selectedSetsDisplayMode: SelectedDisplayMode.Hide,
      selectedNestedSetsDisplayMode: SelectedDisplayMode.Hide,
    },
  ];

  const selectedLocationSetSectionProps: SelectedAccordionProps[] = isZoneSelection
    ? []
    : [
        {
          name: 'selected-sets',
          headline: mode === FormMode.Select ? 'Sets' : '',
          contentType: AccordionContentType.SetWithIndividualItems,
          filterItemSet: (set) => mode !== FormMode.Select || set.custom,
          searchItem: searchLocation as (item: GenericItem, searchQuery: string) => boolean,
          searchItemSet: searchLocationSet as (set: GenericSet, searchQuery: string) => boolean,
        },
      ];

  if (mode === FormMode.Select) {
    selectedLocationSetSectionProps.push({
      name: 'selected-locations',
      headline: 'Locations',
      contentType: AccordionContentType.SetWithIndividualItems,
      filterItemSet: (set) => !set.custom || set.name === 'Uncategorized',
      searchItem: searchLocation as (item: GenericItem, searchQuery: string) => boolean,
      searchItemSet: searchLocationSet as (set: GenericSet, searchQuery: string) => boolean,
    });
  }

  const selectedLocationSetsSectionProps: SelectedAccordionProps = {
    name: 'selected-locations-sets',
    headline: 'Location Sets',
    contentType: AccordionContentType.Set,
    searchItem: searchLocation as (item: GenericItem, searchQuery: string) => boolean,
    searchItemSet: searchLocationSet as (set: GenericSet, searchQuery: string) => boolean,
    filterItemSet: () => true,
  };
  const selectedLocationSectionProps: SelectedAccordionProps = {
    name: 'selected-locations',
    headline: 'Locations',
    contentType: AccordionContentType.Items,
    searchItem: searchLocation as (item: GenericItem, searchQuery: string) => boolean,
    searchItemSet: searchLocationSet as (set: GenericSet, searchQuery: string) => boolean,
    filterItemSet: () => true,
  };

  return (
    <StyledLocationSetModal
      title={titleByMode[mode](locationSet)}
      isViewMode={isViewMode(mode)}
      ignoreOperations={['Locations', 'LocationSets']}
    >
      {isViewMode(mode) ? (
        <SetItemsSelectionForm
          data-automation-id="selected-items-container"
          setOf={SetOfEntity.Locations}
          itemSetTitleFormatter={LocationSetTitle}
          selectedAccordionsProps={[selectedLocationSetsSectionProps, selectedLocationSectionProps]}
          itemFormatter={(l: Location) => `${l.code} - ${l.name}`}
          formMode={mode}
          onEdit={onEdit}
          onClose={onCancel}
          onDuplicate={onDuplicate}
          onViewUsage={onViewUsage}
          supportSetFringes={true}
          itemSet={
            {
              sets: locationSet.sets,
              locations: locationSet.locations,
              excludedItems: locationSet.excludedItems,
            } as unknown as GenericSet
          }
          offerPreview
        />
      ) : (
        <SetItemsSelectionForm
          itemSet={locationSet}
          itemSetTitleFormatter={LocationSetTitle}
          setOf={SetOfEntity.Locations}
          formMode={mode}
          searchPlaceholder="Search"
          onSave={onSave ?? handleSave}
          onClose={onCancel}
          onDelete={handleDelete}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          onViewUsage={onViewUsage}
          itemFormatter={(l: Location) => `${l.code} - ${l.name}`}
          supportSetFringes={supportSetFringes}
          fetchRootSetsByIds={async (sets) => {
            const filters = {
              [LocationSetsFilters.ContainsLocationSetsIds]: sets.map((set) => set.id),
              [LocationSetsFilters.OnlyRootSets]: true,
              [LocationSetsFilters.CustomSets]: sets.some((set) => set.custom),
            };

            const result = await getLocationSets(filters, sets.length, 0);
            return result?.items;
          }}
          fetchTotalAvailableItems={(searchValue, selectedItemsIds) =>
            getAvailableLocationsCountBySearchValue(searchValue, selectedItemsIds, restaurantEligibility)
          }
          availableAccordionsProps={availableLocationSetsSectionProps}
          selectedAccordionsProps={selectedLocationSetSectionProps}
          forcedExcludedItems={excludedLocations?.getLocations?.items}
          forcedExcludedSets={excludedLocationSets?.getLocationSets?.items}
          supportSingleItem={!isZoneSelection}
        />
      )}
    </StyledLocationSetModal>
  );
};

export default LocationSetModal;
