import React, { useState, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { MultipleChoiceProps } from './MultipleChoice.consts';
import {
  StyledContainer,
  StyledChoiceItem,
  StyledMultipleChoiceContainer,
  StyledError,
  StyledLabel,
  ItemInput,
  ItemLabel,
} from './MultipleChoice.style';
import { capitalize } from 'utils/text';

const MultipleChoice = ({
  control,
  name,
  validation,
  value,
  disabled = false,
  errors,
  label,
  items,
  itemLabelType,
  className,
  labelIsHorizontal,
}: MultipleChoiceProps) => {
  const [selectedChoice, setSelectedChoice] = useState(value || []);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, itemId: string, field: any, index: number) => {
    // Handle selection on Enter or Space key
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      const indexValue = selectedChoice.indexOf(itemId);
      const newChoices = [...selectedChoice];
      if (indexValue === -1) {
        newChoices.push(itemId);
      } else {
        newChoices.splice(indexValue, 1);
      }
      setSelectedChoice(newChoices);
      field.onChange(newChoices);
    } else if (event.key === 'ArrowLeft') {
      const prevIndex = (index - 1 + items.length) % items.length;
      itemRefs.current[prevIndex]?.focus();
    } else if (event.key === 'ArrowRight') {
      const nextIndex = (index + 1) % items.length;
      itemRefs.current[nextIndex]?.focus();
    }
  };

  return (
    <StyledContainer className={className} labelIsHorizontal={labelIsHorizontal} data-automation-id="multiple-choice">
      {label && (
        <StyledLabel labelIsHorizontal={labelIsHorizontal} disabled={disabled}>
          {`${label}${validation?.required ? '*' : ''}`}
        </StyledLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={value}
        render={({ field }: any) => (
          <StyledMultipleChoiceContainer disabled={disabled}>
            {items.map((item, index) => (
              <StyledChoiceItem
                key={`${item.id}_${index}`}
                ref={(choiceRef) => (itemRefs.current[index] = choiceRef)}
                disabled={disabled}
                tabIndex={0}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, item.id, field, index)
                }
                role="checkbox"
                aria-checked={selectedChoice.includes(item.id)}
              >
                <ItemInput
                  aria-label={item.id}
                  type="checkbox"
                  value={item.name}
                  name={item.id}
                  checked={selectedChoice.includes(item.id)}
                  disabled={disabled}
                  onBlur={field.onBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const indexValue = selectedChoice.indexOf(event.target.name);
                    const newChoices = [...selectedChoice];
                    if (indexValue === -1) {
                      newChoices.push(event.target.name);
                    } else {
                      newChoices.splice(indexValue, 1);
                    }
                    setSelectedChoice(newChoices);
                    field.onChange(newChoices);
                  }}
                  tabIndex={-1}
                />
                <ItemLabel>{capitalize(itemLabelType === 'name' ? item.name : item.id)}</ItemLabel>
              </StyledChoiceItem>
            ))}
          </StyledMultipleChoiceContainer>
        )}
      />
      <StyledError name={name} errors={errors} />
    </StyledContainer>
  );
};

export default MultipleChoice;
