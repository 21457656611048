import { client } from '../../app/apollo';
import { DoeGqls } from '../../pages/campaigns/doeCampaigns/Doe.gqls';

export const bulkDoeCampaignUpdate = async (data: any) => {
  return client.mutate({
    mutation: DoeGqls.mutations.bulkCampaignUpdate,
    variables: {
      data,
    },
    refetchQueries: ['DoeCampaigns', 'GetCampaignsQuickFilters'],
  });
};

export const fetchBulkEditCampaignInfo = async (offerBankId: string, offerGroupId: string) => {
  const bulkEditCampaignInfo = await client.query({
    query: DoeGqls.queries.getBulkEditCampaignInfo,
    variables: {
      data: {
        offerBankId,
        offerGroupId,
      },
    },
  });
  if (bulkEditCampaignInfo.error) {
    throw bulkEditCampaignInfo.error;
  }
  return bulkEditCampaignInfo.data.getBulkEditCampaignInfo;
};
