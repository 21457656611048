import { gql } from '@apollo/client';

const doeOffersGqls = {
  queries: {
    getOffersScreenData: gql`
      query DoeOffers($data: GetAllQueryDto) {
        getDoeOffers(data: $data) {
          items {
            ... on BaseOffer {
              id
              templateType
              isArchive
              isLocked
              offerSource
              segmentId
              baseOfferRecs {
                offerBankId
                offerRecId
                offerGroupId
              }
              tags {
                id
                name
              }
              versions {
                id
                baseOfferId
                status
                translationsMap
                translations {
                  title
                  posTitle
                  subtitle
                  description
                  language
                  image {
                    fileName
                    file
                    id
                  }
                }
                isDraft
              }
            }
          }
          total
        }
      }
    `,
    getById: gql`
      query Offer($id: BigInt!) {
        getOffer(id: $id) {
          ... on BaseOffer {
            id
            templateType
            isArchive
            isLocked
            offerSource
            segmentId
            offerHash
            isAutomated
            tags {
              id
              name
            }
            baseOfferRecs {
              id
              offerBankId
              offerRecId
              offerGroupId
              baseOfferId
            }
            versions {
              id
              baseOfferId
              status
              updatedBy {
                id
              }
              approvals {
                id
                approvalStatus
                comment
              }
              templateValues
              term {
                id
                name
                translationsMap
                originalTermId
              }
              translationsMap
              translations {
                title
                posTitle
                subtitle
                description
                language
                image {
                  fileName
                  file
                  id
                }
              }
              isDraft
              pointOfDistribution
            }
            updatedAt
            createdBy {
              id
            }
            inProgress  
          }
        }
      }
    `,
  },
  mutations: {
    bulkOfferUpdate: gql`
        mutation BulkOfferUpdate($data: BulkOfferUpdateDto!) {
            bulkOfferUpdate(data: $data) {
                id
            }
        }
    `,
  },
};

export default doeOffersGqls;
