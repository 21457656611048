import React from 'react';
import { IconProps } from './Icon.consts';

// Create a mapping of svg names to components
const svgIcons: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {};

// Dynamically require all SVGs from the assets/svgs folder
if (process.env.NODE_ENV !== 'test') {
  const requireSvg = require.context('../../../assets/svgs', false, /\.svg$/);
  requireSvg.keys().forEach((filename) => {
    const iconName = filename.replace('./', '').replace('.svg', '');
    svgIcons[iconName] = requireSvg(filename).default;
  });
}

export const Icon = ({ name, width = 12, dataAutomationID, ...rest }: IconProps) => {
  // Access the dynamically imported SVG component directly from the svgIcons map
  const SvgIcon = svgIcons[name];

  return <>{SvgIcon && 
   <SvgIcon width={width} data-automation-id={dataAutomationID || `icon-${name}`} {...rest} />
    }</>;
};

export default function useStaticSVGImport(name: string) {
  const SvgIcon = svgIcons[name];
  return { SvgIcon };
}



