import React, { useEffect, useRef, useState } from 'react';
import { FormMode } from 'utils/types';
import { SetItemsSelectionFormFooterProps } from 'pages/shared/setItemsSelectionForm/setItemsSelectionFormFooter/SetItemsSelectionFormFooter.consts';
import Error from 'components/shared/error/Error';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import { hideTooltip } from 'utils/tooltip';
import { ButtonsContainer, CancelButton, EditButton, Footer, SaveButton } from './SetItemsSelectionFormFooter.style';
import NewTooltip from 'components/shared/tooltip/NewTooltip';
import Tooltip from 'components/shared/tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';
import OfferToolTip from 'components/shared/tooltip/OfferToolTip';

const DELETE_TOOLTIP_ID = '#delete-tooltip';

const SetItemsSelectionFormFooter = ({
  onSave,
  formMode,
  onDelete,
  onDuplicate,
  onViewUsage,
  onEdit,
  onClose,
  formState,
  isReadOnly = false,
  isNonProduct = false,
}: SetItemsSelectionFormFooterProps) => {
  const { isDirty, isValid, errors } = formState;
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const tooltipRef = useRef(null);

  const handleAction = (actionFn: () => void) => {
    setButtonsDisabled(true);
    try {
      actionFn();
    } finally {
      setButtonsDisabled(false);
    }
  };


  const handleCancelKeyDown = (event: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (event.key === 'Enter') {
        if (onClose) {
          onClose();
        } else {
          store.dispatch(closeModal());
        }
      if (tooltipRef.current) {
        tooltipRef.current.focus(); 
      }
    }
  };

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);

  const handleDelete = (actionFn: () => void) => {
    if (!isDeleteButtonClicked) {
      setIsDeleteButtonClicked(true);
      actionFn();
      hideTooltip(DELETE_TOOLTIP_ID);
    }
  };

  const GeneralButtons = (
    <>
      <CancelButton
        noStopPropagation
        data-tip
        data-for="cancel-tooltip"
        onClick={() => (!isDirty ? (onClose ? onClose() : store.dispatch(closeModal())) : null)}
        onKeyDown={handleCancelKeyDown}
      >
        Cancel
      </CancelButton>
      {isDirty && (
          <OfferToolTip
            id="cancel-tooltip"
            content="Are you sure you want to cancel?"
            onDisapproveClick={() => {hideTooltip('#cancel-tooltip')
              ReactTooltip.hide();
            }}
            eventOff={null}
            onApproveClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}
          />

      )}
       <SaveButton noStopPropagation disabled={!isDirty || !isValid || buttonsDisabled} onClick={() => handleAction(onSave)}>
          Save
        </SaveButton>
      </>
  );

  const ViewButtons = (
      <>
      <CancelButton noStopPropagation onClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}>Close</CancelButton>
      <EditButton noStopPropagation disabled={buttonsDisabled || isReadOnly} onClick={onEdit}>
        Edit
      </EditButton>
    </>
  )

  const FormSectionToComponent : any = {
    [FormMode.View]: ViewButtons,
    [FormMode.New]: GeneralButtons,
    [FormMode.Select]: GeneralButtons,
    [FormMode.Edit]: GeneralButtons,
    [FormMode.BulkEdit]: GeneralButtons,
    [FormMode.Duplicate]: GeneralButtons,
    [FormMode.SelectionView]: (
      <CancelButton onClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}>Close</CancelButton>
    ),
    [FormMode.QuickView]: null,
  };

return (
    <Footer>
      <div>
        {Object.keys(errors)
          .filter((field) => field !== 'name')
          .map((errorKey) => (
            <Error name={errorKey} key={errorKey} className={'SetItemSelectionForm'} errors={errors} />
          ))}
        </div>
      <ButtonsContainer>{FormSectionToComponent[formMode]}</ButtonsContainer>

    </Footer>

  );
};
export default SetItemsSelectionFormFooter;
