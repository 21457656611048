import styled from '@emotion/styled';
import NewModal from 'components/shared/modal/NewModal';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import Banner from 'components/shared/notifications/banner/Banner';
import { StyledWithThemeProps } from 'utils/types';
import { DatePicker } from 'components/shared/picker/datePicker/DatePicker';
import { TimePicker } from 'components/shared/picker/timePicker/TimePicker';
import MultipleChoice from 'components/shared/multipleChoose/MultipleChoice';
import Checkbox from 'components/shared/checkbox/Checkbox';
import typography from 'styles/typography';
import { NewButtonText } from 'components/shared/button';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';
import { List, ListItem } from '@mui/material';
import { SelectboxPaginate } from 'components/shared/selectboxPaginate/SelectboxPaginate';

export const DoeCampaignBulkEditModal = styled(NewModal)`
  width: 680px;
  padding: 0;
  max-height: 95vh;
  min-height: 0;
  letter-spacing: -0.15px;
  border-radius: 16px;

  label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    text-transform: none;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
  }

  h5 {
    color: #6f6f6f;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  [class$='-indicatorContainer'] svg {
    height: 24px;
    width: 24px;
    color: #292929;
  }

  [class~='ModalHeader'] {
    padding: ${typography.unit * 6}px;
    height: unset;
    margin-bottom: 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.global.border};`};

    h3 {
      font-size: 32px;
    }

    > div {
      height: 40px;
    }
  }

  span {
    font-size: 14px;
    line-height: 20px;
  }

  div {
    &[role='alert'] {
      position: unset;
      margin-top: 5px;
      span {
        color: #d90007;
        font-weight: 300;
      }
    }
  }
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  padding: ${typography.unit * 6}px;
`;

export const Step1Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 411px;
`;

export const Step2Container = styled.div`
  min-height: 720px;
  display: flex;

  input {
    padding: 10px 0;
  }
`;

export const Step2SideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 240px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  background: #f9f9f9;
  padding: 16px;
  line-height: 24px;

  h4 {
    font-size: 14px;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 10px;
  }
`;

export const Step2Content = styled.div`
  margin-left: 24px;
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  button {
    display: none;
  }

  .input-wrapper {
    border-bottom-color: #6f6f6f !important;
  }

  fieldset {
    border-bottom-width: 1px !important;
    border-bottom-color: #6f6f6f !important;
  }

  [class~='MuiInputBase-root'] {
    ::after {
      border-bottom: none !important;
    }
  }

  [class~='Mui-focused'] {
    :after {
      border-bottom: none !important;
    }

    [class~='MuiOutlinedInput-notchedOutline'] {
      border-bottom-color: #6f6f6f !important;
    }
  }

  input {
    width: 100% !important;
  }
`;

export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  gap: 14px;

  a {
    text-decoration: underline;
    color: #006bae;
  }
`;

export const StyledLabel = styled.label`
  color: var(--Secondary-Colors-Black, #6f6f6f);
`;

export const StyledSupportingText = styled.div`
  color: var(--Secondary-Colors-Black, #6f6f6f);
  font-size: 12px;
  font-weight: 400;
`;

export const StyledList = styled(List)`
  list-style-type: disc;
  padding-left: 16px;
`;

export const StyledListItem = styled(ListItem)`
  font-weight: 700;
  display: list-item;
  padding: 4px 0 4px 0;
`;

export const StyledNewButtonText = styled(NewButtonText)`
  display: flex !important;
  width: fit-content;
  justify-content: start;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  text-decoration: underline;

  &[disabled] {
    color: #6f6f6f;
    text-decoration: none;
  }
`;

export const Step3Container = styled.div`
  max-height: 396px;

  h5 {
    margin-bottom: 8px;
  }

  ul {
    margin-top: 0;
    padding-inline-start: 30px;
    margin-bottom: 24px;

    li {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
    }
  }
`;

export const BannerWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d6d6d6;
  margin-bottom: 36px;
`;

export const StyledBanner = styled(Banner)<StyledWithThemeProps & { type?: string }>`
  height: 62px;
  padding: 16px;
  border-radius: 0;
  border-left: 6px solid #ffd90d;
  background: #f9f9f9;
`;

export const StyledSelectbox = styled(NewSelectbox)`
  max-width: 320px;
  margin-bottom: 24px;

  > div {
    border-bottom: unset;
  }

  div:hover {
    cursor: pointer;
  }

  div[class*='-control'] {
    background: #fff;
    border-bottom: 1px solid #6f6f6f;
  }
`;

export const StyledSelectPaginate = styled(SelectboxPaginate)`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  gap: 8px;
  margin-bottom: 8px;
` as unknown as typeof SelectboxPaginate;

export const StyledNewTextField = styled(OfferFormTextArea)`
  margin-bottom: 0;
  div {
    font-size: 14px;
  }

  textarea {
    border-top: unset !important;
    border-left: unset !important;
    border-right: unset !important;
    overflow: clip;
    outline: none;
    font-size: 18px;
    border-radius: 0;
    line-height: 20px;
    border-bottom: 1px solid #6f6f6f !important;
    height: 36px;
    padding: 8px 0;

    :focus {
      border-top: unset !important;
      border-left: unset !important;
      border-right: unset !important;
      border-bottom: 1px solid #6f6f6f !important;
    }
  }

  input {
    background: #fff;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  label {
    font-size: 18px;
    font-weight: 400;
  }

  div {
    height: 24px;
    width: 24px;
    border-width: 2px;
  }
`;

export const StyledDatePicker = styled(DatePicker)<{ isDisabled?: boolean }>`
  div {
    border-radius: 0;
    width: 100% !important;
    padding: 0;
    max-width: none !important;
  }

  fieldset {
    border: none;
    width: 100%;
    border-bottom: 1px solid #6f6f6f;
  }
  label {
    ${({ isDisabled }) => isDisabled && `color: #6F6F6F;`}
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  div {
    width: 100% !important;
    padding: 0;
    max-width: none;
  }

  input {
    text-transform: uppercase;
  }
`;

export const StyledFieldSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledMultipleChoice = styled(MultipleChoice)`
  ul {
    width: 100%;
    border: 1px solid #adadad;
    border-radius: 4px;
    overflow: auto;
    padding: 0;

    div {
      margin: 0;
      width: 48px;
      border-right: 1px solid #adadad;

      input {
        height: 100%;
      }

      &[aria-checked='true'] {
        label {
          background: #d6d6d6;
        }
      }

      :last-child {
        border-right: none;
      }

      :focus {
        outline: none;

        label {
          background: #adadad;
        }
      }
    }

    label {
      border-radius: 0;
      border: 0;
      margin: 0;
      width: auto;
      font-size: 14px;
      height: 44px;
      color: #000;
    }
  }
`;

export const StyledDisabledLabel = styled.label`
  color: #6f6f6f;
`;
