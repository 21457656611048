import styled from '@emotion/styled/macro';
import typography, { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { Icon } from 'components/shared/icon';
import { NewButtonText } from 'components/shared/button';

export const StyledNotificationMessageContainer = styled.div<StyledWithThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width:336px;
  background: #f5f5f5;
  font-family: ${newTypography.primaryFont};
  border-radius: 8px;
  border: 6px;
  opacity: 0px;
`;

export const StyledMessageContainer = styled.div<{ isRead: boolean }>`
  display: flex;
  flex-wrap: wrap;
  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  font-weight: ${({ isRead }) => (isRead ? `400` : `700`)};
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: center;
  margin: 15px 15px 15px 15px;
`;

export const StyledMessage = styled.span<StyledWithThemeProps>`
margin-right: ${typography.unit}px;
font-family: ${newTypography.primaryFont};
left: 1034px;
gap: 0px;
opacity: 0px;
font-size: 12px;
line-height: 16px;
letter-spacing: -0.03em;
text-align: left;
position:inherit
top: 180px;
width: 100%;
`;

export const StyledLink = styled.div<StyledWithThemeProps>`
  color: #1d6ca9;
  margin-right: ${typography.unit}px;
  cursor: pointer;
  font-family: ${newTypography.primaryFont};
a:link:not(#exclude),
a:visited:not(#exclude) {
    text-decoration: none;

    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
  }
  a {
    color: #1d6ca9;
    text-decoration: none;
    text-align: left;
    outline: none; 
    &:focus {
      outline: 2px solid #1d6ca9;
    }
  }
`;


export const StyledMessageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 4px 15px 15px;

  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: left;
`;
export const StyledActionContainer = styled.div`
  display: flex;
  padding-right: 10px;
`;
export const StyledActionsContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledAction = styled(NewButtonText)`
  margin-left: ${typography.unit}px;
  font-size: 12px;
  font-family: ${newTypography.primaryFont};
  color: #1d6ca9;
`;

export const StyledIcon = styled(Icon)<StyledWithThemeProps>`
  color: #6f6f6f;
  margin-right: 11.55px;
`;
