import { FormMode } from 'utils/types';
import { Language } from 'utils/languages';
import { BaseOffer } from 'utils/types/offers';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

export interface DoeOfferFormProps {
  onClose: (refresh?: boolean) => void;
  offer?: BaseOffer;
  defaultLanguage?: Language;
  mode?: FormMode;
  viewOnly?: boolean;
  offerFormTags?: any;
  offerSelectedLanguage?: any;
  offerFormEditValues?: any;
  offerSource?: OfferSource;
}

export interface DoeOfferFormValues {
  title: string;
  posTitle: string;
  description: string;
  tcId: number;
}

export interface DoeOfferFormData {
  offerBankId: string;
  offerGroupId: number;
  bulkEditOptions: DoeOfferFormBulkEditMode;
  bulkEditImageOptions: DoeOfferFormBulkEditImageMode;
}

export enum DoeOfferFormBulkEditMode {
  'ImagesOnly' = 'imagesOnly',
  'TextOnly' = 'textOnly',
  'TextAndImages' = 'textAndImages',
}

export enum DoeOfferFormBulkEditImageMode {
  'KeepImages' = 'keepImages',
  'ReplaceImages' = 'replaceImages',
}

export const BulkEditOptions = [
  {
    value: DoeOfferFormBulkEditMode.TextAndImages,
    label: 'Text and Images',
  },
  {
    value: DoeOfferFormBulkEditMode.TextOnly,
    label: 'Text Only',
  },
  {
    value: DoeOfferFormBulkEditMode.ImagesOnly,
    label: 'Images Only',
  },
];

export const BulkEditImageOptions = [
  {
    value: DoeOfferFormBulkEditImageMode.KeepImages,
    label: 'Keep images as they are',
  },
  {
    value: DoeOfferFormBulkEditImageMode.ReplaceImages,
    label: 'Bulk replace with a single image',
  },
];

export const PointsOfDistribution = [
  {
    value: 'deliveryAndPickup',
    label: 'Delivery And Pickup',
  },
  {
    value: 'Delivery',
    label: 'Delivery Only',
  },
  {
    value: 'Pickup',
    label: 'Pickup Only',
  },
];

export const PointsOfDistributionRecord: Record<string, string[]> = {
  'deliveryAndPickup': ['Delivery', 'Pickup'],
  'Delivery': ['Delivery'],
  'Pickup': ['Pickup'],
};

export const MockTags = [
  {
    __typename: 'Tag',
    id: 100026,
    name: 'Eran',
    amount: 2,
    isMasterTag: true,
    isLocked: false,
    createdBy: {
      __typename: 'User',
      id: 1,
      firstName: 'Dynamic',
      lastName: 'Yield',
      email: 'vce@dynamicyield.com',
      active: true,
    },
    validEntities: ['Campaign', 'Image', 'Offer'],
    amountByEntities: {
      offer: 2,
    },
  },
  {
    __typename: 'Tag',
    id: 100025,
    name: 'Tag Image 1646519433650',
    amount: 2,
    isMasterTag: true,
    isLocked: false,
    createdBy: {
      __typename: 'User',
      id: 1,
      firstName: 'Dynamic',
      lastName: 'Yield',
      email: 'vce@dynamicyield.com',
      active: true,
    },
    validEntities: ['Image', 'Offer', 'Campaign'],
    amountByEntities: {
      offer: 2,
    },
  },
  {
    __typename: 'Tag',
    id: 100000,
    name: 'test13',
    amount: 2,
    isMasterTag: true,
    isLocked: false,
    createdBy: {
      __typename: 'User',
      id: 1,
      firstName: 'Dynamic',
      lastName: 'Yield',
      email: 'vce@dynamicyield.com',
      active: true,
    },
    validEntities: ['Campaign', 'Image', 'Offer'],
    amountByEntities: {
      offer: 2,
    },
  },
];

export const MockOfferBankOptions = [
  { id: '123e4567-e89b-12d3-a456-426614174000', name: '123e4567-e89b-12d3-a456-426614174000' },
  { id: '123e4567-e89b-12d3-a456-426614174001', name: '123e4567-e89b-12d3-a456-426614174001' },
  { id: '123e4567-e89b-12d3-a456-426614174002', name: '123e4567-e89b-12d3-a456-426614174002' },
  { id: '123e4567-e89b-12d3-a456-426614174003', name: '123e4567-e89b-12d3-a456-426614174003' },
];

export const MockOfferGroupOptions = [
  { id: 100040, name: '100040' },
  { id: 100041, name: '100041' },
  { id: 100042, name: '100042' },
  { id: 100043, name: '100043' },
];

export const MockTermsAndConditions = [
  {
    __typename: 'Term',
    id: 100000,
    isArchive: false,
    name: 'T&C1',
    template: [''],
  },
  {
    __typename: 'Term',
    id: 100001,
    isArchive: false,
    name: 'T&C2',
    template: [''],
  },
  {
    __typename: 'Term',
    id: 100002,
    isArchive: false,
    name: 'T&C3',
    template: [''],
  },
  {
    __typename: 'Term',
    id: 100003,
    isArchive: false,
    name: 'T&C4',
    template: [''],
  },
];
