import { OrderDirection } from '../utils/types';
import { useLazyQuery } from '@apollo/client';
import { FetchPolicies } from '../utils/types/common';
import { termsConditionsGqls } from '../pages/settings/termsConditions/TermsConditions.gqls';

export const useTermsAndConditionsQuery = (order: any = { name: OrderDirection.ASC }, filters?: any) => {
  const [
    load,
    { data: { getTerms: { items: termsAndConditions = [], total = 0 } = {} } = {} },
  ] = useLazyQuery(termsConditionsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters,
        order,
      },
    },
  });

  return { load, termsAndConditions, total };
};
