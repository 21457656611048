export interface User {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  role?: UserRole;
  domainName?: DomainName;
  rgmAttribute?: RGMAttribute;
  active?: boolean;
  notifications?: string[];
  isLocked?: boolean;
  userOperation?: UserOperation;
}

export interface UserOperation {
  id: number;
  role: Role;
  domain: Domain;
  attribute?: DomainAttribute;
}

export interface Role {
  id: number;
  roleName: UserRole;
}

export interface Domain {
  id: number;
  domainName: DomainName;
  attributes: DomainAttribute[];
}

export interface DomainAttribute {
  id: number;
  attributeName: RGMAttribute;
}

export enum UserRole {
  SysAdmin = 'sysadmin',
  Admin = 'admin',
  Creator = 'creator',
  Trainee = 'trainee',
  Viewer = 'viewer',
}

export const UserRoleRecord: Record<UserRole, string> = {
  [UserRole.SysAdmin]: 'System Admin',
  [UserRole.Admin]: 'Admin',
  [UserRole.Creator]: 'Creator',
  [UserRole.Trainee]: 'Trainee',
  [UserRole.Viewer]: 'Viewer',
};

export enum UserNotificationType {
  NoRedemptions = 'noRedemptions',
  CampaignErrors = 'campaignErrors',
  CampaignRequireApproval = 'campaignApprovalRequired',
  ProductUpdate = 'productUpdate',
  LocationUpdate = 'locationUpdate',
  DOEBulkOfferResults = 'doeBulkOfferResults',
  DOEBulkCampaignResults = 'doeBulkCampaignResults',
}

export const UserNotificationRecord: Record<UserNotificationType, string> = {
  [UserNotificationType.NoRedemptions]: 'No Redemptions Notification',
  [UserNotificationType.CampaignErrors]: 'Campaign Error Notification',
  [UserNotificationType.CampaignRequireApproval]: 'Campaign Requires Approval Notification',
  [UserNotificationType.ProductUpdate]: 'Product Update Notification',
  [UserNotificationType.LocationUpdate]: 'Location Update Notification',
  [UserNotificationType.DOEBulkOfferResults]: 'DOE Bulk Offer Results Notification',
  [UserNotificationType.DOEBulkCampaignResults]: 'DOE Bulk Campaign Results Notification',
};

export enum AuthType {
  GAS = 'gas',
  VCE = 'vce',
}

export interface UserNameNotification {
  firstName: string;
  lastName: string;
}

export enum DomainName {
  Offers = 'offers',
  Doe = 'doe',
}

export const DomainNameRecord: Record<DomainName, string> = {
  [DomainName.Offers]: 'Offer',
  [DomainName.Doe]: 'Value Optimizer',
}

export enum RGMAttribute {
  Approver = 'approver',
  Editor = 'editor',
  Viewer = 'viewer'
}

export const RGMAttributeRecord: Record<RGMAttribute, string> = {
    [RGMAttribute.Approver]: 'PE3 Approver',
    [RGMAttribute.Editor]: 'PE3 Editor',
    [RGMAttribute.Viewer]: 'PE3 Viewer',
}