import React from 'react';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { ThemeProvider } from '@mui/material/styles';
import { DatePickerProps, materialTheme } from 'components/shared/picker/datePicker/DatePicker.consts';
import { StyledDateContainer, StyledError, StyledLabel } from 'components/shared/picker/datePicker/DatePicker.style';
import { DateFormats } from 'utils/types';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import locale from 'date-fns/locale/en-US';
import { dayOfWeekRecord } from 'utils/date';

export const DatePicker = ({
  validation,
  errors,
  name,
  label,
  labelIsHorizontal,
  disabled,
  disablePast = false,
  minDate,
  maxDate,
  value,
  control,
  onChange,
  className,
}: DatePickerProps) => {
  const { config } = useSelector(marketConfig);
  const formattedDate = `${config.dateFormat === DateFormats.DayMonthYear ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}`;
  if (locale && locale.options) {
    locale.options.weekStartsOn = dayOfWeekRecord[config.calendarWeekStartsOn] || 0;
  }

  return (
    <StyledDateContainer
      className={className}
      labelIsHorizontal={labelIsHorizontal}
      disabled={disabled}
      data-automation-id="date-picker"
    >
      {label && (
        <StyledLabel labelIsHorizontal={labelIsHorizontal} disabled={disabled}>{`${label}${
          validation?.required ? '*' : ''
        }`}</StyledLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={value}
        render={({ field }: any) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <ThemeProvider theme={materialTheme}>
              <MuiDatePicker
                slotProps={{ textField: { onBlur: field.onBlur } }}
                value={field.value}
                onChange={(date) => {
                  field.onChange(date);
                  if (onChange) {
                    onChange(date);
                  }
                }}
                format={formattedDate}
                disabled={disabled}
                disablePast={disablePast}
                minDate={minDate}
                maxDate={maxDate}
              />
            </ThemeProvider>
          </LocalizationProvider>
        )}
      />
      <StyledError name={name} errors={errors} />
    </StyledDateContainer>
  );
};
