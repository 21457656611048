import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { ApprovalStatus, GenericItem } from 'utils/types';
import { OfferFilters } from 'pages/offers/offerManagement/Offers.const';
import type { RootState } from 'app/store';
import { buildOSBaseAndFilters, generateOfferStatusFilters } from 'utils/serverFilters';

export enum OffersStateId {
  DoeOffersPage = 'doeOffersPage',
  OffersPage = 'offersPage',
  OffersSelection = 'offersSelection',
  ZoneOffersSelection = 'zoneOffersSelection',
}

export interface OffersFilters {
  [OfferFilters.Id]: number[];
  [OfferFilters.TemplateType]: number[];
  [OfferFilters.Status]: ApprovalStatus[];
  [OfferFilters.Tags]: { [key in number | string]: GenericItem };
  [OfferFilters.SearchQuery]: string;
  [OfferFilters.Zone]?: number[];
  [OfferFilters.OfferGroup]: { [key in number | string]: GenericItem };
  [OfferFilters.OfferBank]: { [key in number | string]: GenericItem };
}

type OffersGenericSlice = {
  offersState: (state: RootState) => any;
  reducer: any;
  actions: any;
  offersSlice: any;
  getFilters: any;
};

export interface Offers {
  filters: OffersFilters;
}

export interface BaseOfferRec {
  id: string;
  offerGroupId: string;
}

export interface OfferBank {
  offerBankId: string;
}

export const initialState: Offers = {
  filters: {
    [OfferFilters.Id]: [],
    [OfferFilters.TemplateType]: [],
    [OfferFilters.Status]: [
      ApprovalStatus.Draft,
      ApprovalStatus.PendingApproval,
      ApprovalStatus.Approved,
      ApprovalStatus.Rejected,
    ],
    [OfferFilters.Tags]: {},
    [OfferFilters.SearchQuery]: '',
    [OfferFilters.Zone]: [],
    [OfferFilters.OfferGroup]: {},
    [OfferFilters.OfferBank]: {},
  },
};

export const DoeInitialState : Offers = {
  filters: {
    [OfferFilters.Id]: [],
    [OfferFilters.TemplateType]: [],
    [OfferFilters.Status]: [ApprovalStatus.Approved],
    [OfferFilters.Tags]: {},
    [OfferFilters.SearchQuery]: '',
    [OfferFilters.Zone]: [],
    [OfferFilters.OfferGroup]: {},
    [OfferFilters.OfferBank]: {},
  }
}

export const createOffersGenericSlice = (
  sliceName: OffersStateId,
  initialSliceState = initialState,
): OffersGenericSlice => {
  const getFilters = (initialFilters: { [key in OfferFilters]?: any[] }, nglRequired = true) => {
    const OfferServerFiltersRecord = {
      [OfferFilters.Tags]: 'tags.id',
      [OfferFilters.TemplateType]: 'templateType',
      [OfferFilters.Id]: 'id',
      [OfferFilters.Zone]: 'sets.id',
      [OfferFilters.OfferGroup]: 'baseOfferRecs.offerGroupId',
      [OfferFilters.OfferBank]: 'baseOfferRecs.offerBankId',
    };
    const filters = buildOSBaseAndFilters(OfferServerFiltersRecord, initialFilters, nglRequired);
    filters.bool.must.push({ match: { 'versions.isLatest': true } });

    if (initialFilters[OfferFilters.Status]?.length) {
      const statusFilter = generateOfferStatusFilters(initialFilters);
      filters.bool.must.push({ bool: { should: statusFilter } });
    }

    if (initialFilters[OfferFilters.SearchQuery]?.length) {
      filters[OfferFilters.SearchQuery] = initialFilters[OfferFilters.SearchQuery][0] as any;
    }
    return filters;
  };

  const offersSlice = createSlice({
    name: sliceName,
    initialState: initialSliceState,
    reducers: {
      setFilters(state, action) {
        state.filters = action.payload;
      },
      setFilter(state, action) {
        state.filters = {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        };
      },
    },
  });

  const offersState = (state: RootState) => state.offers[sliceName];
  return {
    offersState,
    reducer: offersSlice.reducer,
    actions: offersSlice.actions,
    offersSlice,
    getFilters,
  };
};

export const vceOffersPage: OffersGenericSlice = createOffersGenericSlice(OffersStateId.OffersPage);
export const doeOffersPage: OffersGenericSlice = createOffersGenericSlice(OffersStateId.DoeOffersPage, {
  filters: {
    [OfferFilters.Id]: [],
    [OfferFilters.TemplateType]: [],
    [OfferFilters.Status]: [
      ApprovalStatus.Approved,
    ],
    [OfferFilters.Tags]: {},
    [OfferFilters.SearchQuery]: '',
    [OfferFilters.OfferGroup]: {},
    [OfferFilters.OfferBank]: {},
  },
});
export const offersSelection: OffersGenericSlice = createOffersGenericSlice(OffersStateId.OffersSelection, {
  filters: {
    [OfferFilters.Id]: [],
    [OfferFilters.TemplateType]: [],
    [OfferFilters.Status]: [ApprovalStatus.Approved],
    [OfferFilters.Tags]: {},
    [OfferFilters.SearchQuery]: '',
    [OfferFilters.Zone]: [],
    [OfferFilters.OfferGroup]: {},
    [OfferFilters.OfferBank]: {},
  },
});
export const zoneOffersSelection: OffersGenericSlice = createOffersGenericSlice(OffersStateId.ZoneOffersSelection, {
  filters: {
    [OfferFilters.Id]: [],
    [OfferFilters.TemplateType]: [],
    [OfferFilters.Status]: [ApprovalStatus.Approved],
    [OfferFilters.Tags]: {},
    [OfferFilters.SearchQuery]: '',
    [OfferFilters.Zone]: [],
    [OfferFilters.OfferGroup]: {},
    [OfferFilters.OfferBank]: {},
  },
});

const offersReducer = combineReducers({
  [OffersStateId.DoeOffersPage]: doeOffersPage.reducer,
  [OffersStateId.OffersPage]: vceOffersPage.reducer,
  [OffersStateId.OffersSelection]: offersSelection.reducer,
  [OffersStateId.ZoneOffersSelection]: zoneOffersSelection.reducer,
});

export default offersReducer;
