import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import {
  ButtonContained,
  ButtonContainedCancel,
  ButtonText,
  NewButtonContained,
  NewButtonText,
} from 'components/shared/button';
import { appTheme, defaultTheme } from 'styles/themes/defaultTheme';
import { StyledWithThemeProps } from 'utils/types';
import { Icon, IconProps, StyledClickableIconProps } from 'components/shared/icon';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import Tooltip from 'components/shared/tooltip/NewTooltip';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { Product } from 'utils/types/products';
import NewTabStrip from 'components/shared/tabStrip/NewTabStrip';
import { StyledFormSection } from 'pages/shared/shared.style';
import { MoreActionsDropDown } from 'components/shared/button/buttonDropdown/MoreActionsDropDown';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import Banner from 'components/shared/notifications/banner/Banner';
import { ImageFormContainerProps } from 'pages/settings/doeImages/components/DoeImageForm/DoeImageForm.consts';
import { MessageType } from 'components/shared/notifications/notifications';
import { get } from 'lodash';
import { List, ListItem } from '@mui/material';
import InputFiles from 'components/shared/inputFiles/InputFiles';
import { SelectboxPaginate } from 'components/shared/selectboxPaginate/SelectboxPaginate';

const getBannerColor = (type: MessageType) => {
  switch (type) {
    case MessageType.Important:
    case MessageType.Warning:
      return '#FFD90D';
    case MessageType.Success:
      return '#9CC93A';
    default:
      return '#9CC93A';
  }
};

export const ImageContainer = styled.div<StyledWithThemeProps>`
  img {
    width: 164px;
    height: 249px;
    object-fit: contain;
    padding: 8px;
  }
  button {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0; /* Initially hidden */
    transition: opacity ${typography.transition}s ease;
  }

  &:hover {
    button {
      opacity: 2;
    }
  }
`;

export const DoeImageFormContainer = styled.div<ImageFormContainerProps>`
  max-width: 220px;
  min-height: 600px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 1 33.33%;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Secondary-Colors-Light-Grey, #d6d6d6);
  background: var(--Secondary-Colors-Ivory, #f9f9f9);
`;

export const StyledTabStrip = styled(NewTabStrip)`
  margin: 0;
  height: 31px;
  font-size: 14px;
  font-family: Speedee;
  font-size: 12px;
  gap: 8px;
  border-bottom: 1px solid ${newGlobalTheme.colors.status.archived};
  div {
    padding: 8px 0px;
    font-size: 12px;
  }
`;

export const DoeOfferFormContentContainer = styled.div<{ theme?: appTheme; selected: boolean }>`
  display: ${({ selected }) => (selected ? 'flex' : 'none')};
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const DoeOfferSectionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const DoeOfferSectionTitle = styled.h3<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  font-family: Speedee;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.15px;
`;

export const DoeOfferSectionSubtitle = styled.p<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.text.disabledSave};
  font-family: Speedee;
  font-size: 14px;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.15px;
`;

export const DoeOfferFormImageContainer = styled.aside`
  height: 251px;
`;

export const DoeOfferFormImage = styled.div<StyledWithThemeProps & { imageSelected?: string }>`
  position: relative;
  width: 166px;
  height: 251px;
  border-radius: 5px;
  background: ${({ theme }) => `${theme.colors.global.background}`};
  display: flex;
  justify-content: center;
  word-break: break-all;
  ${({ imageSelected }) =>
    imageSelected
      ? `border: 1px solid ${newGlobalTheme.colors.global.borderColor};`
      : `  border: 1px dashed ${newGlobalTheme.colors.toggle.disabled};`}
`;

export const DoeOfferFormContent = styled.div`
  flex: 1 1 auto;
`;

export const StyledTextField = styled(OfferFormTextField)<{ disabled?: boolean }>``;

export const GroupIdTextField = styled(OfferFormTextField)`
  width: 257px;
  margin-bottom: 0px;
  input {
    padding: 0px;
  }
`;

export const TermsTextArea = styled(OfferFormTextArea)`
  textarea {
    border-radius: 5px;
    height: 70px;
  }
`;

export const StyledTermsAndConditionsTextArea = styled.div`
  position: relative;
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  position: absolute;
  right: ${typography.unit}px;
  top: ${typography.unit}px;
  font-size: 12px;
`;

export const StyledButtonContained = styled(ButtonContained)`
  margin-top: ${typography.unit * 8}px;
  align-self: self-start;
`;

export const DoeOfferFormAdditionalDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

export const DoeOfferFormFooter = styled.footer`
  display: flex;
  margin: auto 0 0 0;
  justify-content: flex-end;
  padding: 24px;
  justify-content: flex-end;
  width: 680px;
  min-width: 540px;
  max-width: 1080px;
  gap: 24px;
  align-items: flex-end;
  border-top: 1px solid #d6d6d6;
`;

export const StyledSelectbox = styled(NewSelectbox)`
  width: 100%;
  gap: 8px;
`;

export const StyledButtonText = styled(ButtonText)`
  margin: ${typography.unit * 2}px 0 0 0;
  color: ${newGlobalTheme.colors.text.primary};
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid var(--Secondary-Colors-Grey, #adadad);
  background: var(--Brand-Colors-White, #fff);
  gap: 4px;
`;

export const DoeOfferFormContainer = styled.div<StyledWithThemeProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 36px;
  overflow-y: scroll;
  padding: 24px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 19px;
    background: ${newGlobalTheme.colors.toggle.disabled};
    padding-left: 20px;
  }
`;

export const DoeOfferColumnContainer = styled.div`
  gap: 24px;
  display: flex;
`;

export const TagsSelectbox = styled(NewSelectbox)`
  label {
    ${({ disabled }) =>
      disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }
` as unknown as typeof NewSelectbox;

export const TermsConditionsSelectbox = styled(NewSelectbox)<{ version?: string }>`
  label {
    ${({ disabled }) =>
      disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }
`;

export const DoeOfferSelectbox = styled(NewSelectbox)`
  background-color: ${newGlobalTheme.colors.global.background};
  label {
    ${({ disabled }) =>
      disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }
` as unknown as typeof NewSelectbox;

export const DoeOfferSelectboxPaginate = styled(SelectboxPaginate)`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
` as unknown as typeof SelectboxPaginate;
export const StyledLabelContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const StyledInfo = styled(Icon)<StyledClickableIconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 15px;
  margin-left: ${typography.unit * 2}px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledButtonDropdown = styled(MoreActionsDropDown)<StyledWithThemeProps>`
  cursor: pointer;
  font-family: Speedee;
  font-weight: 400;
`;

export const StyledUpdatedTime = styled.span`
  font-size: 12px;
  margin-left: ${typography.unit * 2}px;
`;

export const StyledProductTooltip = styled(Tooltip)`
  width: 468px;
  max-width: 500px;
  height: 308px;
  padding: 12px 16px !important;
`;

export const StyledError = styled.p<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.text.error};
  font-family: Speedee;
  font-size: 10px;
  font-weight: 300;
  margin-left: 2px;
  height: 28px;
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  width: 18px;
  fill: #fff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  transition: all ${typography.transition}s ease;

  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledViewImage = styled(StyledIcon)`
  width: 24px;
`;

export const StyledImageButtonText = styled(NewButtonText)``;

export const DoeOfferTemplateSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SelectionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CancelButton = styled(NewButtonText)`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  border: 1px solid #adadad;
  background: var(--Brand-Colors-White, #fff);
  color: ${newGlobalTheme.colors.text.primary};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Speedee;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  letter-spacing: -0.187px;
`;

export const StyledGoldButton = styled(NewButtonContained)`
  height: 52px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  border: 1px solid #c08b00;
  background: var(--Brand-Colors-McDonalds-Gold, #ffbc0d);
  color: ${newGlobalTheme.colors.text.primary};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Speedee;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  letter-spacing: -0.187px;
  &:disabled {
    border: none;
    background: ${newGlobalTheme.colors.global.disabledGold};
    color: ${newGlobalTheme.colors.text.disabledSave};
  }
  :hover {
    border: 1px solid var(--Accent-Gold, #c08b00);
    background: var(--McDonalds-Gold, #ffbc0d);
  }
`;

export const StyledGoBackButton = styled(ButtonContainedCancel)`
  height: 52px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  border: 0px;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Speedee;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  letter-spacing: -0.187px;
  &:disabled {
    border: none;
    background: ${newGlobalTheme.colors.button.disabledSave};
    color: ${newGlobalTheme.colors.text.disabledSave};
  }
  :hover {
  }
`;

export const StyledWhiteButton = styled(NewButtonContained)`
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  height: 32px;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid ${newGlobalTheme.colors.text.disabledSave};
  background: #fff;

  :hover {
    background-color: inherit;
  }

  &:disabled {
    color: ${newGlobalTheme.colors.text.disabledSave};
    border-radius: 5px;
    border: 1px solid ${newGlobalTheme.colors.status.archived};
    background: var(--White, #fff);
  }
`;

export const DoeOfferFormTextField = styled(OfferFormTextField)`
    margin-bottom: 0;
`;

export const DoeOfferInfoWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    ${({ disabled }) =>
      disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}` : `color: ${newGlobalTheme.colors.text.primary}`};
  }
  span {
    ${({ disabled }) =>
      disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}` : `color: ${newGlobalTheme.colors.text.primary}`};
  }
`;

export const StyledTitle = styled.label`
  color: var(--Secondary-Colors-Black, #292929);
  font-feature-settings: 'liga' off, 'clig' off;

  /* Headline/H6 - 14px Bold */
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.15px;
`;

export const StyledLabel = styled.label`
  color: var(--Secondary-Colors-Black, #6f6f6f);
`;

export const StyledTitleWrapper = styled.div``;

export const LineWrapper = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => (gap ? `${gap}px` : '16px')};
  align-items: center;
  height: 36px;
`;

export const LineWrapperSpaceBetween = styled.div<{ gap?: number; values?: Product[] }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  gap: 16px;
`;

export const AdditionalInfoRow = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  height: ;
`;

export const StyledButtonProductSelectionText = styled(NewButtonText)<{ disabled: boolean }>`
  font-weight: 700;
  line-height: 22.834px;
  letter-spacing: -0.42px;
  font-size: 14px;
  padding: 8px 0px;
  color: ${({ disabled }) =>
    disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.global.textSubheading};
`;

export const SaveButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  div[role='alert'] {
    margin-top: 44px;
    color: ${newGlobalTheme.colors.text.error} !important;
  }
`;

export const ImageRowContainer = styled.div<{ imageSelected?: string }>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: ${({ imageSelected }) => (imageSelected ? '277px' : '251px')};
`;

export const StyledSelectImageButton = styled(NewButtonContained)`
  color: ${newGlobalTheme.colors.text.primary};
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${newGlobalTheme.colors.button.border.secondary};
  background: ${newGlobalTheme.colors.global.brandMain};
  font-size: 14px;
  :hover {
    background: ${newGlobalTheme.colors.global.brandMain};
  }
`;

export const ImageTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
`;

export const DoeOfferFormSection = styled(StyledFormSection)`
  gap: 36px;
  margin-bottom: 0;
`;

export const DoeOfferFormSubsection = styled(DoeOfferFormSection)`
  gap: 16px;
`;

export const DoeOfferFormSectionWrapper = styled(StyledFormSection)`
  gap: 36px;
  display: flex;
  flex-direction: column;
`;

export const TermsConditionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  div[role='alert'] {
    color: ${newGlobalTheme.colors.global.error};
    font-size: 10px;
  }
`;

export const CancelTooltip = styled(Tooltip)`
  button {
    font-size: 14px;
  }
`;

export const ContentTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SelectImageSecondaryButton = styled(NewButtonText)`
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.42px;
  justify-content: flex-start;
`;

export const ImageColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledBanner = styled(Banner)<StyledWithThemeProps & { type?: string }>`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Secondary-Colors-Light-Grey, #d6d6d6);
  border-left: 6px solid ${({ type }) => getBannerColor(type)};
  background: var(--Secondary-Colors-Ivory, #f9f9f9);
  &.warning-icon {
    svg {
      fill: #ffd90d;
    }
  }
`;

export const StyledBannerContent = styled.div<StyledWithThemeProps>`
  font-size: ${typography.fontSize}px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledSupportingText = styled.span`
    font-size: ${newTypography.smallFontSize}px;
`;

export const StyledReplaceImage = styled.div<StyledWithThemeProps>`
  display: flex;
  width: 188px;
  height: 188px;
  max-width: 320px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--Secondary-Colors-Grey, #adadad);

  &:has(> img) {
    height: min-content;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const StyledLine = styled.div`
  width: 188px;
  height: 1px;
  background: #d6d6d6;
`;

export const StyledList = styled(List)`
  list-style-type: disc;
  padding-left: 16px;
`;
// width: 100%;
// max-width: 360px;
// background-color: ${newGlobalTheme.colors.global.background};
// border-radius: 8px;
// box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// padding: 16px;

export const StyledListItem = styled(ListItem)`
  font-weight: 700;
  display: list-item;
  padding: 4px 0 4px 0;
`;

// padding: ${typography.unit * 3}px;
export const StyledInputFiles = styled(NewButtonContained)`
  height: auto;
  font-weight: normal;
  color: ${newGlobalTheme.colors.text.primary};
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid var(--Secondary-Colors-Grey, #adadad);
  background: var(--Brand-Colors-White, #fff);
  gap: 4px;
  &:hover {
    background-color: var(--Brand-Colors-White, #e7e7e7);
  }
`;

export const StyledBoldText = styled.p<StyledWithThemeProps>`
    color: ${newGlobalTheme.colors.text.primary};
    font-feature-settings: 'liga' off, 'clig' off;

    /* Headline/H4 -  18px Bold */
    font-family: ${newTypography.primaryFont}
    font-size: ${newTypography.fontSize}px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.15px;
    padding-bottom: ${newTypography.unit * 21}px;
`;
