import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import TextModalTitle from 'components/shared/text/textModalTitle/NewTextModalTitle';
import { useOperationCounter } from 'app/apollo/operationCounter';
import difference from 'lodash/difference';
import Backdrop from '../backdrop/Backdrop';
import { ModalProps } from './Modal.const';
import { Container, Content, Loading, MainTitle, ModalHeader } from './Campaign.style';
import { Loader } from '../loader';
import ButtonGroup from 'pages/campaigns/campaignManagement/components/campaignForm/components/buttonGroup/ButtonGroup';
import { FormMode } from 'utils/types';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import ProgressLoader from 'pages/shared/progressIndicator/ProgressLoader';
import { TitleWithProgress } from './NewModal.style';
import { handleKeyDownModal } from 'utils/modal';

const CampaignModal = ({
  children,
  mode,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  offerSource = OfferSource.VCE,
  inProgress = false,
  onHover,
  message,
  ...rest
}: ModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();

  const headerRef = useRef(null); 
  useEffect((): (() => void) => {
    // Focus on the header when the modal opens
    if (headerRef.current) {
      headerRef.current.focus();
    }
    // add event listener to prevent focus from leaving the modal 
    const keyDownListener : any = (event: KeyboardEvent) => handleKeyDownModal(event, 'modalPart');
    document.addEventListener('keydown', keyDownListener);
    document.body.style.overflow = 'hidden';
    return () => {
      document.removeEventListener('keydown', keyDownListener)
      document.body.style.overflow = 'unset';
    };
  }, [mode]);

  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <Container className={className} data-automation-id="modal" {...rest}>
        {withLoader && (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
        <ModalHeader ref={headerRef} tabIndex={0}>
          <MainTitle subtitle={subtitle}>
            <TitleWithProgress>
              {title && <TextModalTitle text={title} />}
              {inProgress && <ProgressLoader size={35} message={message} onHover={onHover} />}
            </TitleWithProgress>
            {isLocked && <LockIcon />}
            {![FormMode.New, FormMode.Duplicate, FormMode.Edit].includes(mode) && (
              <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                <ButtonGroup mode={mode} offerSource={offerSource} inProgress={inProgress} />
              </RoleGuard>
            )}
          </MainTitle>
          {subtitle ?? null}
        </ModalHeader>
        <Content data-automation-id="modal-content">{children}</Content>
      </Container>
    </>,
    document.querySelector('#modal'),
  );
};

export default CampaignModal;
