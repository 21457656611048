import { gql } from '@apollo/client';

const doeOfferFormGqls = {
  queries: {
    getBulkEditOfferInfo: gql`
      query getBulkEditOfferInfo($data: BulkEditInfoInput!) {
        getBulkEditOfferInfo(data: $data) {
          offerCount
          campaignCount
          posTitle
          subtitle
          description
          pointOfDistribution
          termId
          imageId
          tagIds
          emptyFields
        }
      }
    `,
  },
};

export default doeOfferFormGqls;