import React, { useEffect, useMemo, useState } from 'react';
import {
  BannerWrapper,
  ColumnFlexContainer,
  DoeCampaignBulkEditModal,
  ModalContent,
  RowFlexContainer,
  Step1Container,
  Step2Container,
  Step2Content,
  Step2SideBar,
  Step3Container,
  StyledLabel,
  StyledList,
  StyledListItem,
  StyledBanner,
  StyledCheckbox,
  StyledDatePicker,
  StyledMultipleChoice,
  StyledNewButtonText,
  StyledNewTextField,
  StyledTimePicker,
  StyledSelectPaginate,
  StyledSupportingText,
  StyledFieldSection,
  StyledDisabledLabel,
} from 'pages/campaigns/doeCampaigns/doeCampaignBulkEdit/DoeCampaignBulkEdit.style';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { MessageType } from 'components/shared/notifications/notifications';
import { useForm } from 'react-hook-form';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { FormMode, SetOfEntity } from 'utils/types';
import { SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { getTotalSelectedItems } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { useQuery } from '@apollo/client';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { FetchPolicies } from 'utils/types/common';
import { getMarketDatetime, getCalenderWeekDays } from 'utils/date';
import { bulkDoeCampaignUpdate, fetchBulkEditCampaignInfo } from 'utils/api/doeCampaigns';
import { Location, LocationSet, LocationSetType, RestaurantEligibility } from 'utils/types/locations';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import {
  BulkEditFieldStatus,
  DoeCampaignBulkEditProps,
  DoeCampaignFormData,
  VALID_DAYS,
} from './DoeCampaignBulkEdit.const';
import { useOfferBanksQuery } from 'hooks/offer-banks';
import { useBaseOfferRecsQuery } from 'hooks/base-offer-recs';
import { getAllItemsRecursively } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export interface FormData {
  offerBankId?: string;
  offerGroupId?: string;
  description?: string;
  endDate?: Date;
  endTime?: Date;
  validDays?: string[];
  validityStartTime?: Date;
  validityEndTime?: Date;
  locations?: SetItemsSelectionFormState;
  isNationwide?: boolean;
  campaignCount?: number;
}

interface BaseOfferRec {
  baseOffer: {
    versions: {
      isLatest: boolean;
      campaigns: {
        id: bigint;
      }[];
    }[];
  };
}

enum DoeCampaignBulkEditSteps {
  'SelectBankAndGroup' = 1,
  'EditCampaigns' = 2,
  'Confirm' = 3,
}

interface DoeCampaignFormReduxData {
  campaign: DoeCampaignFormData;
  step: number;
  formData: FormData;
}

const DoeCampaignBulkEdit = ({ step: initialStep, initialLocationCount, formData }: DoeCampaignBulkEditProps) => {
  const reduxData: Readonly<DoeCampaignFormReduxData> = useSelector(
    (state: any) => state.modals.data,
  ) as DoeCampaignFormReduxData;

  const { filters = {} } = useSelector((state: any) => state.doeCampaignsPage?.campaignsState || {});

  const form = useForm({
    mode: 'onBlur',
    defaultValues:
      formData ||
      ({
        offerBankId: reduxData?.formData?.offerBankId,
        offerGroupId: reduxData?.formData?.offerGroupId,
        validDays: [],
        isNationwide: false,
        locations: reduxData?.formData?.locations as SetItemsSelectionFormState,
        campaignCount: 0,
      } as any),
  });

  const [step, setStep] = useState(reduxData?.step ?? (initialStep || 1));
  const [bulkEditCampaignInfo, setBulkEditCampaignInfo] = useState(form.getValues());
  const [locationCount, setLocationCount] = useState(initialLocationCount ?? 0);

  const { loadOptions: loadOfferBankOptions } = useOfferBanksQuery(true);

  const { loadOptions: loadOfferGroupOptions } = useBaseOfferRecsQuery(true, [form.getValues('offerBankId')]);

  const [offerBankOpen, setOfferBankOpen] = useState(false);
  const [offerGroupKey, setOfferGroupKey] = useState(false);
  const [offerGroupOpen, setOfferGroupOpen] = useState(false);

  const [makeNationalState, setMakeNationalState] = useState(form.getValues('isNationwide'));
  const formErrors = form.formState.errors as Record<string, any>;
  const [displayLocationError, setDisplayLocationError] = useState(!!formErrors?.locations);

  useEffect(() => {
    if (filters?.offerBank?.length === 1) {
      form.setValue('offerBankId', filters.offerBank[0]);
    }
    if (filters?.offerBank?.length === 1 && filters?.offerGroup?.length === 1) {
      form.setValue('offerGroupId', Number(filters.offerGroup[0]));
    }
  }, [filters, form]);

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === 'validityEndTime') {
        form.trigger('validityStartTime');
      } else if (name === 'locations') {
        form.trigger('isNationwide');
      } else if (name === 'endTime') {
        form.trigger('endDate');
      } else if (name === 'endDate') {
        form.trigger('endTime');
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  useEffect(() => {
    setOfferGroupKey((prev) => !prev);
  }, [offerGroupOpen]);

  const {
    config: { calendarWeekStartsOn, endTimezone },
  } = useSelector(marketConfig);
  const calendarWeekDays = getCalenderWeekDays(VALID_DAYS, calendarWeekStartsOn);

  const getAllLocationSets = useQuery(locationSetsGqls.queries.getAllLocationSets, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: { data: { filters: {} } },
  });

  const totalSelectedLocations = useMemo(() => {
    return getTotalSelectedItems(
      getAllLocationSets?.data?.getLocationSets?.items,
      [],
      [],
      [],
      SetOfEntity.Locations,
      false,
    );
  }, [getAllLocationSets?.data?.getLocationSets?.items]);

  const handleSubmitBulkEdit = async () => {
    const formData: any = form.getValues();

    const prepareRestaurantEligibility = (): RestaurantEligibility => {
      if (formData.isNationwide) {
        return {
          excludeRestaurantGroups: [],
          excludeRestaurants: [],
          isNationwide: formData.isNationwide,
          restaurants: [],
          restaurantGroups: [],
        };
      }

      if (!formData.locations) {
        return null;
      }

      const getRestaurantCodes = (items: Location[]) => items.map((item) => item.code);
      const getRestaurantGroups = (items: LocationSet[]) =>
        items.map((item) => ({
          id: item.externalId,
          type: item.custom ? LocationSetType.Custom : LocationSetType.Hierarchy,
        }));

      const selectedItemsById = Object.values(formData.locations?.selectedItemsById ?? {}) as Location[];
      const selectedItemSetsById = Object.values(formData.locations?.selectedItemSetsById ?? {}) as LocationSet[];
      const excludedItemsById = Object.values(formData.locations?.excludedItemsById ?? {}) as Location[];
      const excludedItemSetsById = Object.values(formData.locations?.excludedItemSetsById ?? {}) as LocationSet[];
      return {
        restaurants: getRestaurantCodes(selectedItemsById),
        restaurantGroups: getRestaurantGroups(selectedItemSetsById),
        excludeRestaurants: getRestaurantCodes(excludedItemsById),
        excludeRestaurantGroups: getRestaurantGroups(excludedItemSetsById),
      };
    };

    const bulkCampaignUpdateDto = {
      offerBankId: formData.offerBankId,
      offerGroupId: formData.offerGroupId,
      description: formData.description,
      restaurantEligibility: prepareRestaurantEligibility(),
      schedule: {
        campaignEnd: getCampaignEnd(formData.endDate, formData.endTime),
        validityStartTime: formData.validityStartTime?.toLocaleTimeString('en', { hour12: false }),
        validityEndTime: formData.validityEndTime?.toLocaleTimeString('en', { hour12: false }),
        validDays: formData.validDays,
      },
    };

    try {
      bulkDoeCampaignUpdate(bulkCampaignUpdateDto).then(() => {}); // Don't wait
      showToast(MessageType.Info, 'Your campaign bulk edit is processing.');
      store.dispatch(closeModal());
    } catch (_error) {
      showToast(MessageType.Error, 'Error processing your campaign bulk edit.');
    }
  };

  const nextButtonAction = form.handleSubmit(async () => {
    const errors = new Set(Object.keys(form.formState.errors));
    if (errors.size > 0) {
      return;
    }

    setDisplayLocationError(false);
    switch (step) {
      case DoeCampaignBulkEditSteps.SelectBankAndGroup:
        const offerBankId = form.getValues('offerBankId');
        const offerGroupId = form.getValues('offerGroupId');
        await form.trigger(['offerBankId', 'offerGroupId']);
        if (form.formState.errors.offerBankId || form.formState.errors.offerGroupId) {
          showToast(MessageType.Error, 'Please select an offer bank and group.');
          return;
        }
        try {
          setBulkEditCampaignInfo(await getBulkEditCampaignInfo(offerBankId, offerGroupId));
          setStep(step + 1);
        } catch {
          showToast(MessageType.Error, 'Failed to get campaign information.');
        }
        break;
      case DoeCampaignBulkEditSteps.EditCampaigns:
        setStep(step + 1);
        break;
      case DoeCampaignBulkEditSteps.Confirm:
        if (form.getValues('isNationwide')) {
          form.setValue('locations', undefined);
        }
        await handleSubmitBulkEdit();
        break;
      default:
        break;
    }
  });

  const getBulkEditCampaignInfo = async (offerBankId: string, offerGroupId: string) => {
    let bulkEditCampaignInfo = await fetchBulkEditCampaignInfo(offerBankId, offerGroupId);
    const emptyFieldsSet = new Set(bulkEditCampaignInfo.emptyFields);

    if (bulkEditCampaignInfo.campaignCount === 0) {
      throw new Error('No campaigns found.');
    }

    const parseTimeString = (timeString: string): Date => {
      const [hours, minutes] = timeString.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    };

    let filteredLocations: LocationSet[] = [];

    if (bulkEditCampaignInfo.zoneIds) {
      const uniqueZoneIds = new Set<number>(bulkEditCampaignInfo.zoneIds);
      filteredLocations = getAllLocationSets.data.getLocationSets.items.filter((locationSet: LocationSet) => {
        return uniqueZoneIds.has(locationSet.id);
      });
    } else {
      filteredLocations = null;
    }

    const locations: SetItemsSelectionFormState = filteredLocations
      ? {
          name: '',
          description: '',
          selectedItemsById: (
            getAllItemsRecursively(filteredLocations, SetOfEntity.Locations, true) as Location[]
          ).reduce((acc, location) => ({ ...acc, [location.id]: location }), {}),
          selectedItemSetsById: filteredLocations.reduce(
            (acc, locationSet) => ({ ...acc, [locationSet.id]: locationSet }),
            {},
          ),
          excludedItemsById: {},
          excludedItemSetsById: {},
        }
      : null;

    const campaignEndDate = bulkEditCampaignInfo.campaignEnd ? new Date(bulkEditCampaignInfo.campaignEnd) : undefined;
    const campaignEndTime = bulkEditCampaignInfo.validityEndTime
      ? parseTimeString(bulkEditCampaignInfo.validityEndTime)
      : undefined;
    const campaignStartTime = bulkEditCampaignInfo.validityStartTime
      ? parseTimeString(bulkEditCampaignInfo.validityStartTime)
      : undefined;

    form.setValue('description', bulkEditCampaignInfo.description);
    form.setValue('endDate', campaignEndDate);
    form.setValue('endTime', campaignEndDate);
    form.setValue('validityStartTime', campaignStartTime);
    form.setValue('validityEndTime', campaignEndTime);
    form.setValue('validDays', bulkEditCampaignInfo.validDays);
    form.setValue('isNationwide', bulkEditCampaignInfo.isNationwide);
    form.setValue('campaignCount', bulkEditCampaignInfo.campaignCount);
    form.setValue('locations', locations);
    setMakeNationalState(bulkEditCampaignInfo.isNationwide);
    setLocationCount(locations ? Object.keys(locations.selectedItemsById).length : 0);

    return { ...form.getValues(), emptyFields: emptyFieldsSet };
  };

  const hasMixedValuesForField = (field: string) => {
    if (field === 'isNationwide') {
      return bulkEditCampaignInfo?.isNationwide === null && !fieldIsEmpty('isNationwide');
    }
    return !bulkEditCampaignInfo[field] && !fieldIsEmpty(field);
  };

  const fieldIsEmpty = (field: string) => {
    return bulkEditCampaignInfo.emptyFields.has(field);
  };

  const getInputPlaceholderText = (field: string) => {
    return hasMixedValuesForField(field) ? BulkEditFieldStatus.MIXED_VALUES : 'Enter';
  };

  const getInputSupportingText = (field: string) => {
    if (hasMixedValuesForField(field)) {
      return <StyledSupportingText>{BulkEditFieldStatus.EDITED_INDIVIDUALLY}</StyledSupportingText>;
    }
    return (
      <StyledSupportingText>
        {bulkEditCampaignInfo[field] ? BulkEditFieldStatus.SAME_VALUES : BulkEditFieldStatus.EMPTY_VALUES}
      </StyledSupportingText>
    );
  };

  const openDoeCampaignModal = () => {
    const locations = form.getValues('locations');

    store.dispatch(
      openModal({
        modal: Modals.DoeCampaignModal,
        props: {
          step: step,
          initialLocationCount: locations
            ? getTotalSelectedItems(
                Object.values(locations.selectedItemSetsById),
                Object.values(locations.selectedItemsById),
                Object.values(locations.excludedItemSetsById),
                Object.values(locations.excludedItemsById),
                SetOfEntity.Locations,
                false,
              )
            : undefined,
          formData: form.getValues(),
        },
      }),
    );
  };

  const openLocationSetModal = (mode: FormMode) => {
    let locationSet;
    if (mode === FormMode.View) {
      locationSet = { sets: getAllLocationSets.data.getLocationSets.items };
    } else if (mode === FormMode.Select) {
      const formLocations = form.getValues('locations');
      const campaignLocationSet = formLocations
        ? Object.values(formLocations.selectedItemSetsById)
        : bulkEditCampaignInfo.campaignLocations;
      const locations = formLocations
        ? Object.values(formLocations?.selectedItemsById)
        : getAllItemsRecursively(campaignLocationSet, SetOfEntity.Locations, true);
      if (locations) {
        locationSet = {
          locations: locations,
          sets: campaignLocationSet,
          excludedItems: formLocations ? Object.values(formLocations.excludedItemsById) : [],
          excludedSets: formLocations ? Object.values(formLocations.excludedItemSetsById) : [],
        };
      }
    } else {
      locationSet = { sets: getAllLocationSets.data.getLocationSets.items };
    }

    store.dispatch(
      openModal({
        modal: Modals.LocationSetModal,
        props: {
          mode: mode,
          locationSet: locationSet,
          onSave: (locations: SetItemsSelectionFormState) => {
            form.setValue('locations', locations);
            form.setValue('isNationwide', false);
            openDoeCampaignModal();
          },
          onCancel: openDoeCampaignModal,
        },
      }),
    );
  };

  const endDateTimeIsValid = (endDateInput: Date, endTimeInput: Date) => {
    return getCampaignEnd(endDateInput, endTimeInput).getTime() > getMarketDatetime(endTimezone).getTime();
  };

  const getCampaignEnd = (endDateInput: Date, endTimeInput: Date) => {
    return new Date(
      endDateInput.getFullYear(),
      endDateInput.getMonth(),
      endDateInput.getDate(),
      endTimeInput.getHours(),
      endTimeInput.getMinutes(),
      endTimeInput.getSeconds(),
      endTimeInput.getMilliseconds(),
    );
  };

  const customSelectPaginateStyles = {
    control: {
      borderBottom: `1px solid ${newGlobalTheme.colors.global.borderColorboxes}`,
    },
    singleValue: {
      fontSize: '18px',
      fontWeight: '400',
    },
    input: {
      fontSize: '18px',
      fontWeight: '400',
    },
  };

  const getInitialOfferBank = () => (form.getValues('offerBankId') ? [form.getValues('offerBankId')] : []);
  const getInitialOfferGroup = () => (form.getValues('offerGroupId') ? [form.getValues('offerGroupId')] : []);

  return (
    <DoeCampaignBulkEditModal
      title="Bulk Edit - Campaigns"
      showCloseIcon
      backButtonAction={step < 2 ? undefined : () => setStep(step - 1)}
      nextButtonAction={nextButtonAction}
      nextButtonLabel={step === 1 ? 'Next' : step === 2 ? 'Submit' : 'Confirm'}
    >
      <ModalContent>
        {(() => {
          switch (step) {
            case DoeCampaignBulkEditSteps.SelectBankAndGroup:
              return (
                <Step1Container>
                  <h4>Select a Bank and Group to bulk edit</h4>
                  <h5>*Required fields</h5>
                  <StyledSelectPaginate
                    control={form.control}
                    placeholder=""
                    name="offerBankId"
                    label="Offer Bank"
                    onChange={(selectedItem) => {
                      form.setValue('offerBankId', selectedItem?.id || null);
                      form.setValue('offerGroupId', null);
                      form.trigger('offerBankId');
                    }}
                    initialSelectedItems={getInitialOfferBank()}
                    defaultValue={form.getValues('offerBankId')}
                    reset
                    isOpen={offerBankOpen}
                    setIsOpen={setOfferBankOpen}
                    loadOptions={loadOfferBankOptions}
                    customStyles={customSelectPaginateStyles}
                    validation={{ required: 'Required' }}
                    errors={form.formState.errors}
                  />
                  <StyledSelectPaginate
                    key={`campaignBulkEdit_${offerGroupKey}`}
                    control={form.control}
                    placeholder=""
                    name="offerGroupId"
                    label="Offer Group"
                    onChange={(selectedItem) => {
                      const selectedOfferGroup = selectedItem ? Number(selectedItem?.id) : null;
                      form.setValue('offerGroupId', selectedOfferGroup);
                      form.trigger('offerGroupId');
                    }}
                    disabled={!form.getValues('offerBankId')}
                    initialSelectedItems={getInitialOfferGroup()}
                    defaultValue={form.getValues('offerBankId')}
                    reset
                    isOpen={offerGroupOpen}
                    setIsOpen={setOfferGroupOpen}
                    loadOptions={loadOfferGroupOptions}
                    customStyles={customSelectPaginateStyles}
                    validation={{ required: 'Required' }}
                    errors={form.formState.errors}
                  />
                </Step1Container>
              );
            case DoeCampaignBulkEditSteps.EditCampaigns:
              return (
                <Step2Container>
                  <Step2SideBar>
                    <h4>Selection summary</h4>
                    <div>
                      <h5>Offer Bank</h5>
                      <label>{form.getValues('offerBankId')}</label>
                    </div>
                    <div>
                      <h5>Offer Group</h5>
                      <label>{form.getValues('offerGroupId')}</label>
                    </div>
                  </Step2SideBar>
                  <Step2Content>
                    <h4>Options for bulk editing</h4>
                    <h5>*Required fields</h5>
                    <StyledFieldSection>
                      <StyledNewTextField
                        label="Description"
                        labelIsHorizontal
                        name="description"
                        value={form.getValues('description')}
                        disabled={hasMixedValuesForField('description')}
                        placeholder={getInputPlaceholderText('description')}
                        register={form.register}
                        shouldUnregister={false}
                        validation={
                          !hasMixedValuesForField('description')
                            ? {
                                required: 'Required',
                                maxLength: { value: 4000, message: 'Up to 4000 characters' },
                              }
                            : null
                        }
                        errors={form.formState.errors}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          event.target.style.height = ''; // Reset
                          event.target.style.height = event.target.scrollHeight + 'px'; // Grow
                        }}
                      />
                      {getInputSupportingText('description')}
                    </StyledFieldSection>
                    <StyledFieldSection>
                      <StyledDatePicker
                        control={form.control}
                        name="endDate"
                        label="End date"
                        labelIsHorizontal
                        value={form.getValues('endDate')}
                        validation={
                          !hasMixedValuesForField('endDate')
                            ? {
                                required: 'Required',
                                validate: {
                                  inputValidity: (endDate: Date) => {
                                    const endTime = form.getValues('endTime');
                                    if (!endTime) {
                                      return;
                                    }
                                    if (!endDateTimeIsValid(endDate, endTime)) {
                                      return 'End date and time are invalid or in the past';
                                    }
                                  },
                                },
                              }
                            : null
                        }
                        isDisabled={hasMixedValuesForField('endDate')}
                        disabled={hasMixedValuesForField('endDate')}
                        errors={form.formState.errors}
                      />
                      {getInputSupportingText('endDate')}
                    </StyledFieldSection>
                    <StyledFieldSection>
                      <StyledTimePicker
                        control={form.control}
                        name="endTime"
                        label="End time"
                        labelIsHorizontal
                        disabled={hasMixedValuesForField('endTime')}
                        defaultValue={form.getValues('endTime')}
                        errors={form.formState.errors}
                        validation={
                          !hasMixedValuesForField('endTime')
                            ? {
                                required: 'Required',
                                validate: {
                                  inputValidity: (endTime: Date) => {
                                    const endDate = form.getValues('endDate');
                                    if (!endDate) {
                                      return;
                                    }
                                    if (!endDateTimeIsValid(endDate, endTime)) {
                                      return 'End date and time are invalid or in the past';
                                    }
                                  },
                                },
                              }
                            : null
                        }
                      />
                      {getInputSupportingText('endDate')}
                    </StyledFieldSection>
                    <StyledFieldSection>
                      <StyledMultipleChoice
                        control={form.control}
                        items={calendarWeekDays}
                        label="Days valid*"
                        labelIsHorizontal
                        itemLabelType="name"
                        name="validDays"
                        value={form.getValues('validDays') || []}
                        validation={
                          !hasMixedValuesForField('validDays')
                            ? { validate: (days: string[]) => (days.length > 0 ? undefined : 'Required') }
                            : null
                        }
                        errors={form.formState.errors}
                        disabled={hasMixedValuesForField('validDays')}
                      />
                      {getInputSupportingText('validDays')}
                    </StyledFieldSection>
                    <StyledFieldSection>
                      <StyledTimePicker
                        control={form.control}
                        name="validityStartTime"
                        label="Validity start time"
                        labelIsHorizontal
                        disabled={hasMixedValuesForField('validityStartTime')}
                        defaultValue={form.getValues('validityStartTime')}
                        validation={
                          !hasMixedValuesForField('validityStartTime')
                            ? {
                                required: 'Required',
                                validate: {
                                  minDate: (validityStartTime: Date) => {
                                    const validityEndTime = form.getValues('validityEndTime');
                                    if (
                                      !validityStartTime ||
                                      !validityEndTime ||
                                      isNaN(validityStartTime.getTime()) ||
                                      isNaN(validityEndTime.getTime())
                                    ) {
                                      return;
                                    }
                                    if (validityStartTime > validityEndTime) {
                                      return "Start time can't be later than end time";
                                    }
                                  },
                                },
                              }
                            : null
                        }
                        errors={form.formState.errors}
                      />
                      {getInputSupportingText('validityStartTime')}
                    </StyledFieldSection>
                    <StyledFieldSection>
                      <StyledTimePicker
                        control={form.control}
                        name="validityEndTime"
                        label="Validity end time"
                        labelIsHorizontal
                        disabled={hasMixedValuesForField('validityEndTime')}
                        defaultValue={form.getValues('validityEndTime')}
                        validation={hasMixedValuesForField('validityEndTime') ? { required: 'Required' } : null}
                        errors={form.formState.errors}
                      />
                      {getInputSupportingText('validityEndTime')}
                    </StyledFieldSection>
                    <RowFlexContainer>
                      {!hasMixedValuesForField('isNationwide') && !hasMixedValuesForField('locations') ? (
                        <>
                          <ColumnFlexContainer>
                            <label>Locations*</label>
                            {makeNationalState === false && (
                              <>
                                <StyledNewButtonText onClick={() => openLocationSetModal(FormMode.Select)}>
                                  {locationCount ? `Edit ${locationCount} selected locations` : 'Select locations'}
                                </StyledNewButtonText>
                                {displayLocationError && (
                                  <div role="alert">
                                    <span>Required</span>
                                  </div>
                                )}
                              </>
                            )}
                            {makeNationalState === true && (
                              <StyledNewButtonText onClick={() => openLocationSetModal(FormMode.View)}>
                                {`View ${totalSelectedLocations} selected locations`}
                              </StyledNewButtonText>
                            )}
                            <input
                              type="hidden"
                              {...form.register(`locations`, {
                                validate: () => {
                                  const isValid = form.getValues('isNationwide') === true || locationCount > 0;
                                  setDisplayLocationError(!isValid);
                                  return isValid;
                                },
                              })}
                            />
                          </ColumnFlexContainer>
                          <ColumnFlexContainer>
                            <StyledCheckbox
                              label="Make National"
                              onClick={() => {
                                setMakeNationalState(!form.getValues('isNationwide'));
                                form.setValue('isNationwide', !form.getValues('isNationwide'));
                                form.trigger('isNationwide');
                              }}
                              checked={form.getValues('isNationwide')}
                            />
                          </ColumnFlexContainer>
                        </>
                      ) : (
                        <>
                          <ColumnFlexContainer>
                            <StyledDisabledLabel>Locations*</StyledDisabledLabel>
                            <StyledNewButtonText onClick={() => openLocationSetModal(FormMode.Select)} disabled={true}>
                              {locationCount ? `Edit ${locationCount} selected locations` : 'Select locations'}
                            </StyledNewButtonText>
                          </ColumnFlexContainer>
                          <ColumnFlexContainer>
                            <StyledCheckbox label="Make National" disabled={true} />
                          </ColumnFlexContainer>
                        </>
                      )}
                    </RowFlexContainer>
                    {!hasMixedValuesForField('locations') && !hasMixedValuesForField('isNationwide') ? (
                      <StyledSupportingText>{BulkEditFieldStatus.SAME_VALUES}</StyledSupportingText>
                    ) : (
                      <StyledSupportingText>{BulkEditFieldStatus.EDITED_INDIVIDUALLY}</StyledSupportingText>
                    )}
                  </Step2Content>
                </Step2Container>
              );
            case DoeCampaignBulkEditSteps.Confirm:
              return (
                <Step3Container>
                  <BannerWrapper>
                    <StyledBanner type={MessageType.Warning}>You are about to confirm a bulk action!</StyledBanner>
                  </BannerWrapper>
                  <StyledLabel>Your changes will impact:</StyledLabel>
                  <StyledList>
                    <StyledListItem>{form.getValues('campaignCount')} campaigns</StyledListItem>
                  </StyledList>
                </Step3Container>
              );
            default:
              return null;
          }
        })()}
      </ModalContent>
    </DoeCampaignBulkEditModal>
  );
};

export default DoeCampaignBulkEdit;
