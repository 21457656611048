import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledNotificationWithTheme } from '../notifications';
import DoeMessage from '../message/DoeMessage';
import { Icon } from '../../icon';

export const DoeBannerContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const DoeBannerMessageContainer = styled.div`
  display: flex;
  padding: 16px 24px 16px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background: #f9f9f9;
`;

export const DoeBannerErrorTypeLine = styled.div<StyledNotificationWithTheme>`
  width: 6px;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${({ type, theme }) => theme.colors.notifications.primary[type]};
`;

export const StyledMessage = styled(DoeMessage)<StyledNotificationWithTheme>`
  border-radius: ${typography.borderRadius}px;
`;

export const StyledIconMessage = styled(Icon)<StyledNotificationWithTheme>`
  width: 54px;
  height: 54px;
  fill: ${({ type, theme }) => theme.colors.notifications.background[type]};
`;
