import { GameNotification } from './games';
import { UserNameNotification } from './users';

export enum NotificationEntityType {
  Product = 'product',
  Location = 'location',
  Offer = 'offer',
  Campaign = 'campaign',
  User = 'user',
  BulkOffer = 'bulkOffer',
  Image = 'image',
  Term = 'term',
}

export enum NotificationActionType {
  NoProducts = 'noProducts',
  ProductUpdate = 'productUpdate',
  NoLocations = 'noLocations',
  LocationUpdate = 'locationUpdate',
  NoRedemptions = 'noRedemptions',
  CampaignErrors = 'campaignErrors',
  CampaignApprovalRequired = 'campaignApprovalRequired',
  NewUser = 'newUser',
  UserUpdate = 'userUpdate',
  GameLocationUpdateFailed = 'gameLocationUpdateFailed',
  GameLocationUpdateSuccess = 'gameLocationUpdateSuccess',
  BulkRevoke = 'bulkRevoke',
  BulkStopAssociation = 'bulkStopAssociation',
  BulkCampaignCreation = 'bulkCampaignCreation',
  BulkSubmissionForApproval = 'bulkSubmissionForApproval',
  BulkCampaignApproval = 'bulkCampaignApproval',
  BulkOfferEditSuccess = 'bulkOfferEditSuccess',
  BulkOfferEditPartialSuccess = 'bulkOfferEditPartialSuccess',
  BulkOfferEditFailed = 'bulkOfferEditFailed',
  BulkCampaignEditSuccess = 'bulkCampaignEditSuccess',
  BulkCampaignEditPartialSuccess = 'bulkCampaignEditPartialSuccess',
  BulkCampaignEditFailure = 'bulkCampaignEditFailure',
  ImageEdit = 'imageEdit',
  TermEdit = 'termEdit',
}

export const userNotificationFilters = [
  NotificationActionType.GameLocationUpdateFailed,
  NotificationActionType.GameLocationUpdateSuccess,
  NotificationActionType.LocationUpdate,
  NotificationActionType.ProductUpdate,
  NotificationActionType.NoLocations,
  NotificationActionType.NoProducts,
  NotificationActionType.BulkCampaignCreation,
  NotificationActionType.BulkSubmissionForApproval,
  NotificationActionType.BulkCampaignApproval,
  NotificationActionType.BulkRevoke,
  NotificationActionType.BulkStopAssociation,
  NotificationActionType.BulkOfferEditSuccess,
  NotificationActionType.BulkOfferEditPartialSuccess,
  NotificationActionType.BulkOfferEditFailed,
  NotificationActionType.BulkCampaignEditSuccess,
  NotificationActionType.BulkCampaignEditPartialSuccess,
  NotificationActionType.BulkCampaignEditFailure,
  NotificationActionType.ImageEdit,
  NotificationActionType.TermEdit,
];

export interface UserNotification {
  id: number;
  createdAt: string;
  action: NotificationActionType;
  entityType: NotificationEntityType;
  entitiesData: any[];
  isRead: boolean;
  isArchive: boolean;
  game: GameNotification;
  user: UserNameNotification;
}

export interface UserNotificationMessage {
  id: number;
  message: string;
  linksString?: string[];
  links?: string[];
  secondLinkString?: string[];
  secondLink?:string[]; 
  thirdLinkString?: string[];
  thirdLink?:string[]; 
  fourthLinkString?: string[];
  fourthLink?:string[]; 
  entityType: NotificationEntityType;
  createdAt: string;
  isRead: boolean;
  isArchive: boolean;
  callToAction?: CallToAction;
  additionalMessage?: string; // if present, this will be appended on a newline to the message
}

// CallToAction is intended to be used as an actionable item in a notification
export interface CallToAction {
  action: () => Promise<any>;
  label: string;
}