import React from 'react';
import { useSelector } from 'react-redux';
import { users } from 'app/slices/users';
import { RoleGuardProps } from './RoleGuard.consts';
import { DomainName } from '../../utils/types/users';

export const RoleGuard = ({ children, roles, domain = DomainName.Offers, attributes = [] }: RoleGuardProps) => {
  const {
    user: { role: roleLegacy, userOperation },
  } = useSelector(users);

  // TODO: Remove once all users have been migrated to the new userOperation
  if (!userOperation) {
    if (!roles || roles.length === 0 || roles.includes(roleLegacy)) {
      return Array.isArray(children) ? <>{children.map((c) => c)}</> : children;
    }
    return null;
  }

  const userDomain = userOperation.domain.domainName;

  if (userDomain !== domain) {
    return null;
  }

  const hasRole = !roles || roles.length === 0 || roles.includes(userOperation.role.roleName);
  const hasAttribute =
    !attributes || attributes.length === 0 || attributes.includes(userOperation.attribute.attributeName);

  if (hasRole && hasAttribute) {
    return Array.isArray(children) ? <>{children.map((c) => c)}</> : children;
  }

  return null;
};
