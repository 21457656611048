import { gql } from '@apollo/client';

const usersGqls = {
  queries: {
    getAll: gql`
      query Users($data: GetAllQueryDto) {
        getUsers(data: $data) {
          items {
            ... on User {
              id
              email
              firstName
              lastName
              fullName
              company
              role
              notifications
              authType
              isLocked
            }
          }
          total
        }
      }
    `,
    getAllFull: gql`
      query UsersFull($data: GetAllQueryDto) {
        getUsersFull(data: $data) {
          items {
            ... on User {
              id
              email
              firstName
              lastName
              fullName
              company
              role
              notifications
              authType
              isLocked
              userOperation {
                role {
                  roleName
                }
                domain {
                  domainName
                }
                attribute {
                  attributeName
                }
              }
            }
          }
          total
        }
      }
    `,
  },
  mutations: {
    add: gql`
      mutation CreateUser($data: CreateUserDto!) {
        createUser(data: $data) {
          id
          email
        }
      }
    `,
    update: gql`
      mutation UpdateUserMutation($data: UpdateUserDto!) {
        updateUser(data: $data) {
          email
          role
        }
      }
    `,
    delete: gql`
      mutation DeleteUser($id: Int!) {
        deleteUser(id: $id) {
          id
        }
      }
    `,
    unlock: gql`
      mutation UnlockUser($id: BigInt!) {
        unlockUser(id: $id) {
          id
        }
      }
    `,
    forgotPassword: gql`
      mutation ForgotPassword($data: ForgotPasswordDto!) {
        forgotPassword(data: $data)
      }
    `,
  },
};

export default usersGqls;
