import React from 'react';
import { Container, Description, IphoneFrame, StyledMcdPng } from './GMAPreview.style';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import {
  AboveTitle,
  DetailsContainer,
  TitleContainer,
  PointOfDistribution,
  Title,
  GeneralDetails,
  GeneralAllSection,
  GeneralAllSectionFirst,
  SubTitle,
  ViewEligibleItem,
  GeneralAllSectionSecond,
  Expires,
  TermAndRestaurant,
  GeneralImageSection,
  Bottom,
  BottomSectionFirst,
  BottomCommonDiv,
  StyledMcdImage,
  StyledText,
  StyledArrowImage,
  BottomCommonDivContainer,
  StyledAppDealImage,
  PointOfDistributionText,
} from './GMAPreview.style';
import { convertUtcDateToTimezoneDateUsingLibrary, formatTimeToHoursMinutes } from 'utils/date';
import { StyledIcon } from './GMAPreview.style';
import { Icon } from 'components/shared/icon';
import { scalingFactorGMA } from '../GmaViewForm.const';
import mcdDelivery from "../../../../../assets/svgs/mcdDelivery.png";
import { differenceInDays, format } from 'date-fns';
import { DateFormats } from 'utils/types';

const GMAPreview = ({ offer, campaign }: { offer: any; campaign: any }) => {
  const { config } = useSelector(marketConfig);
  const endTimezone = config.endTimezone;
  if (campaign) {
    offer = campaign.entity;
  }
  const primaryLanguage = config.primaryLanguage;
  const formattedDate = `${config.dateFormat === DateFormats.DayMonthYear ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}`;
  // Handle versions correctly based on the source (Offer Page vs Campaign Page)
  // Check if `versions` exists; otherwise, fall back to `offerVersion` (single object)
  const version = Array.isArray(offer?.versions) ? offer.versions[0] : offer?.offerVersion;

  const translation = version?.translationsMap?.[primaryLanguage] || version?.translations[0];
  const pointOfDistribution = version?.pointOfDistribution;
  const description = translation?.description;
  const imageSrc = translation?.image?.file;
  const imageSrcFileName = translation?.image?.name;
  const campaignEndRaw = offer?.schedule?.campaignEnd;
  const diffDays = differenceInDays(campaignEndRaw, new Date());

  // Ensure campaignEndRaw is a valid ISO string before processing
  const campaignEndIso = campaignEndRaw
    ? convertUtcDateToTimezoneDateUsingLibrary(campaignEndRaw.toISOString(), endTimezone)
    : null;
  let expire;
  if (campaignEndIso) {
    if (diffDays === 0 && campaignEndIso) {
      expire = 'Expires Today';
    } else if (diffDays === 1) {
      expire = 'Expires Tomorrow';
    } else if (diffDays < 7) {
      expire = `Expires in ${diffDays} days`;
    } else {
      expire = `Expires ${format(campaignEndIso, formattedDate)}`; // Display full date if more than a week
    }
  } else {
    expire = `Expires on XX/XX/XXXX`;
  }

  return (
    <Container data-automation-id="gma-container">
      <IphoneFrame>
        <AboveTitle>
          <StyledIcon>
            <Icon name="close" width={14.38 * scalingFactorGMA} height={14.38 * scalingFactorGMA} />
          </StyledIcon>
        </AboveTitle>
        <DetailsContainer data-automation-id="details-container">
          <TitleContainer id="title-container">
            <PointOfDistribution>
              <StyledAppDealImage name="deals" />
              <PointOfDistributionText>
                {offer?.type === 'loyalty'
                  ? 'Redeem for XXXX pts'
                  : pointOfDistribution?.includes('Pickup')
                  ? 'App Exclusive Deal'
                  : 'McDelivery Only'}
              </PointOfDistributionText>
            </PointOfDistribution>
            <Title data-automation-id={'offer-title'}>{translation?.title}</Title>
          </TitleContainer>
          <GeneralDetails>
            <GeneralAllSection data-automation-id="general-all-section">
              <GeneralAllSectionFirst>
                <SubTitle data-automation-id={'offer-subtitle'}>{translation?.subtitle}</SubTitle>
                <ViewEligibleItem
                  href=""
                  onClick={(e) => e.preventDefault()}
                  data-automation-id={'view-eligible-items'}
                >
                  View eligible items
                </ViewEligibleItem>
              </GeneralAllSectionFirst>
              <GeneralAllSectionSecond>
                <Expires data-automation-id={'campaign-expires'}>{`${expire}`}</Expires>
                <TermAndRestaurant href="" onClick={(e) => e.preventDefault()} data-automation-id={'terms-restaurants'}>
                  View terms of this offer | Participating Restaurants
                </TermAndRestaurant>
              </GeneralAllSectionSecond>
              <Description>{description}</Description>
            </GeneralAllSection>
            <GeneralImageSection data-automation-id="general-image-section">
              <img
                src={imageSrc}
                alt={imageSrcFileName}
                width={200 * scalingFactorGMA}
                style={{
                  objectFit: "scale-down",
                  minHeight: `${200 * scalingFactorGMA}px`, // Ensures minimum height before loading
                  height: "auto", // Adjusts after loading
                }}
              />
            </GeneralImageSection>
          </GeneralDetails>
          <Bottom>
            <BottomSectionFirst>Choose how to use your deal</BottomSectionFirst>
            {pointOfDistribution?.includes('Pickup') && (
              <BottomCommonDivContainer>
                <BottomCommonDiv>
                  <StyledMcdImage name="mcdGma" />
                  <StyledText>Add to Mobile Order</StyledText>
                </BottomCommonDiv>
                <StyledArrowImage name="sideArrow" />
              </BottomCommonDivContainer>
            )}
            {pointOfDistribution?.includes('Delivery') && (
              <BottomCommonDivContainer>
                <BottomCommonDiv>
                  <StyledMcdPng src={mcdDelivery} alt="Delivery" />
                  <StyledText>Add to McDelivery Order</StyledText>
                </BottomCommonDiv>
                <StyledArrowImage name="sideArrow" />
              </BottomCommonDivContainer>
            )}
          </Bottom>
        </DetailsContainer>
      </IphoneFrame>
    </Container>
  );
};

export default GMAPreview;
