import React, { useEffect, useState } from 'react';
import { closeModal } from 'app/slices/modals';
import { store } from 'app/store';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import TextField from 'components/shared/textField/TextField';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Checkbox from 'components/shared/checkbox/Checkbox';
import ReactTooltip from 'react-tooltip';
import { capitalize } from 'utils/text';
import { FormMode } from 'utils/types';
import { useMutation } from '@apollo/client';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import {
  AuthType,
  UserNotificationRecord,
  UserNotificationType,
  UserRole,
  DomainName,
  UserRoleRecord,
  RGMAttributeRecord,
  DomainNameRecord,
} from 'utils/types/users';
import { hideTooltip } from 'utils/tooltip';
import ToggleGroup from 'components/shared/toggleGroup/ToggleGroup';
import { StyledLabel } from 'components/shared/textField/TextField.style';
import { isInArray } from 'utils/array';
import {
  CheckboxWrapper,
  NotificationRegisteration,
  RoleSelectbox,
  StyledCheckbox,
  StyledGoldButton,
  StyledInfoTooltip,
  StyledModal,
  StyledTooltip,
  UserManagementModalColumn,
  UserManagementModalCompany,
  UserManagementModalContainer,
  UserManagementModalDoeBannerSection,
  UserManagementModalFooter,
  UserManagementModalHorizontalColumn,
  UserManagementModalNames,
  UserManagementModalNotificationSectionTitle,
  UserManagementModalSection,
  UserManagementModalSectionTitle,
  UserManagementModalTextField,
} from './UserManagementModal.style';
import { AuthTypes, Companies, UserManagementModalProps } from './UserManagementModal.consts';
import usersGqls from '../../UserManagement.gqls';
import { NotificationActionType } from 'utils/types/notification';
import { FormValidationMessages, ValidationMessages } from 'utils/types/common';
import { getUserRole } from '../../../../../utils/users';
import DoeBanner from 'components/shared/notifications/banner/DoeBanner';

const UserManagementModal = ({ user, mode }: UserManagementModalProps) => {
  const {
    control,
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: cloneDeep(omitBy(user, isNil)) as any,
    reValidateMode: 'onChange',
  });

  const [modalMode, setModalMode] = useState(mode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createUser] = useMutation(usersGqls.mutations.add);
  const [updateUser] = useMutation(usersGqls.mutations.update);
  const [deleteUser] = useMutation(usersGqls.mutations.delete);
  const [unlockUser] = useMutation(usersGqls.mutations.unlock);
  const [firstName, lastName, authType, userOperation] = useWatch({
    control,
    name: ['firstName', 'lastName', 'authType', 'userOperation'],
  });
  const [notifications, setNotifications] = useState(user?.notifications ?? []);

  const getNotifications = (role: UserRole, notifications: NotificationActionType[]) => {
    if ([UserRole.Trainee, UserRole.Viewer].includes(role)) {
      return notifications
        ? notifications.filter(
            (ele: NotificationActionType) => ele !== NotificationActionType.CampaignApprovalRequired
          )
        : [];
    }
    return notifications ?? [];
  };

  const onSave = async (formData: any) => {
    const isVceAuthType = formData.authType === AuthType.VCE;
    setIsSubmitting(true);
    if (modalMode === FormMode.Edit) {
      try {
        await updateUser({
          variables: {
            data: {
              id: formData.id,
              role:
                formData.userOperation.role.roleName === UserRole.SysAdmin
                  ? 'SysAdmin'
                  : capitalize(formData.userOperation.role.roleName),
              domainName: capitalize(formData.userOperation.domain.domainName),
              ...(formData.userOperation?.attribute?.attributeName && {
                rgmAttribute: capitalize(formData.userOperation?.attribute?.attributeName),
              }),
              company: formData.company,
              notifications: getNotifications(formData.userOperation.role.roleName, formData.notifications),
              firstName: isVceAuthType ? firstName : undefined,
              lastName: isVceAuthType ? lastName : undefined,
            },
          },
          refetchQueries: ['UsersFull'],
        });
        showToast(MessageType.Success, `User updated successfully`);
        store.dispatch(closeModal());
      } catch (e) {
        if (e.message.includes('Access Denied')) {
          showToast(MessageType.Error, `Failed to update user - Access Denied`);
        } else {
          showToast(MessageType.Error, `Failed to update user`);
        }
      }
    } else if (modalMode === FormMode.New) {
      try {
        await createUser({
          variables: {
            data: {
              email: formData.email,
              role: capitalize(formData.userOperation.role.roleName),
              domainName: capitalize(formData.userOperation.domain.domainName),
              rgmAttribute: capitalize(
                formData.userOperation?.attribute?.attributeName
                  ? formData.userOperation?.attribute?.attributeName
                  : 'approver',
              ),
              company: formData.company,
              notifications: getNotifications(formData.userOperation.role.roleName, formData.notifications),
              authType: formData.authType.toUpperCase(),
              firstName: isVceAuthType ? firstName : undefined,
              lastName: isVceAuthType ? lastName : undefined,
            },
          },
          refetchQueries: ['UsersFull'],
        });
        showToast(MessageType.Success, `User added successfully`);
        store.dispatch(closeModal());
      } catch (e) {
        if (e.message.includes('already exists')) {
          showToast(MessageType.Error, `Failed to add user - ${formData.email} already exists`);
        } else {
          showToast(MessageType.Error, `Failed to add user`);
        }
      }
    }
    setIsSubmitting(false);
  };

  const getModalTitle = () => {
    switch (modalMode) {
      case FormMode.New:
        return 'Add User';
      case FormMode.Edit:
        return `Edit User ${user.email}`;
      case FormMode.View:
        return `View User ${user.email}`;
      default:
        return '';
    }
  };

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);

  const handleDeleteClick = async () => {
    try {
      if (!isDeleteButtonClicked) {
        setIsDeleteButtonClicked(true);
        await deleteUser({
          variables: {
            id: Number(user.id),
          },
          refetchQueries: ['Users'],
        });
        showToast(MessageType.Success, `User deleted successfully`);
      }
    } catch (e) {
      if (e.message.includes('Access Denied')) {
        showToast(MessageType.Error, `Failed to delete user - Access Denied`);
      } else {
        showToast(MessageType.Error, `Failed to delete user`);
      }
    }
  };

  const getIsNotificationDisabled = (key: UserNotificationType) =>
    key === UserNotificationType.CampaignRequireApproval &&
    isInArray([UserRole.Trainee, UserRole.Viewer], userOperation?.role);

  const handleProductOrLocationUpdate = (userNotifications: string[]) => {
    const keysToDelete = [];
    if (!userNotifications.includes(UserNotificationType.ProductUpdate) && userNotifications.includes('noProducts')) {
      keysToDelete.push('noProducts');
    }
    if (!userNotifications.includes(UserNotificationType.LocationUpdate) && userNotifications.includes('noLocations')) {
      keysToDelete.push('noLocations');
    }
    return keysToDelete;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty]);

  useEffect(() => {
    if (modalMode !== FormMode.View && modalMode !== FormMode.Edit && authType === AuthType.VCE) {
      setValue('company', 'Other');
    }
  }, [authType]);

  return (
    <StyledModal mode={modalMode} title={getModalTitle()}>
      <UserManagementModalContainer>
        {Object.keys(errors).length > 0 && (
          <UserManagementModalDoeBannerSection>
            <DoeBanner type={MessageType.Critical}>{FormValidationMessages.ValidationError}</DoeBanner>
          </UserManagementModalDoeBannerSection>
        )}
        <UserManagementModalColumn>
          <UserManagementModalSection>
            <UserManagementModalSectionTitle>Authentication*</UserManagementModalSectionTitle>
            <RadioGroup
              control={control}
              name="authType"
              errors={errors}
              validation={{ required: ValidationMessages.AlternateRequiredField }}
              radioList={AuthTypes}
              defaultValue={null}
              disabled={modalMode !== FormMode.New} // TODO: Should be updated according to the requirement
            />
          </UserManagementModalSection>
          <UserManagementModalTextField
            register={register}
            errors={errors}
            name="email"
            label="Email address"
            placeholder=""
            labelIsHorizontal
            type="email"
            value={user?.email}
            validation={{
              required: ValidationMessages.AlternateRequiredField,
              pattern: {
                value:
                  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid Email',
              },
            }}
            disabled={modalMode !== FormMode.New}
          />
          {authType === AuthType.VCE ? (
            <>
              <UserManagementModalTextField
                register={register}
                errors={errors}
                name="firstName"
                label="First Name"
                labelIsHorizontal
                disabled={!isInArray([FormMode.New, FormMode.Edit], modalMode)}
                validation={{
                  required: ValidationMessages.AlternateRequiredField,
                }}
              />
              <UserManagementModalTextField
                register={register}
                errors={errors}
                name="lastName"
                label="Last Name"
                labelIsHorizontal
                disabled={!isInArray([FormMode.New, FormMode.Edit], modalMode)}
                validation={{
                  required: ValidationMessages.AlternateRequiredField,
                }}
              />
            </>
          ) : null}
          <UserManagementModalSection>
            <UserManagementModalSectionTitle>Company*</UserManagementModalSectionTitle>
            <RadioGroup
              control={control}
              name="company"
              errors={errors}
              validation={{ required: ValidationMessages.AlternateRequiredField }}
              radioList={Companies}
              defaultValue={null}
              disabled={modalMode === FormMode.View}
            />
          </UserManagementModalSection>
          <UserManagementModalSection>
            <UserManagementModalSectionTitle>Domain*</UserManagementModalSectionTitle>
            <RadioGroup
              control={control}
              errors={errors}
              validation={{ required: ValidationMessages.AlternateRequiredField }}
              name="userOperation.domain.domainName"
              radioList={
                Object.entries(DomainNameRecord).map(([key, value]) => {
                  return {
                    value: key,
                    label: value,
                  };
                }) as any[]
              }
              defaultValue={null}
              disabled={modalMode === FormMode.View}
            />
          </UserManagementModalSection>
          {getUserRole(user) !== UserRole.SysAdmin && (
            <>
              <UserManagementModalSection>
                <UserManagementModalSectionTitle>Role*</UserManagementModalSectionTitle>
                <RadioGroup
                  control={control}
                  name="userOperation.role.roleName"
                  errors={errors}
                  validation={{ required: ValidationMessages.AlternateRequiredField }}
                  radioList={
                    Object.entries(UserRoleRecord)
                      .filter(([key]) => key !== UserRole.SysAdmin)
                      .map(([key, value]) => {
                        return {
                          value: key,
                          label: value,
                        };
                      }) as any[]
                  }
                  defaultValue={null}
                  disabled={modalMode === FormMode.View}
                />
              </UserManagementModalSection>
            </>
          )}
          {userOperation?.domain?.domainName === DomainName.Doe && (
            <>
              <UserManagementModalSection>
                <UserManagementModalSectionTitle>Domain attributes*</UserManagementModalSectionTitle>
                <RadioGroup
                  control={control}
                  name="userOperation.attribute.attributeName"
                  errors={errors}
                  validation={{ required: ValidationMessages.AlternateRequiredField }}
                  radioList={
                    Object.entries(RGMAttributeRecord).map(([key, value]) => {
                      return {
                        value: key,
                        label: value,
                      };
                    }) as any[]
                  }
                  defaultValue={null}
                  disabled={modalMode === FormMode.View}
                />
              </UserManagementModalSection>
            </>
          )}
          <NotificationRegisteration>
            <UserManagementModalNotificationSectionTitle>
              Email Notifications
            </UserManagementModalNotificationSectionTitle>
            {Object.entries(UserNotificationRecord).map(([key, val]: [string, string]) => {
              return (
                <Controller
                  control={control}
                  name="notifications"
                  render={({ field: { onChange } }) => (
                    <CheckboxWrapper>
                      <StyledCheckbox
                        disabled={modalMode === FormMode.View || getIsNotificationDisabled(key as UserNotificationType)}
                        label={val === 'Campaign Error Notification' ? 'Campaign Notification' : val}
                        onClick={() => {
                          const newNotifications = [...notifications];
                          if (notifications.includes(key)) {
                            newNotifications.splice(notifications.indexOf(key), 1);
                            const keysToRemove = handleProductOrLocationUpdate(newNotifications);
                            keysToRemove.forEach((k) => newNotifications.splice(newNotifications.indexOf(k), 1));
                          } else {
                            newNotifications.push(key);
                          }
                          setNotifications(newNotifications);
                          onChange(newNotifications);
                        }}
                        checked={notifications.includes(key) && !getIsNotificationDisabled(key as UserNotificationType)}
                      />
                      {val === 'Campaign Error Notification' && (
                        <StyledInfoTooltip
                          id="uptoTooltip"
                          className="userManagementCampaignNotification"
                          content="User will receive bulk campaign notification and campaign error notification if enabled."
                        />
                      )}
                    </CheckboxWrapper>
                  )}
                />
              );
            })}
          </NotificationRegisteration>
        </UserManagementModalColumn>
        <UserManagementModalHorizontalColumn>
          {user?.role !== UserRole.SysAdmin && (
            <RoleSelectbox
              items={
                Object.entries(UserRoleRecord)
                  .filter(([key]) => key !== UserRole.SysAdmin)
                  .map(([key, value]) => {
                    return {
                      id: key,
                      name: value,
                    };
                  }) as any[]
              }
              control={control}
              name="role"
              errors={errors}
              label="Role"
              labelIsHorizontal
              validation={{ required: true }}
              onChange={() => {
                trigger('role');
              }}
              disabled={modalMode === FormMode.View}
              selectWidth={100}
            />
          )}
          <UserManagementModalCompany>
            <span>Company*</span>
            <RadioGroup
              control={control}
              name="company"
              radioList={Companies}
              defaultValue={Companies[0].value}
              disabled={modalMode === FormMode.View}
            />
          </UserManagementModalCompany>
        </UserManagementModalHorizontalColumn>
        <UserManagementModalFooter>
          <ButtonText
            data-for="cancel-tooltip"
            {...(isDirty ? { 'data-tip': true, onClick: () => null } : { onClick: () => store.dispatch(closeModal()) })}
            onKeyDown={(e: any) => { if (e.key === 'Enter') {
              store.dispatch(closeModal())
            }}
          }
          >
            Cancel
          </ButtonText>
          {modalMode === FormMode.View ? (
            <>
              <StyledTooltip
                id="delete-tooltip"
                content={`Are you sure you want to delete ${user.email}?`}
                approveMsg="Yes, Delete"
                onDisapproveClick={() => {
                  hideTooltip('#delete-tooltip');
                }}
                onApproveClick={() => {
                  handleDeleteClick();
                  showToast(MessageType.Success, `User deleted successfully`);
                  store.dispatch(closeModal());
                }}
              />
              <ButtonOutlined data-tip data-for="delete-tooltip" onClick={() => null}>
                Delete
              </ButtonOutlined>
              {user?.isLocked === true ? (
                <ButtonOutlined
                  onClick={async () => {
                    try {
                      await unlockUser({
                        variables: {
                          id: user.id,
                        },
                        refetchQueries: ['Users'],
                      });
                      showToast(MessageType.Success, `User unlocked successfully`);
                      store.dispatch(closeModal());
                    } catch (error) {
                      showToast(MessageType.Error, `User unlock failed, Please try again`);
                      // Handle error
                    }
                  }}
                >
                  Unlock
                </ButtonOutlined>
              ) : (
                <ButtonContained onClick={() => setModalMode(FormMode.Edit)}>Edit</ButtonContained>
              )}
            </>
          ) : (
            <>
              <ButtonContained onClick={handleSubmit(onSave)} disabled={!isValid || !isDirty || isSubmitting}>
                Save
              </ButtonContained>
              <StyledTooltip
                id="cancel-tooltip"
                content="Are you sure you want to cancel?"
                onDisapproveClick={() => {
                  hideTooltip('#cancel-tooltip');
                }}
                onApproveClick={() => store.dispatch(closeModal())}
              />
            </>
          )}
        </UserManagementModalFooter>
      </UserManagementModalContainer>
      <UserManagementModalFooter>
        {modalMode === FormMode.View ? (
          <>
            <StyledTooltip
              id="delete-tooltip"
              content={`Are you sure you want to delete ${user.email}?`}
              approveMsg="Yes, Delete"
              onDisapproveClick={() => {
                hideTooltip('#delete-tooltip');
              }}
              onApproveClick={() => {
                handleDeleteClick();
                store.dispatch(closeModal());
              }}
            />
            <ButtonOutlined data-tip data-for="delete-tooltip" onClick={() => null}>
              Delete
            </ButtonOutlined>
            {user?.isLocked === true ? (
              <ButtonOutlined
                onClick={async () => {
                  try {
                    await unlockUser({
                      variables: {
                        id: user.id,
                      },
                      refetchQueries: ['Users'],
                    });
                    showToast(MessageType.Success, `User unlocked successfully`);
                    store.dispatch(closeModal());
                  } catch (error) {
                    showToast(MessageType.Error, `User unlock failed, Please try again`);
                    // Handle error
                  }
                }}
              >
                Unlock
              </ButtonOutlined>
            ) : (
              <ButtonContained onClick={() => setModalMode(FormMode.Edit)}>Edit</ButtonContained>
            )}
          </>
        ) : (
          <>
            <StyledGoldButton onClick={handleSubmit(onSave)} disabled={!isDirty || isSubmitting}>
              Save
            </StyledGoldButton>
            <StyledTooltip
              id="cancel-tooltip"
              content="Are you sure you want to cancel?"
              onDisapproveClick={() => {
                hideTooltip('#cancel-tooltip');
              }}
              onApproveClick={() => store.dispatch(closeModal())}
            />
          </>
        )}
      </UserManagementModalFooter>
    </StyledModal>
  );
};

export default UserManagementModal;
