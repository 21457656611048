import { gql } from '@apollo/client';

export const DoeGqls = {
  queries: {
    getAll: gql`
      query DoeCampaigns($data: GetCampaignsQueryDto) {
        getDoeCampaigns(data: $data) {
          items {
            ... on Campaign {
              id
              externalId
              type
              title
              status
              alert
              schedule
              isLocked
              redemptions
              voucherConfig
              isTriggerEvent
              priority
              approvals {
                deploymentFailedCount
              }
              createdBy {
                id
              }
              updatedBy {
                id
              }
              localSchedule {
                period {
                  id
                  name
                  endDate
                }
                zone {
                  id
                  name
                }
              }
              offerVersion {
                translations {
                  subtitle
                  image {
                    id
                  }
                }
              }
              push_notification {
                id
                startDate
                push_notification_translation {
                  id
                  language
                  title
                  message
                }
              }
              inProgress
            }
          }
          total
          totalLocal
          totalNonLocalConflicted
          totalNotEligibleForSelfApproval
          totalInvalid
        }
      }
    `,
    getBulkEditCampaignInfo: gql`
      query getBulkEditCampaignInfo($data: BulkEditInfoInput!) {
        getBulkEditCampaignInfo(data: $data) {
          campaignCount
          description
          isNationwide
          campaignEnd
          validityStartTime
          validityEndTime
          validDays
          zoneIds
          emptyFields
        }
      }
    `,
  },
  mutations: {
    bulkCampaignsRevokeOrStopAssociation: gql`
      mutation BulkCampaignsRevokeOrStopAssociation($data: BulkCampaignsRevokeOrStopAssociationDto!) {
        bulkCampaignsRevokeOrStopAssociation(data: $data) {
          offerPropIds
        }
      }
    `,
    bulkCampaignUpdate: gql`
      mutation BulkCampaignUpdate($data: BulkCampaignUpdateDto!) {
        bulkCampaignUpdate(data: $data) {
          id
        }
      }
    `,
  },
};
