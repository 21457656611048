import styled from '@emotion/styled';
import { newTypography } from 'styles/typography';
import { newGlobalTheme } from '../../../../styles/themes/newGlobalTheme';

export const StyledTitle = styled.h1`
  font-size: 56px;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: ${newTypography.unit * 10}px;
  font-weight: 700;
  line-height: 22.83px;
  font-family: ${newTypography.primaryFont};
`;

export const OfferStyledTitle = styled.h1`
  font-size: 56px;
  text-transform: capitalize;
  margin: 0;
  font-weight: 700;
  line-height: 22.83px;
  font-family: ${newTypography.primaryFont};
`;

export const StyledSubtitle = styled.p`
  color: ${newGlobalTheme.colors.text.disabledSave};
  font-family: ${newTypography.primaryFont};
  font-size: 14px;
  line-height: ${newTypography.unit * 4}px;
  letter-spacing: -0.15px;
`;
