export interface DoeCampaignBulkEditProps {
    step: number;
    initialLocationCount?: number;
    formData: FormData;
    nextEnabled: boolean;
  }

export interface DoeCampaignFormData {
    offerBankId: string;
    offerGroupId: number;
}

export const VALID_DAYS = [
    { id: 'monday', name: 'Mon' },
    { id: 'tuesday', name: 'Tue' },
    { id: 'wednesday', name: 'Wed' },
    { id: 'thursday', name: 'Thu' },
    { id: 'friday', name: 'Fri' },
    { id: 'saturday', name: 'Sat' },
    { id: 'sunday', name: 'Sun' },
];

export enum BulkEditFieldStatus {
    SAME_VALUES = 'All values for this field are currently the same.',
    EMPTY_VALUES = 'All values for this field are currently empty.',
    MIXED_VALUES = 'This field contains mixed values.',
    EDITED_INDIVIDUALLY = 'Fields containing mixed values must be edited individually.'
}