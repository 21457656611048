import { useEffect, useRef, useState } from 'react';
import { debounce } from 'utils/debounce';
import { getIsControlWithError } from 'utils/form';
import { Icon } from '../icon';
import { DoeTextFieldProps } from './DoeTextField.consts';
import {
  InputContainer,
  InputWrapper,
  StyledChildren,
  StyledContainer,
  StyledError,
  StyledErrorIcon,
  StyledInput,
  StyledLabel,
  StyledViewButton,
} from './DoeTextField.style';

const DoeTextField = ({
  id,
  name,
  type = 'text',
  register,
  validation,
  label,
  labelIsHorizontal,
  placeholder,
  disabled,
  value,
  errors,
  children,
  className,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  debounceTime,
  caretPosition,
  autoFocus = false,
  showError = true,
}: DoeTextFieldProps) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (autoFocus && caretPosition === 0) {
      return;
    }
    if (caretPosition !== 0) {
      elementRef.current?.setSelectionRange(caretPosition, caretPosition);
    }
  }, [caretPosition]);
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <StyledContainer role="textField" labelIsHorizontal={labelIsHorizontal} className={className}>
      {label && (
        <StyledLabel
          labelIsHorizontal={labelIsHorizontal}
          disabled={disabled}
        >{`${label}${validation?.required ? '*' : ''}`}</StyledLabel>
      )}
      <InputContainer {...(disabled && { title: value?.toString() })}>
        <InputWrapper className={'input-wrapper'} disabled={disabled} error={getIsControlWithError(name, errors)}>
          {children && <StyledChildren>{children}</StyledChildren>}
          <StyledInput
            ref={elementRef}
            id={id}
            {...(register &&
              register(name, {
                ...validation,
                shouldUnregister: true,
                setValueAs: (v: string | number) => (typeof v === 'string' ? v.trim() : v),
              }))}
            defaultValue={value}
            placeholder={placeholder}
            disabled={disabled}
            {...(onChange && { onChange: debounce(onChange, debounceTime) })}
            {...(onFocus && { onFocus })}
            onBlur={onBlur}
            type={type === 'password' ? (passwordVisible ? 'text' : type) : type}
            autoFocus={autoFocus}
            autoComplete="off"
            data-automation-id={`text-field-${name}`}
            data-testid={`text-field-${name}`}
          />
          {getIsControlWithError(name, errors) && <StyledErrorIcon name="doeError" />}
          {type === 'password' && (
            <StyledViewButton
              onClick={() => setPasswordVisible(!passwordVisible)}
              data-title={passwordVisible ? 'Hide' : 'View'}
            >
              {passwordVisible ? (
                <Icon name="eyeIconPasswordShow" width={26} height={21} />
              ) : (
                <Icon name="eyeIconPasswordHide" width={26} height={16} />
              )}
            </StyledViewButton>
          )}
        </InputWrapper>
        {showError && <StyledError name={name} errors={errors} tabIndex={-1} />}
      </InputContainer>
    </StyledContainer>
  );
};

export default DoeTextField;
